import { Avatar } from 'antd';

import './DashboardHeader.scss';

const DashboardHeader = ({ user: { picture, name } }) => (
  <div className="dashboard-header">
    <div className="dashboard-header--avatar">
      <Avatar size={60} src={picture} />
    </div>
    <div className="dashboard-header--greeting">
      Welcome back, {name}
    </div>
  </div>
);

export default DashboardHeader;

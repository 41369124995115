import { useState } from 'react';
import { IoCalendarClearOutline } from 'react-icons/io5';

import {
  datePickerTypes,
  datePickerErrorFields,
} from 'shared/constants/graphingAndStatisticsConstants';
import FluxRangePicker from 'shared/components/FluxRangePicker';
import { getFormattedDate } from 'shared/helpers/datePickerHelpers';

import './FluxDatePicker.scss';

const FluxDatePicker = ({
  showReset,
  errorField,
  clearFunction,
  finalizedDates,
  resetToDefault,
  setFinalizedDates,
  popupClasses = '',
  containerClasses = '',
  timeIsLaterThanNow = false,
  singlePickerProps = {},
  pickerType = datePickerTypes.RANGE,
  rangePickerProps = {
    panelTitle: 'Select date range',
    panelSubtitle: 'Click on any two days to create a range.',
  },
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const startDate = pickerType === datePickerTypes.RANGE
    ? getFormattedDate(finalizedDates?.[0])
    : getFormattedDate(finalizedDates);

  const endDate = pickerType === datePickerTypes.RANGE
    ? getFormattedDate(finalizedDates?.[1])
    : getFormattedDate(finalizedDates);

  const hasError = errorField === datePickerErrorFields.START_TIME
    || errorField === datePickerErrorFields.END_TIME;

  const otherPickerProps = pickerType === datePickerTypes.RANGE
    ? rangePickerProps
    : singlePickerProps;

  const handleDatePickerClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`flux-date-picker ${containerClasses}`}>
      <div
        tabIndex={0}
        role="button"
        onClick={handleDatePickerClick}
        className={
            `date-picker-button ${isOpen ? 'showing-picker' : ''}
            ${hasError && !finalizedDates ? 'errored' : ''}`
          }
      >
        <div className="start-date">
          <IoCalendarClearOutline size={28} />
          <div>
            <p className="btn-label">
              {pickerType === datePickerTypes.RANGE
                ? 'Start Date'
                : 'Choose a Date'}
            </p>
            <p className="btn-value">{startDate}</p>
          </div>
        </div>
        {pickerType === datePickerTypes.RANGE
          ? (
            <>
              <div className="separator" />
              <div className="end-date">
                <IoCalendarClearOutline size={28} />
                <div>
                  <p className="btn-label">End Date</p>
                  <p className="btn-value">{endDate}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="date-spacer" />
          )}
        {showReset
          && (
            <div className="close-button" onClick={resetToDefault}>
              Reset
            </div>
          )}
      </div>
      {isOpen && (
        <FluxRangePicker
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          pickerType={pickerType}
          extraClasses={popupClasses}
          clearFunction={clearFunction}
          finalizedDates={finalizedDates}
          setFinalizedDates={setFinalizedDates}
          timeIsLaterThanNow={timeIsLaterThanNow}
          {...otherPickerProps}
        />
      )}
    </div>

  );
};

export default FluxDatePicker;

import * as sessionActions from 'shared/actions/sessionActionTypes';

export const setSession = (payload) => ({
  type: sessionActions.FLUX_APP__SET_SESSION,
  payload,
});

export const clearSession = () => ({
  type: sessionActions.FLUX_APP__CLEAR_SESSION,
});

import FluxDatePicker from 'shared/components/FluxDatePicker';
import FluxAutoComplete from 'shared/components/FluxAutoComplete';

import { WORK_ORDER_SEARCH_PLACEHOLDER_TEXT } from 'single-asset/constants/workOrdersConstants';
import LiveCount from 'shared/components/LiveCount';

import './WorkOrdersSearchForm.scss';

const WorkOrderSearchForm = ({
  finalizedDates,
  handleFiltering,
  handleClearDates,
  setFinalizedDates,
  formattedWorkOrders,
}) => {
  const workOrderCount = formattedWorkOrders?.length?.toString();

  return (
    <div className="work-orders-search-form">
      <FluxAutoComplete
        onChange={(input) => handleFiltering(input)}
        placeholder={WORK_ORDER_SEARCH_PLACEHOLDER_TEXT}
      />
      <section className="live-count-header">
        <LiveCount countType="Work Orders" count={workOrderCount} />
        <div className="date-picker-container">
          <FluxDatePicker
            finalizedDates={finalizedDates}
            clearFunction={handleClearDates}
            setFinalizedDates={setFinalizedDates}
          />
        </div>
      </section>
    </div>
  );
};

export default WorkOrderSearchForm;

import { configureStore } from '@reduxjs/toolkit';

import singleAsset from 'single-asset/reducers';
import session from 'shared/reducers/sessionReducer';
import permissions from 'shared/reducers/permissionsSlice';
import notifications from 'shared/reducers/notificationsSlice';
import organizations from 'shared/reducers/organizationsSlice';
import subscriptions from 'shared/reducers/subscriptionsSlice';

import { reducer as resources } from './resources';

const store = configureStore({
  reducer: {
    session,
    resources,
    permissions,
    singleAsset,
    notifications,
    organizations,
    subscriptions,
  },
});

export default store;

import React from 'react';

const SortIcons = ({ dataIndex, sortConfig }) => {
  const isActiveAsc = sortConfig.key === dataIndex && sortConfig.direction === 'ascending';
  const isActiveDesc = sortConfig.key === dataIndex && sortConfig.direction === 'descending';
  return (
    <div className="sort-icons">
      <span className={`sort-icon${isActiveAsc ? '-active' : ''}`}>▲</span>
      <span className={`sort-icon${isActiveDesc ? '-active' : ''}`}>▼</span>
    </div>
  );
};

export default SortIcons;

import React from 'react';
import { Modal, Skeleton } from 'antd';

import RecipientInfoContainer from './RecipientInfoContainer';

import './AssetEventRecipientModal.scss';

const AssetEventRecipientModal = ({
  isOpen,
  recipients,
  closeModal,
  isPending,
  modalTitle = 'Notifications received by:',
}) => (
  <Modal
    centered
    footer={[]}
    open={isOpen}
    title={modalTitle}
    onClose={closeModal}
    onCancel={closeModal}
    className="asset-event-recipient-modal"
    data-testid="asset-event-recipient-modal"
    wrapClassName="asset-event-recipient-modal-dialog"
  >
    {recipients?.length
      ? (
        <RecipientInfoContainer
          isPending={isPending}
          recipients={recipients}
        />
      )
      : (
        isPending ? (
          <Skeleton
            active
            className="recipient-skeleton"
            paragraph={{ rows: 0 }}
          />
        ) : (
          <p
            className="recipient-info-placeholder"
            data-testid="recipient-info-placeholder"
          >
            No recipient information.
          </p>
        )
      )}
  </Modal>
);

export default AssetEventRecipientModal;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { selectSession } from 'shared/reducers/sessionReducer';
import * as digitalTwinConstants from 'single-asset/constants/digitalTwinConstants';
import { selectAsset } from 'single-asset/reducers/assetReducer';

import './DigitalTwin.scss';

const DigitalTwin = () => {
  const { user: { email } } = useSelector(selectSession);
  const { asset_name: assetName } = useSelector(selectAsset);
  const [jwt, setJwt] = useState(null);

  useEffect(() => {
    (async () => {
      const fetchJWT = await fetch(digitalTwinConstants.URSALEO_TOKEN_ROUTE, {
        method: 'POST',
      });

      setJwt(await fetchJWT.json());
    })();
  }, []);

  return (
    <div className="digital-twin-container">
      {jwt && assetName ? (
        <iframe
          title="Digital Twin"
          data-testid="ursaLeoIFrame"
          // eslint-disable-next-line max-len
          src={`${digitalTwinConstants.DIGITAL_TWIN_BASE_URL}/gemini/index.html?USER_EMAIL=${email}&ASSET_ID=${assetName}&t=${jwt}`}
        />
      ) : (
        <BeatLoader color="#ff6600" />
      )}
    </div>
  );
};

export default DigitalTwin;

import { useState } from 'react';

import { DateRangePicker, MultiFiltrationDropdown, Pill } from '@flogistix/flo-ui';

import { eventFilterDropdownOptions } from 'single-asset/constants/assetEventConstants';

import LiveCount from 'shared/components/LiveCount';

import './AssetEventsSearchForm.scss';

const AssetEventsSearchForm = ({
  endDate,
  disabled,
  startDate,
  setEndDate,
  handleDates,
  setStartDate,
  handleClearDates,
  formattedAssetEvents,
  selectedEventFilters,
  setSelectedEventFilters,
}) => {
  const assetEventCount = formattedAssetEvents?.length;

  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState('');

  const addOption = (option) => {
    setSelectedEventFilters([...selectedEventFilters, option]);
  };

  const removeOption = (option) => {
    const newFilters = selectedEventFilters?.filter((entry) => entry?.value !== option?.value);
    setSelectedEventFilters(newFilters);
  };

  const removePill = (e, entry) => {
    removeOption(entry);
  };

  const removeFilters = () => setSelectedEventFilters([]);

  return (
    <div className="asset-events-search-form">
      <section className="live-count-header">
        <LiveCount countType="Events" count={assetEventCount} />
      </section>
      <div className="filtration-row">
        <DateRangePicker
          disabled={disabled}
          onClear={handleClearDates}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
          onSelectionChange={handleDates}
          setSelectedEndDate={setEndDate}
          setSelectedStartDate={setStartDate}
        />
        <MultiFiltrationDropdown
          width="small"
          searchValue={searchTerm}
          placeholder="Event Type"
          searchPlaceholder="Search"
          values={selectedEventFilters}
          options={eventFilterDropdownOptions}
          onSelect={(option) => addOption(option)}
          onDeselect={(option) => removeOption(option)}
        />
      </div>
      <div className="selected-filter-pills-row">
        {selectedEventFilters?.map((entry) => (
          <Pill
            variation="filter"
            label={entry?.label}
            onRemove={(e) => removePill(e, entry)}
          />
        ))}
        {selectedEventFilters?.length
          ? (
            <span
              onClick={removeFilters}
              className="clear-filters-clickable"
            >
              Clear all
            </span>
          )
          : <span />}
      </div>
    </div>
  );
};

export default AssetEventsSearchForm;

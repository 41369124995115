export const itemFilter = (filterString, keyList, item) => typeof filterString === 'string'
  && (
    filterString?.length === 0 || (
      keyList.some((key) => !!item?.[key] && item?.[key].toLocaleLowerCase().includes(filterString.toLocaleLowerCase())))
  );

export const getFilteredList = (filterString, keyList, itemList) => (
  !filterString
    ? itemList
    : itemList.filter((item) => itemFilter(filterString, keyList, item)));

export const isDataEqual = (data1, data2) => JSON.stringify(data1) === JSON.stringify(data2);

export const searchItemFieldsForMatch = (item, searchString) => (
  ['string', 'number'].includes(typeof searchString) && Object.entries(item).find(([, value]) => `${value}`?.toLowerCase()?.includes(searchString?.toLowerCase()))
  || []
);

export const filterArray = (array, searchText) => array
  .filter((obj) => !!searchItemFieldsForMatch(obj, searchText).length);

import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { generateNavIcon } from 'shared/constants/navItems';

import './SideNav.scss';

const buildItemLinks = (navItems, currentActive) => navItems.map((linkItem) => ({
  ...linkItem,
  label: linkItem.key,
  icon: (
    <NavLink
      to={linkItem.path}
      key={linkItem.key}
    >
      {generateNavIcon(linkItem.icon, currentActive === linkItem.key)}
    </NavLink>
  ),
}));

const SideNav = ({ navLinks, activeLink, canAccessReports }) => {
  const navItems = canAccessReports ? navLinks : navLinks.filter((item) => item.key !== 'Reporting');

  return (
    <Menu
      mode="inline"
      inlineCollapsed
      className="side-nav"
      items={buildItemLinks(navItems, activeLink.key)}
      selectedKeys={[navItems.find((link) => activeLink.path.includes(link.path))?.key ?? '']}
    />
  );
};
export default SideNav;

import dayjs from 'dayjs';

const generateCronMinuteAndHour = (timeValue) => {
  const timezoneOffsetHours = new Date().getTimezoneOffset() / 60;
  const hours = parseInt(timeValue.split(':').at(0), 10) + timezoneOffsetHours;
  const finalizedReportHour = hours > 23 ? (hours % 23) - 1 : hours;
  const finalizedMinutes = timeValue.split(':').at(1);
  return { finalizedMinutes, finalizedReportHour };
};

// generates string in following format 'MIN HOUR DAY(of month) MONTH DAY(of week)
// 00 18 * * 4 would be 'Every Thursday at 6:00 PM central time'
export const generateCronString = ({
  frequency, startDate, timeValue, weekday,
}) => {
  const { finalizedMinutes, finalizedReportHour } = generateCronMinuteAndHour(timeValue);
  const finalizedMonthDay = frequency === 'Monthly' ? dayjs(startDate).date() : '*';
  const finalizedWeekDay = (frequency === 'Weekly' || frequency === 'Fortnightly') ? weekday : '*';
  const finalizedMonth = '*';
  const cronString = `${finalizedMinutes} ${finalizedReportHour} ${finalizedMonthDay} ${finalizedMonth} ${finalizedWeekDay}`;
  return cronString;
};

export const getValidReportRequestData = ({
  selectedReportType,
  reportFilters,
  finalizedDates,
  extraReportOptions,
  setErrorMessage,
  scheduledReportOptions,
  isScheduled,
}) => {
  const { required } = selectedReportType.options;
  const reportData = {
    orgId: reportFilters.org ?? null,
    area: reportFilters.area,
    startTime: finalizedDates?.length ? dayjs(finalizedDates[0]).format('YYYY-MM-DD') : '',
    endTime: (finalizedDates?.length && !isScheduled) ? dayjs(finalizedDates[1]).format('YYYY-MM-DD') : '',
  };

  if (required.includes('eventDetail')) {
    if (extraReportOptions?.options.length) {
      reportData.eventDetail = true;
      extraReportOptions.options.reduce((acc, cur) => {
        acc[cur.name] = cur.value;
        return acc;
      }, reportData);
    } else {
      setErrorMessage({
        message: 'Extra report information is required',
        field: 'eventDetail',
      });
      return null;
    }
  } else if (Object.entries(extraReportOptions).length === 1) {
    reportData[extraReportOptions.options.name] = extraReportOptions.options.value;
  }

  if (required.includes('orgId') && !reportData.orgId) {
    setErrorMessage({
      message: 'Organization is required',
      field: 'orgId',
    });
    return null;
  }
  if (required.includes('startTime') && !reportData.startTime && !isScheduled) {
    setErrorMessage({
      message: 'Start date is required',
      field: 'startTime',
    });
    return null;
  }
  if (required.includes('endTime') && !reportData.endTime && !isScheduled) {
    setErrorMessage({
      message: 'End date is required',
      field: 'endTime',
    });
    return null;
  }
  if (scheduledReportOptions) {
    const {
      frequency, time, weekday, title,
    } = scheduledReportOptions;
    if (!title.length) {
      setErrorMessage({ message: 'Report title is required', field: 'title' });
      return null;
    }
    if (!frequency) {
      setErrorMessage({ message: 'Frequency is required', field: 'frequency' });
      return null;
    }
    if (frequency.value === 'Monthly' && !reportData.startTime) {
      setErrorMessage({
        message: 'Start date is required',
        field: 'startTime',
      });
      return null;
    } if ((frequency.value === 'Weekly' || frequency.value === 'Fortnightly') && !weekday) {
      setErrorMessage({
        message: 'Start date is required',
        field: 'startTime',
      });
      return null;
    }
    if (!time) {
      setErrorMessage({ message: 'Time is required', field: 'time' });
      return null;
    }

    const cronString = generateCronString({
      frequency: frequency.value,
      timeValue: time.value,
      startDate: reportData.startTime,
      weekday: weekday?.value,
    });

    return {
      userReportName: title,
      reportName: selectedReportType.pk,
      cronUtc: cronString,
      options: {
        ...reportData,
        reportName: selectedReportType.pk,
      },
      ...(frequency.value === 'Fortnightly' ? { everyOtherWeek: true } : {}),
    };
  }
  return reportData;
};

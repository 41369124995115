import { v4 as uuid } from 'uuid';
import React, { useEffect, useRef } from 'react';

import './DonutChart.scss';

interface DonutChartProps {
  size: number;
  colors: string[];
  ringWidth: number;
  data: { label: string; percent: number }[];
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  size,
  colors,
  ringWidth,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const total = data.reduce((sum, item) => sum + item.percent, 0);
        let startAngle = -Math.PI / 2; // Start from the top
        const centerX = size / 2;
        const centerY = size / 2;
        const radius = (size - ringWidth) / 2;

        // Clear the canvas
        ctx.clearRect(0, 0, size, size);

        // Draw each segment
        data.forEach((item, index) => {
          const sliceAngle = (item.percent / total) * 2 * Math.PI;
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius + ringWidth / 2, startAngle, startAngle + sliceAngle);
          ctx.arc(centerX, centerY, radius - ringWidth / 2, startAngle + sliceAngle, startAngle, true);
          ctx.closePath();
          ctx.fillStyle = colors[index % colors.length];
          ctx.fill();
          startAngle += sliceAngle;
        });
      }
    }
  }, [data, colors, ringWidth, size]);

  return (
    <section className="donut-chart-container">
      <canvas ref={canvasRef} width={size} height={size} />
      <div className="donut-chart-legend">
        {data?.map((item, index) => (
          <div
            key={uuid()}
            className="donut-chart-legend-item"
          >
            <span
              className="donut-chart-legend-item-color"
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: colors[index % colors.length],
              }}
            />
            <span className="donut-chart-legend-item-value">
              {item.label}
            </span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DonutChart;

import { Skeleton } from 'antd';

const WorkOrderLoadingColumn = () => (
  <Skeleton.Input active />
);

export const WORK_ORDER_TABLE_LOADING_COLUMNS = [
  {
    title: 'Start Time',
    dataIndex: 'start_time',
    render: WorkOrderLoadingColumn,
  },
  {
    title: 'Work Order Id',
    dataIndex: 'id',
    render: WorkOrderLoadingColumn,
  },
  {
    title: 'Mechanic',
    dataIndex: 'mechanic_name',
    render: WorkOrderLoadingColumn,
  },
  {
    title: 'Type',
    dataIndex: 'call_type',
    render: WorkOrderLoadingColumn,
  },
  {
    title: 'Job Safety Analysis',
    render: WorkOrderLoadingColumn,
  },
];

export default WorkOrderLoadingColumn;

import { repositoryFetch } from 'shared/helpers/repositoryHelpers';

const notificationBase = '/v1/axil/notifications';

export const getUserPreferences = ({ accessToken }) => repositoryFetch({
  accessToken,
  endpoint: `${notificationBase}/list/preferences`,
  body: {},
});

export const updateUserPreferences = ({ accessToken, body }) => repositoryFetch({
  accessToken,
  endpoint: `${notificationBase}/update/preferences`,
  body,
});

export const getUserSubscriptions = ({ accessToken }) => repositoryFetch({
  accessToken,
  endpoint: `${notificationBase}/subscribed/assets`,
  body: {},
});

export const updateUserSubscriptions = ({ accessToken, body }) => repositoryFetch({
  accessToken,
  endpoint: `${notificationBase}/subscribe/assets`,
  body,
});

import { getUserGroups } from 'shared/repositories/permissionsRepository';
import * as actionTypes from 'shared/actions/permissionsActionTypes';
import { asyncActionCreator } from 'shared/helpers/actionHelpers';

export const fetchUserGroups = asyncActionCreator({
  repositoryAPI: getUserGroups,
  PENDING_ACTION_TYPE: actionTypes.PERMISSIONS__FETCH_PERMISSION_GROUPS,
  SUCCESS_ACTION_TYPE: actionTypes.PERMISSIONS__FETCH_PERMISSION_GROUPS_SUCCESS,
  FAILED_ACTION_TYPE: actionTypes.PERMISSIONS__FETCH_PERMISSION_GROUPS_FAILED,
});

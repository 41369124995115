import { createSlice } from '@reduxjs/toolkit';

import * as actionTypes from 'shared/actions/subscriptionsActionTypes';

export const defaultState = {
  isPending: false,
  subscriptions: {},
  errorResponse: null,
};

const handleSubscriptionsCall = (state) => ({
  ...state,
  isPending: true,
});

const handleSubscriptionsFetchSuccess = (state, { result: { results: { subscriptions } } }) => ({
  ...defaultState,
  subscriptions,
});

const handleSubscriptionsCallFailed = (state, { errorResponse }) => ({
  ...defaultState,
  errorResponse,
});

const handleSubscriptionsUpdateSuccess = (_, { subscriptions }) => ({
  ...defaultState,
  subscriptions,
});

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionTypes.FETCH_SUBSCRIPTIONS, handleSubscriptionsCall);
    builder.addCase(actionTypes.FETCH_SUBSCRIPTIONS_FAILED, handleSubscriptionsCallFailed);
    builder.addCase(actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS, handleSubscriptionsFetchSuccess);
    builder.addCase(actionTypes.UPDATE_SUBSCRIPTIONS, handleSubscriptionsCall);
    builder.addCase(actionTypes.UPDATE_SUBSCRIPTIONS_FAILED, handleSubscriptionsCallFailed);
    builder.addCase(actionTypes.UPDATE_SUBSCRIPTIONS_SUCCESS, handleSubscriptionsUpdateSuccess);
  },
});

export const selectSubscriptions = (state) => state?.subscriptions;

export default subscriptionsSlice.reducer;

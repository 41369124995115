import { createTableSorter } from 'dashboard/helpers/dashboardHelpers';

export const sections = {
  NEW_REPORT: '',
  SCHEDULED: 'scheduled',
  EDITING_REPORT: 'editingReport',
};

export const filterOptions = {
  ORGANIZATION: 'org',
  AREA: 'area',
};

export const allAreasOption = { label: 'All Areas', key: 'All Areas', value: 'All' };

export const reportsWithExtras = {
  FLUX_FLEET: 'flux-fleet',
  UNIT_PERFORMANCE: 'unit-performance',
};

export const extraReportDetailHeadings = {
  'flux-fleet': {
    title: 'Flux Fleet Report Details',
    subTitle: 'Deselect any unwanted report details',
  },
  'unit-performance': {
    title: 'Unit Performance Type',
    subTitle: 'Your report details.',
  },
};

export const REPORT_TABLE_DROPDOWN_FIELDS = [
  'customer',
  'reportTypeText',
];

export const MINIMUM_AUTOCOMPLETE_FILTER_LENGTH = 2;

export const REPORT_TABLE_COLUMNS = [
  {
    key: 'reportName',
    dataIndex: 'reportName',
    title: 'Report Name',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: {
      compare: createTableSorter('reportName'),
    },
  },
  {
    key: 'customer',
    dataIndex: 'customer',
    title: 'Customer',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: {
      compare: createTableSorter('customer'),
    },
  },
  {
    key: 'reportCycle',
    dataIndex: 'reportCycle',
    title: 'Report Cycle',
  },
  {
    key: 'reportType',
    dataIndex: 'reportType',
    title: 'Report Type',
  },
  {
    key: 'editReport',
    dataIndex: 'editReport',
    title: 'Edit Scheduled Report',
  },
];

export const START_DATE_HEADING = 'Send me the report on';
export const START_DATE_SUBHEADING = 'When you would like to receive your report.';

export const DELETE_MODAL_TITLE = 'Are you sure you want to delete the following report?';

export const TABLE_FILTER_PLACEHOLDER = 'Filter by report type ("Mechanical Availability")';

export const MILLISECONDS_IN_SINGLE_DAY = 86400000;

export const REPORT_ALREADY_EXISTS = 'A report with this title already exists';

export const REPORT_CREATION_FAILURE = 'Oops! There was an error submitting your report. Please try again.';
export const REPORT_CREATION_SUCCESS = 'Your report was submitted successfully';

export const REPORT_EDIT_FAILURE = 'Oops! There was an error submitting your report. Please try again.';
export const REPORT_EDIT_SUCCESS = 'Your report was updated successfully';

export const WEEKDAYS = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
];

export const FREQUENCY_OPTIONS = [
  {
    label: 'Every day',
    value: 'Daily',
  },
  {
    label: 'Every week',
    value: 'Weekly',
  },
  {
    label: 'Every 2 weeks',
    value: 'Fortnightly',
  },
  {
    label: 'Every month',
    value: 'Monthly',
  },
];

export const TIME_OPTIONS = [
  {
    label: '12:00 am',
    value: '00:00',
  }, {
    label: '12:30 am',
    value: '00:30',
  },
  {
    label: '1:00 am',
    value: '1:00',
  }, {
    label: '1:30 am',
    value: '1:30',
  }, {
    label: '2:00 am',
    value: '2:00',
  }, {
    label: '2:30 am',
    value: '2:30',
  }, {
    label: '3:00 am',
    value: '3:00',
  }, {
    label: '3:30 am',
    value: '3:30',
  }, {
    label: '4:00 am',
    value: '4:00',
  }, {
    label: '4:30 am',
    value: '4:30',
  }, {
    label: '5:00 am',
    value: '5:00',
  }, {
    label: '5:30 am',
    value: '5:30',
  }, {
    label: '6:00 am',
    value: '6:00',
  }, {
    label: '6:30 am',
    value: '6:30',
  }, {
    label: '7:00 am',
    value: '7:00',
  }, {
    label: '7:30 am',
    value: '7:30',
  }, {
    label: '8:00 am',
    value: '8:00',
  }, {
    label: '8:30 am',
    value: '8:30',
  }, {
    label: '9:00 am',
    value: '9:00',
  }, {
    label: '9:30 am',
    value: '9:30',
  }, {
    label: '10:00 am',
    value: '10:00',
  }, {
    label: '10:30 am',
    value: '10:30',
  }, {
    label: '11:00 am',
    value: '11:00',
  }, {
    label: '11:30 am',
    value: '11:30',
  },
  {
    label: '12:00 pm',
    value: '12:00',
  }, {
    label: '12:30 pm',
    value: '12:30',
  }, {
    label: '1:00 pm',
    value: '13:00',
  }, {
    label: '1:30 pm',
    value: '13:30',
  }, {
    label: '2:00 pm',
    value: '14:00',
  }, {
    label: '2:30 pm',
    value: '14:30',
  }, {
    label: '3:00 pm',
    value: '15:00',
  }, {
    label: '3:30 pm',
    value: '15:30',
  }, {
    label: '4:00 pm',
    value: '16:00',
  }, {
    label: '4:30 pm',
    value: '16:30',
  }, {
    label: '5:00 pm',
    value: '17:00',
  }, {
    label: '5:30 pm',
    value: '17:30',
  }, {
    label: '6:00 pm',
    value: '18:00',
  }, {
    label: '6:30 pm',
    value: '18:30',
  }, {
    label: '7:00 pm',
    value: '19:00',
  }, {
    label: '7:30 pm',
    value: '19:30',
  }, {
    label: '8:00 pm',
    value: '20:00',
  }, {
    label: '8:30 pm',
    value: '20:30',
  }, {
    label: '9:00 pm',
    value: '21:00',
  }, {
    label: '9:30 pm',
    value: '21:30',
  }, {
    label: '10:00 pm',
    value: '22:00',
  }, {
    label: '10:30 pm',
    value: '22:30',
  }, {
    label: '11:00 pm',
    value: '23:00',
  }, {
    label: '11:30 pm',
    value: '23:30',
  },
];

export const ASSET_EVENT_SEARCH_PLACEHOLDER_TEXT = 'Filter by event message';

export const AXIL_EVENTS_RECIPIENT_INFO = '/v1/axil/events/contacts_by_rule_uuid';

export const eventFilterDropdownOptions = [
  {
    label: 'Running',
    value: 'running',
  },
  {
    label: 'Alarms',
    value: 'alarms',
  },
  {
    label: 'Faults',
    value: 'faults',
  },
];

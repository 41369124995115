import React from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

const AssetEventMessageColumn = ({
  record,
  currentEventForModal,
}) => {
  const direction = record === currentEventForModal;

  return (
    <div
      className="asset-event-message-column"
      data-testid="asset-event-message-column"
    >
      {record?.msg}
      {direction ? <IoChevronUp /> : <IoChevronDown />}
    </div>
  );
};

export default AssetEventMessageColumn;

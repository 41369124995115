import { useState } from 'react';

import './ChooseReport.scss';
import { SearchBar } from '@flogistix/flo-ui';

const ChooseReport = ({
  reports,
  selectedReport,
  setSelectedReport,
}) => {
  const [search, setSearch] = useState('');

  const filteredReports = reports.filter((report) => (
    report.title.toLowerCase().includes(search)
    || report.description.toLowerCase().includes(search)
  ));

  return (
    <div className="choose-report-container">
      <h3 className="flux-autocomplete-descriptor">
        Choose Report Type
        <span className="required-asterisk"> * </span>
      </h3>
      <SearchBar
        iconName="Filter"
        placeholder='Filter by report ("Asset List")'
        onChange={(e) => setSearch(e.target.value.toLowerCase())}
      />
      <div className="card-section">
        {
          filteredReports.map((report) => (
            <div
              role="button"
              tabIndex={0}
              key={report.title}
              onClick={() => setSelectedReport(report)}
              className={`report-card ${report === selectedReport ? 'active' : ''}`}
            >
              <p>{report.title}</p>
              <p>{report.description}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ChooseReport;

import { asyncActionCreator } from 'shared/helpers/actionHelpers';
import * as actionTypes from 'shared/actions/organizationsActionTypes';
import { getUserOrganizations } from 'shared/repositories/reportingRepository';

export const fetchUserOrganizations = asyncActionCreator({
  repositoryAPI: getUserOrganizations,
  PENDING_ACTION_TYPE: actionTypes.FETCH_ORGANIZATIONS,
  FAILED_ACTION_TYPE: actionTypes.FETCH_ORGANIZATIONS_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.FETCH_ORGANIZATIONS_SUCCESS,
});

import React from 'react';

import { Input } from '@flogistix/flo-ui';

interface GasAnalysisFormRowProps {
  label: string,
  value: string | number | null,
  // eslint-disable-next-line no-unused-vars
  handleInput: (input: string) => void,
}

const GasAnalysisFormRow: React.FC<GasAnalysisFormRowProps> = ({ label, value, handleInput }) => (
  <section className="gas-analysis-card-input-row">
    <span className="gas-analysis-card-input-label">
      {label}
    </span>
    <Input
      fixedWidth="117px"
      placeholder="Enter Mol %"
      value={value?.toString()}
      className="gas-analysis-card-input"
      onChange={(e) => handleInput(e?.target?.value)}
    />
  </section>
);

export default GasAnalysisFormRow;

import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Card, Dropdown } from '@flogistix/flo-ui';

import { selectSession } from 'shared/reducers/sessionReducer';
import { getUniqueValuesWithLabel } from 'shared/helpers/unitHelpers';
import { postGasAnalysis } from 'shared/repositories/gasAnalysisRepository';

import {
  openRedirect,
  getUnitIdValue,
  removeUnitParameter,
} from 'dashboard/helpers/dashboardHelpers';
import { IDropdownOption } from 'emissions/pages/EmissionsPage';
import { generateUnitViewLink } from 'shared/helpers/assetHelpers';

import GasAnalysisFormRow from 'emissions/components/GasAnalysisFormRow';
import GasAnalysisModal from 'emissions/components/GasAnalysisModal';

import './EmissionsGasAnalysisView.scss';

interface EmissionsGasAnalysisViewProps { units: any[]; }

const EmissionsGasAnalysisView: React.FC<EmissionsGasAnalysisViewProps> = ({ units }) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const session = useSelector(selectSession);

  const currentUrl = `${pathname}${search}`;
  const newUrl = new URL(currentUrl, window.location.origin);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const gasAnalysisSuccessMessage = 'Adding this data will remove any existing gas analysis and modify the equation breakdown.';
  const gasAnalysisErrorMessage = 'Error - there was an error with your gas analysis submission. Please try again or contact support.';

  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [unitSearchString, setUnitSearchString] = useState('');
  const [unitDropdownOptions, setUnitDropdownOptions] = useState<IDropdownOption[]>([]);
  const [selectedUnitOption, setSelectedUnitOption] = useState<IDropdownOption | null>(null);

  const [molTotal, setMolTotal] = useState<number>(0);
  const [ethaneMol, setEthaneMol] = useState<string | null>(null);
  const [butaneMol, setButaneMol] = useState<string | null>(null);
  const [hexaneMol, setHexaneMol] = useState<string | null>(null);
  const [methaneMol, setMethaneMol] = useState<string | null>(null);
  const [iButaneMol, setIButaneMol] = useState<string | null>(null);
  const [pentaneMol, setPentaneMol] = useState<string | null>(null);
  const [propaneMol, setPropaneMol] = useState<string | null>(null);
  const [iPentaneMol, setIPentaneMol] = useState<string | null>(null);
  const [nitrogenMol, setNitrogenMol] = useState<string | null>(null);
  const [carbonDioxideMol, setCarbonDioxideMol] = useState<string | null>(null);
  const [gasAnalysisRequestBody, setGasAnalysisRequestBody] = useState<any>(
    {
      siteId: '',
      operatingAreaId: '',
      assetId: selectedUnit?.value,
      gasMolPercents: {
        hexanePct: Number(hexaneMol),
        butanePct: Number(butaneMol),
        ethanePct: Number(ethaneMol),
        methanePct: Number(methaneMol),
        propanePct: Number(propaneMol),
        iButanePct: Number(iButaneMol),
        pentanePct: Number(pentaneMol),
        nitrogenPct: Number(nitrogenMol),
        iPentanePct: Number(iPentaneMol),
        carbonDioxidePct: Number(carbonDioxideMol),
      },
    },
  );

  const clearAllFormInputs = () => {
    setEthaneMol('');
    setHexaneMol('');
    setButaneMol('');
    setPropaneMol('');
    setMethaneMol('');
    setIButaneMol('');
    setPentaneMol('');
    setIPentaneMol('');
    setNitrogenMol('');
    setCarbonDioxideMol('');
  };

  const removeUnitParam = () => {
    const noUnitUrl = removeUnitParameter(currentUrl);
    navigate(noUnitUrl);
  };

  const handleUnitFilter = (selectedOption: IDropdownOption) => {
    newUrl.searchParams.set('unit_id', selectedOption?.value as string);
    navigate(newUrl.pathname + newUrl.search);
  };

  const generateDropdowns = () => {
    const uniqueAssetName = getUniqueValuesWithLabel('asset_name', units);

    const formattedOrgOptions = uniqueAssetName?.map((entry: any) => ({
      label: entry?.label,
      value: entry?.asset_name,
    }));

    setUnitDropdownOptions(formattedOrgOptions);
  };

  const createGasAnalysis = async () => {
    const formattedBody = {
      ...gasAnalysisRequestBody,
      assetId: selectedUnit?.asset_id?.toString(),
    };

    try {
      const response = await postGasAnalysis({
        body: formattedBody,
        accessToken: session?.token,
      });

      if (!response?.ok) {
        setModalMessage(gasAnalysisErrorMessage);
        setShowModal(true);
        throw new Error(`Fetch failed with status: ${response?.status}`);
      }

      setModalMessage(gasAnalysisSuccessMessage);
      setShowModal(true);
    } catch (error) {
      console.error('Error creating or updating gas analysis:', error);
    }
  };

  const selectUnitView = (event: any) => {
    const link = generateUnitViewLink(selectedUnit);
    openRedirect(event, link);
  };

  useEffect(() => {
    if (!search?.length) {
      setSelectedUnit(null);
      setSelectedUnitOption(null);
      return;
    }

    const paramId = getUnitIdValue(search);
    if (paramId) {
      const currentUnit: any = units?.find((unit: any) => unit?.asset_name?.toString() === paramId?.toString());
      const currentUnitOption: IDropdownOption = {
        value: currentUnit?.asset_name,
        label: `Asset - ${currentUnit?.asset_name}`,
      };

      currentUnit && setSelectedUnit(currentUnit);
      currentUnitOption && setSelectedUnitOption(currentUnitOption);
    } else setSelectedUnitOption(null);
  }, [search, units]);

  useEffect(() => {
    session?.token && units?.length && generateDropdowns();
  }, [session, units]);

  useEffect(() => {
    const updatedGasAnalysisRequestBody = {
      ...gasAnalysisRequestBody,
      gasMolPercents: {
        hexanePct: Number(hexaneMol),
        butanePct: Number(butaneMol),
        ethanePct: Number(ethaneMol),
        methanePct: Number(methaneMol),
        propanePct: Number(propaneMol),
        iButanePct: Number(iButaneMol),
        pentanePct: Number(pentaneMol),
        nitrogenPct: Number(nitrogenMol),
        iPentanePct: Number(iPentaneMol),
        carbonDioxidePct: Number(carbonDioxideMol),
      },
    };

    const newTotal = (Number(ethaneMol) || 0)
      + (Number(butaneMol) || 0)
      + (Number(hexaneMol) || 0)
      + (Number(methaneMol) || 0)
      + (Number(iButaneMol) || 0)
      + (Number(pentaneMol) || 0)
      + (Number(propaneMol) || 0)
      + (Number(iPentaneMol) || 0)
      + (Number(nitrogenMol) || 0)
      + (Number(carbonDioxideMol) || 0);

    const roundedTotal = Number(newTotal?.toFixed(6));

    setMolTotal(roundedTotal);
    setGasAnalysisRequestBody(updatedGasAnalysisRequestBody);
  }, [ethaneMol, butaneMol, hexaneMol, methaneMol, iButaneMol, pentaneMol, propaneMol, iPentaneMol, nitrogenMol, carbonDioxideMol]);

  return !unitDropdownOptions?.length
    ? <p>Loading...</p>
    : (
      <section className="emissions-gas-analysis-view">
        <p className="gas-analysis-title">Unit</p>
        <Dropdown
          fixedWidth="350px"
          onClear={removeUnitParam}
          placeholder="Search Units"
          onSelect={handleUnitFilter}
          searchValue={unitSearchString}
          className="gas-analysis-unit-select"
          value={selectedUnitOption?.label as string}
          onSearchChange={(input) => setUnitSearchString(input)}
          options={unitDropdownOptions?.filter((entry) => entry?.label?.toLowerCase()?.includes(unitSearchString.toLowerCase()))}
        />
        <p className="gas-analysis-title">Add Gas Analysis</p>
        <Card className="gas-analysis-card">
          <section className="gas-analysis-card-header">
            <p className="gas-analysis-card-header-text">Component</p>
            <p className="gas-analysis-card-header-text">Mol%</p>
          </section>
          <div className="gas-analysis-inputs">
            <GasAnalysisFormRow
              label="Carbon Dioxide"
              value={carbonDioxideMol}
              handleInput={setCarbonDioxideMol}
            />
            <GasAnalysisFormRow
              label="Nitrogen"
              value={nitrogenMol}
              handleInput={setNitrogenMol}
            />
            <GasAnalysisFormRow
              label="Methane"
              value={methaneMol}
              handleInput={setMethaneMol}
            />
            <GasAnalysisFormRow
              label="Ethane"
              value={ethaneMol}
              handleInput={setEthaneMol}
            />
            <GasAnalysisFormRow
              label="Propane"
              value={propaneMol}
              handleInput={setPropaneMol}
            />
            <GasAnalysisFormRow
              label="Butane"
              value={butaneMol}
              handleInput={setButaneMol}
            />
            <GasAnalysisFormRow
              label="I-Butane"
              value={iButaneMol}
              handleInput={setIButaneMol}
            />
            <GasAnalysisFormRow
              label="Pentane"
              value={pentaneMol}
              handleInput={setPentaneMol}
            />
            <GasAnalysisFormRow
              label="I-Pentane"
              value={iPentaneMol}
              handleInput={setIPentaneMol}
            />
            <GasAnalysisFormRow
              label="Hexane"
              value={hexaneMol}
              handleInput={setHexaneMol}
            />
          </div>
          <div className="gas-analysis-card-total">
            <span className="gas-analysis-card-total-label">
              Total
            </span>
            <span className="gas-analysis-card-total-value">
              {molTotal}
            </span>
          </div>
        </Card>
        <div className="gas-analysis-buttons">
          <Button
            variation="ghost"
            onClick={clearAllFormInputs}
            className="gas-analysis-clear-button"
          >
            Clear all
          </Button>
          <Button
            onClick={createGasAnalysis}
            className="gas-analysis-add-button"
            disabled={molTotal < 0 || molTotal > 1 || !selectedUnit}
          >
            Add
          </Button>
        </div>
        <GasAnalysisModal
          isOpen={showModal}
          message={modalMessage}
          closeModal={() => setShowModal(false)}
          redirectClick={(e) => selectUnitView(e)}
        />
      </section>
    );
};

export default EmissionsGasAnalysisView;

import { Avatar, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Button } from '@flogistix/flo-ui';

import Logout from 'shared/components/LogoutButton';

import './ProfileModal.scss';

const ProfileModal = ({ isOpen, closeModal, user }) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={isOpen}
      footer={[]}
      className="profile-modal"
      onCancel={() => closeModal()}
      wrapClassName="profile-modal-dialog"
      maskStyle={{ backgroundColor: 'transparent' }}
    >
      <Avatar size={88} src={user?.picture} />
      <div className="user-info-group">
        <p>{user?.name}</p>
        <p>{user?.email}</p>
      </div>
      <div className="button-group">
        <Button
          variation="gray"
          style={{ marginBottom: '8px' }}
          onClick={() => {
            navigate('/notifications');
            closeModal();
          }}
        >
          Manage your Flogistix Account
        </Button>
        <Logout />
      </div>
    </Modal>
  );
};

export default ProfileModal;

import { Switch } from 'antd';

export const generateNotificationTableColumns = ({
  activeSection,
  selectUnitView,
  addSubscriptionDetail,
  toggleSubscriptionDetail,
}) => [
  {
    sortable: true,
    title: 'Organization',
    dataIndex: 'org_name',
    defaultSort: 'ascending',
  },
  {
    sortable: true,
    title: 'Lease Name',
    dataIndex: 'site_name',
  },
  {
    sortable: true,
    title: 'Asset',
    dataIndex: 'asset_name',
    render: (value, record) => (
      <div
        style={{ width: '150px' }}
        onClick={(e) => selectUnitView(e, record)}
      >
        {value}
      </div>
    ),
  },
  {
    title: 'All',
    dataIndex: 'all',
    render: (value, record) => (
      <Switch
        checked={value}
        onClick={activeSection === 'My Notifications'
          ? () => toggleSubscriptionDetail(record, 'all')
          : () => addSubscriptionDetail(record, 'all')}
      />
    ),
  },
  {
    title: 'Alarms',
    dataIndex: 'warning',
    render: (value, record) => (
      <Switch
        checked={value}
        onClick={activeSection === 'My Notifications'
          ? () => toggleSubscriptionDetail(record, 'warning')
          : () => addSubscriptionDetail(record, 'warning')}
      />
    ),
  },
  {
    title: 'Faults',
    dataIndex: 'danger',
    render: (value, record) => (
      <Switch
        checked={value}
        onClick={activeSection === 'My Notifications'
          ? () => toggleSubscriptionDetail(record, 'danger')
          : () => addSubscriptionDetail(record, 'danger')}
      />
    ),
  },
  {
    title: 'Running',
    dataIndex: 'success',
    render: (value, record) => (
      <Switch
        checked={value}
        onClick={activeSection === 'My Notifications'
          ? () => toggleSubscriptionDetail(record, 'success')
          : () => addSubscriptionDetail(record, 'success')}
      />
    ),
  },
];

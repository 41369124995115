import { useState } from 'react';

import ChartForm from 'single-asset/components/charts/edit-charts/ChartForm';
import ChartsContentSection from 'single-asset/components/charts/ChartsContentSection';

import './ChartsContainer.scss';

const ChartsContainer = ({
  asset,
  charts,
  getCharts,
  chartDates,
  chartsRange,
  setChartDates,
  customMetrics,
  chartables,
  setChartsRange,
  updateChartsLayout,
  chartLayout,
  setEditedChartLayout,
  addChartToLayout,
  applyLayoutToTemplate,
  chartLayoutType,
}) => {
  const [isEditingCharts, setIsEditingCharts] = useState(false);
  const [displayGridView, setDisplayGridView] = useState(false);

  const resetChartsLayout = () => setEditedChartLayout(null);

  return (
    <div className="charts-container">
      <ChartForm
        asset={asset}
        getCharts={getCharts}
        chartDates={chartDates}
        chartsRange={chartsRange}
        setChartDates={setChartDates}
        customMetrics={customMetrics}
        chartables={chartables}
        setChartsRange={setChartsRange}
        isEditingCharts={isEditingCharts}
        displayGridView={displayGridView}
        resetChartsLayout={resetChartsLayout}
        setDisplayGridView={setDisplayGridView}
        setIsEditingCharts={setIsEditingCharts}
        updateChartsLayout={updateChartsLayout}
        applyLayoutToTemplate={applyLayoutToTemplate}
        addChartToLayout={addChartToLayout}
        chartLayoutType={chartLayoutType}
        chartLayout={chartLayout}
      />
      {
        charts.length > 0 ? (
          <ChartsContentSection
            getCharts={getCharts}
            chartsData={charts}
            isEditingCharts={isEditingCharts}
            displayGridView={displayGridView}
            resetChartsLayout={resetChartsLayout}
            chartLayout={chartLayout}
            setEditedChartLayout={setEditedChartLayout}
          />
        ) : <p className="empty-charts-section">No charts available</p>
      }
    </div>
  );
};

export default ChartsContainer;

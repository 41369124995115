import { createSlice } from '@reduxjs/toolkit';

import * as actionTypes from 'shared/actions/organizationsActionTypes';

export const defaultState = {
  isPending: false,
  organizations: [],
  errorResponse: null,
};

const handleUserOrganizationsFetch = (state) => ({
  ...state,
  isPending: true,
});

const handleUserOrganizationsFetchSuccess = (state, { result: { orgs } }) => ({
  ...state,
  isPending: false,
  organizations: orgs,
  errorResponse: null,
});

const handleUserOrganizationsFetchFailed = (_, { errorResponse }) => ({
  ...defaultState,
  errorResponse,
});

const organizationsSlice = createSlice({
  reducers: {},
  name: 'organizations',
  initialState: defaultState,
  extraReducers: (builder) => {
    builder.addCase(actionTypes.FETCH_ORGANIZATIONS, handleUserOrganizationsFetch);
    builder.addCase(actionTypes.FETCH_ORGANIZATIONS_FAILED, handleUserOrganizationsFetchFailed);
    builder.addCase(actionTypes.FETCH_ORGANIZATIONS_SUCCESS, handleUserOrganizationsFetchSuccess);
  },
});

export const selectOrganizations = (state) => state.organizations;

export default organizationsSlice.reducer;

import { SearchBar } from '@flogistix/flo-ui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ALL_DECODER_GROUPS, decoderGroupTitleMap } from 'shared/constants/permissionsConstants';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import DecoderCard from 'shared/components/decoder/DecoderCard';
import { decodeFunctionMap } from 'single-asset/helpers/decoderHelpers';

interface DecoderProps {
  orgId: number | null;
}

const Decoder = ({ orgId }: DecoderProps) => {
  const { groups: userGroups } = useSelector(selectPermissions);
  const { body: localGroups } = JSON.parse(localStorage.getItem('groups') || '{}');

  const permissionGroups = navigator.onLine ? userGroups : localGroups;

  const [plcValue, setPlcValue] = useState<string>('');

  return (
    <div className="decoder-page--content">
      <SearchBar
        placeholder="Enter PLC Value"
        iconName="Lock"
        onChange={(e) => setPlcValue(e.target.value)}
        size="small"
      />
      <div className="decoder-page--content--output-cards">
        {
          ALL_DECODER_GROUPS.map((group: string) => {
            if ((permissionGroups ?? []).includes(group)) {
              return (
                <DecoderCard
                  key={group}
                  roleTitle={decoderGroupTitleMap[group as keyof typeof decoderGroupTitleMap]}
                  plcValue={plcValue}
                  decodingFunction={decodeFunctionMap[group as keyof typeof decodeFunctionMap]}
                  orgId={orgId}
                />
              );
            }
            return null;
          })
        }
      </div>
    </div>
  );
};

export default Decoder;

import {
  WORK_ORDERS_API,
  WORK_ORDERS_PRINT_API,
} from 'shared/repositories/urls';
import { repositoryFetch } from 'shared/helpers/repositoryHelpers';

import { GET_WORK_ORDERS_ENDPOINT } from 'single-asset/constants/workOrdersConstants';

export const getWorkOrders = ({ body, accessToken }) => {
  const url = `${WORK_ORDERS_API}${GET_WORK_ORDERS_ENDPOINT}`;
  const restOfHeaderProps = {
    'x-api-key': process.env.REACT_APP_WORK_ORDERS_KEY,
  };

  return repositoryFetch({
    accessToken,
    body,
    restOfHeaderProps,
    url,
  });
};

export const showWorkOrderInfo = (id, hash, showJSA) => {
  const jsaRoute = `/v4/floworks/print/jsa?id=${id}&hash=${hash}`;
  const viewRoute = `/v4/floworks/print/workorder?id=${id}&hash=${hash}`;

  const url = `${WORK_ORDERS_PRINT_API}${showJSA ? jsaRoute : viewRoute}`;

  window.open(url, '_blank');
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { setDefaultBreakpoints } from 'react-socks';

import { Auth0Provider } from '@auth0/auth0-react';
import { ChartingProvider } from 'context/ChartingContext';
import authConfig from 'shared/constants/authConfig';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-grid-layout/css/styles.css';
import store from './store';

import './index.scss';

const {
  domain, clientID, audience, scope,
} = authConfig;
const root = createRoot(document.getElementById('root'));

setDefaultBreakpoints([
  { xsmall: 0 },
  { small: 576 },
  { medium: 769 },
  { large: 992 },
  { xlarge: 1200 },
]);

serviceWorkerRegistration.register();

root.render(
  <ReduxProvider store={store}>
    <Router>
      <Auth0Provider
        domain={domain}
        clientId={clientID}
        redirectUri={window.location.origin}
        scope={scope}
        audience={audience}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <ChartingProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ChartingProvider>
      </Auth0Provider>
    </Router>
  </ReduxProvider>,
);

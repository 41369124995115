import { v4 as uuid } from 'uuid';

import SearchableDropdown from 'shared/components/SearchableDropdown';

import { filterOptions, allAreasOption } from 'reporting/constants/reportingConstants';

const ReportFilters = ({
  areas,
  singleArea,
  reportFilters,
  selectedOrgName,
  orgDropdownItems,
  handleDropdownSearch,
  dropdownOptionFilters,
  handleDropdownSelection,
  errored: { field: errorField } = { field: '' },
}) => {
  const areaDropdownOptions = [allAreasOption, ...areas.map((area) => ({
    key: uuid(),
    label: area,
    value: area,
  }))].filter((area) => area.label.toLowerCase().includes(dropdownOptionFilters.area));

  const orgDropdownOptions = orgDropdownItems
    .filter((org) => org.label.toLowerCase().includes(dropdownOptionFilters.org));

  return (
    <div className="filter-section">
      <div className="filter-selects">
        <SearchableDropdown
          itemHeader="Organizations"
          label="Filter: Organization"
          searchPlaceholder='Search "DCP"'
          dropdownValue={reportFilters.org}
          placeholder="Select Organization"
          dropdownItems={orgDropdownOptions}
          searchValue={dropdownOptionFilters.org}
          errored={errorField === 'orgId' && !reportFilters.org}
          onSearchChange={handleDropdownSearch(filterOptions.ORGANIZATION)}
          onItemSelect={handleDropdownSelection(filterOptions.ORGANIZATION)}
        />
        {areas.length >= 1
          && (
            <SearchableDropdown
              label="Filter: Area"
              disabled={singleArea}
              placeholder="Select Area"
              itemHeader={selectedOrgName}
              dropdownItems={areaDropdownOptions}
              searchValue={dropdownOptionFilters.area}
              searchPlaceholder='Search "Permian Basin'
              onSearchChange={handleDropdownSearch(filterOptions.AREA)}
              onItemSelect={handleDropdownSelection(filterOptions.AREA)}
              dropdownValue={
                !singleArea
                  ? reportFilters.area
                  : `Only one area for ${selectedOrgName}`
              }
            />
          )}
      </div>
    </div>
  );
};

export default ReportFilters;

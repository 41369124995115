import { repositoryFetch } from 'shared/helpers/repositoryHelpers';

const connectivityUrl = '/flux/services/connectivity';

export const fetchEnterpriseObject = ({ accessToken, body: { assetName } }) => repositoryFetch({
  accessToken,
  method: 'GET',
  endpoint: `/enterprise-objects/assets?name=${assetName}`,
  isAxil: true,
});

export const checkIpValidity = ({
  accessToken,
  body: {
    orgId,
    siteId,
    assetId,
    ipAddress,
    ipPort,
  },
}) => repositoryFetch({
  accessToken,
  method: 'GET',
  isAxil: true,
  endpoint: `${connectivityUrl}/orgs/${orgId}/sites/${siteId}/assets/${assetId}?ipAddress=${ipAddress}&port=${ipPort}`,
});

export const connectNewIpAddress = ({
  accessToken,
  body,
}) => repositoryFetch({
  accessToken,
  body,
  isAxil: true,
  endpoint: '/integrations/axil/events',
});

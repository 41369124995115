import {
  CUSTOMER_ADVANCED_TECH,
  CUSTOMER_DECODER,
  FLOGISTIX_ADVANCED_TECH,
  FLOGISTIX_DECODER,
} from 'shared/constants/permissionsConstants';
import {
  PLC_CON1,
  PLC_CON2,
  PLC_CON3,
} from '../constants/decoderConstants';

export const decodeFlogistixProgrammer = (plcValue: string) => {
  const plcHexValue = parseInt(plcValue, 16);
  if (Number.isNaN(plcHexValue)) return 'Invalid';

  const result = ((plcHexValue + PLC_CON1) / PLC_CON2);

  return Math.floor(result).toString();
};

export const decodeFlogistixAdvancedTech = (plcValue: string) => {
  const plcHexValue = parseInt(plcValue, 16);
  if (Number.isNaN(plcHexValue)) return 'Invalid';
  const result = ((plcHexValue + PLC_CON3) / PLC_CON2);

  return Math.floor(result).toString();
};

export const decodeCustomerProgrammer = (plcValue: string, orgId: number) => {
  const plcHexValue = parseInt(plcValue, 16);
  if (Number.isNaN(plcHexValue)) return 'Invalid';
  const result = ((plcHexValue + orgId + PLC_CON3) / PLC_CON2);

  return Math.floor(result).toString();
};

export const decodeCustomerAdvancedTech = (plcValue: string, orgId: number) => {
  const plcHexValue = parseInt(plcValue, 16);
  if (Number.isNaN(plcHexValue)) return 'Invalid';
  const result = ((plcHexValue + orgId + PLC_CON1) / PLC_CON2);

  return Math.floor(result).toString();
};

export const decodeFunctionMap = {
  [CUSTOMER_ADVANCED_TECH]: decodeCustomerAdvancedTech,
  [CUSTOMER_DECODER]: decodeCustomerProgrammer,
  [FLOGISTIX_ADVANCED_TECH]: decodeFlogistixAdvancedTech,
  [FLOGISTIX_DECODER]: decodeFlogistixProgrammer,
};

import React from 'react';

import DashboardStat from 'dashboard/components/stats/DashboardStat';
import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';

import './DashboardStats.scss';

const DashboardStats = ({
  statsLoading,
  dashboardStats,
}) => (
  <section className="dashboard-stats">
    <DashboardStat
      label="RTP"
      statLoading={statsLoading}
      stat={dashboardStats?.rtpStat ?? MISSING_FIELD_VALUE_PLACEHOLDER}
    />
    <DashboardStat
      label="Total"
      statLoading={statsLoading}
      stat={dashboardStats?.totalUnitsStat ?? MISSING_FIELD_VALUE_PLACEHOLDER}
    />
    <DashboardStat
      statLoading={statsLoading}
      label="Communicating Units"
      stat={dashboardStats?.totalCommunicatingUnitsStat ?? MISSING_FIELD_VALUE_PLACEHOLDER}
    />
    <DashboardStat
      label="Running"
      statLoading={statsLoading}
      stat={dashboardStats?.runningStat ?? MISSING_FIELD_VALUE_PLACEHOLDER}
    />
    <DashboardStat
      label="Not Running"
      statLoading={statsLoading}
      stat={dashboardStats?.notRunningStat ?? MISSING_FIELD_VALUE_PLACEHOLDER}
    />
    <DashboardStat
      label="Faulted"
      statLoading={statsLoading}
      stat={dashboardStats?.faultedStat ?? MISSING_FIELD_VALUE_PLACEHOLDER}
    />
    <DashboardStat
      label="Off"
      border={false}
      statLoading={statsLoading}
      stat={dashboardStats?.offStat ?? MISSING_FIELD_VALUE_PLACEHOLDER}
    />
  </section>
);

export default DashboardStats;

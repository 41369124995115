import React from 'react';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { checkDecoderPermissions } from 'single-asset/helpers/singleAssetHelpers';
import Decoder from 'shared/components/decoder/Decoder';
import '../../shared/styles/DecoderPage.scss';

interface DecoderPageProps {
  orgId: number;
}

const DecoderPage = ({ orgId }: DecoderPageProps) => {
  const { groups: userGroups } = useSelector(selectPermissions);

  return checkDecoderPermissions(userGroups) ? (
    <section className="unit-decoder decoder-page">
      <h1>Decoder</h1>
      <Decoder orgId={orgId} />
    </section>
  ) : null;
};

export default DecoderPage;

import React, { useState, useRef, useEffect } from 'react';
import { IoCheckmark, IoChevronDown, IoChevronUp } from 'react-icons/io5';

import {
  DEFAULT_CHART_RANGE_LABEL,
  DEFAULT_OPTION_DESCRIPTOR_TEXT,
} from 'shared/constants/datePickerConstants';

import ResetDatesButton from './ResetDatesButton';

import './FluxCustomSelect.scss';

const FluxCustomSelect = ({
  options,
  handleChange,
  defaultOption,
  showResetButton = false,
  handleResetClick = () => console.log('Reset'),
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectOption, setSelectedOption] = useState(null);

  const handleReset = (e) => {
    e.stopPropagation();
    handleResetClick();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleSelect = (option) => {
    setIsOpen(false);
    setSelectedOption(option);

    handleChange(option);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={dropdownRef} className="flux-custom-select">
      <div
        className="flux-select-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className="flux-select-label">
          {selectOption?.label || defaultOption?.label}
        </h4>
        {showResetButton
            && <ResetDatesButton handleClick={handleReset} />}
        {isOpen
          ? <IoChevronUp />
          : <IoChevronDown />}
      </div>
      {isOpen && (
        <div className="flux-select-dropdown">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => handleSelect(option)}
              className={
                defaultOption?.label === option?.label
                  || selectOption?.label === option?.label
                  ? 'flux-select-option-selected'
                  : 'flux-select-option'
              }
            >
              <span className="flux-select-option-label">
                {option?.label}
              </span>
              {defaultOption?.label === option?.label
                  && <IoCheckmark />}
              {selectOption !== option
                && option?.label === DEFAULT_CHART_RANGE_LABEL
                  && <span className="flux-select-default-label">{DEFAULT_OPTION_DESCRIPTOR_TEXT}</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FluxCustomSelect;

import { Skeleton } from 'antd';

import './AccountInformationSkeleton.scss';

const AccountInformationSkeleton = ({ active }) => (
  <Skeleton
    active={active}
    paragraph={{ rows: 0 }}
    title={{ width: '100%' }}
    className="account-info-skeleton"
  />
);

export default AccountInformationSkeleton;

import { Route, Routes } from 'react-router-dom';
import NewReportCreation from 'reporting/components/new-report/NewReportCreation';
import ScheduledReportSection from 'reporting/components/scheduled-reports/ScheduledReportSection';

const ReportingRoutes = ({
  orgs,
  onSubmit,
  availableReports,
  reportBeingEdited,
  setReportBeingEdited,
}) => (
  <Routes>
    <Route
      exact
      path="/"
      element={(
        <NewReportCreation
          orgs={orgs}
          onSubmit={onSubmit}
          availableReports={availableReports}
          reportBeingEdited={reportBeingEdited}
          setReportBeingEdited={setReportBeingEdited}
        />
    )}
    />
    <Route
      exact
      path="/scheduled"
      element={(
        <ScheduledReportSection
          organizations={orgs}
          setReportBeingEdited={setReportBeingEdited}
        />
      )}
    />
  </Routes>
);

export default ReportingRoutes;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { selectSession } from 'shared/reducers/sessionReducer';

import { checkDecoderPermissions } from 'single-asset/helpers/singleAssetHelpers';
import { getNavLinks } from 'shared/constants/navItems';
import { isUserExternal } from 'shared/helpers/permissionHelpers';

import Header from './Header';
import SideNav from './SideNav';

import './SideNav.scss';

const AppNavigation = ({
  openProfileModal,
  canAccessReports,
  preferences,
  isOffline,
}) => {
  const { pathname } = useLocation();
  const { groups } = useSelector(selectPermissions);
  const { body: localUserGroups } = JSON.parse(localStorage.getItem('groups') ?? '{}');
  const { user } = useSelector(selectSession);

  const external_user = isUserExternal(user?.email);
  const hasDecoderAndIpAccess = checkDecoderPermissions(isOffline ? localUserGroups : groups);
  const currentNavLinks = getNavLinks(preferences?.homepage, external_user, hasDecoderAndIpAccess);

  const [navLinks, setNavLinks] = useState(currentNavLinks);

  const [activeLink, setActiveLink] = useState({
    path: pathname,
    key: navLinks.find((link) => pathname === link.path)?.key,
  });

  useEffect(() => {
    const activeKey = navLinks.find((link) => `/${pathname.split('/').at(1)}` === link.path)?.key;

    setActiveLink({
      key: activeKey ?? 'Dashboard',
      path: activeKey === 'Dashboard' || !activeKey ? '/dashboard' : `/${pathname.split('/').at(1)}`,
    });
  }, [pathname, navLinks]);

  useEffect(() => {
    if (user && isOffline) {
      setNavLinks(getNavLinks(preferences?.homepage, external_user, checkDecoderPermissions(localUserGroups)));
    } else setNavLinks(getNavLinks(preferences?.homepage, external_user, hasDecoderAndIpAccess));
  }, [preferences, user, groups]);

  return (
    <>
      <Header
        user={user}
        navLinks={navLinks}
        activeLink={activeLink}
        openProfileModal={openProfileModal}
      />
      <SideNav
        navLinks={navLinks}
        activeLink={activeLink}
        preferences={preferences}
        canAccessReports={canAccessReports}
      />
    </>
  );
};

export default AppNavigation;

import React from 'react';
import { Skeleton } from 'antd';

import FluxTooltip from 'shared/components/FluxTooltip';
import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';
import { tagTitleToTooltipMap } from 'shared/constants/graphingAndStatisticsConstants';

import { getUnitStatColor } from 'dashboard/helpers/dashboardHelpers';

import './DashboardStat.scss';

const StatComponent = ({ stat, label }) => {
  const runtimeStatColor = getUnitStatColor(label, stat);

  const statValue = stat?.n_assets?.toString();
  const rtpStatValue = `${stat?.avg_rtp?.toFixed(2) || '_'} %`;

  const displayedStatValue = label === 'RTP'
    ? rtpStatValue
    : statValue;

  return (
    <div className="dashboard-stat">
      <div className="stat-value">
        <p style={{ color: runtimeStatColor }}>
          {displayedStatValue ?? MISSING_FIELD_VALUE_PLACEHOLDER}
        </p>
      </div>
      <div className="stat-info">
        <p className="stat-info-label">{label}</p>
        <FluxTooltip
          title={label}
          circleColor="#BCBCBC"
          description={tagTitleToTooltipMap[label]?.description}
        />
      </div>
    </div>
  );
};

const DashboardStat = ({
  stat,
  label,
  statLoading,
  border = true,
}) => (
  <>
    {!statLoading && stat ? (
      <StatComponent
        stat={stat}
        label={label}
      />
    ) : (
      <Skeleton
        active
        paragraph={{
          rows: 1,
          width: '50%',
        }}
        className="dashboard-stat-skeleton"
      />
    )}
    {border && <div className="stat-border" />}
  </>
);

export default DashboardStat;

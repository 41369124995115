import {
  useMemo,
  useState,
  useEffect,
  useTransition,
} from 'react';
import { Select } from 'antd';
import { VariableSizeList as List } from 'react-window';
import ResizeObserver from 'rc-resize-observer';

import { assetFieldNames } from 'shared/constants/altitudeConstants';

import AssetCardRow from 'dashboard/components/asset-display/AssetCardRow';
import { assetCardListSortOptions } from 'dashboard/constants/dashboardConstants';
import { createSingleTupleizedList, createTableSorter, tupleizeArray } from 'dashboard/helpers/dashboardHelpers';

import './AssetListCards.scss';

const AssetListCards = ({ assetList }) => {
  const [, startTransition] = useTransition();
  const [selectedSortOption, setSelectedSortOption] = useState(assetCardListSortOptions.at(0));
  const [tupleizedAssetList, setTupleizedAssetList] = useState([]);
  const [screenWidth, setScreenWidth] = useState(0);

  const itemData = useMemo(() => ({
    tupleizedAssetList,
  }), [tupleizedAssetList]);

  const selectedOptionLabel = (
    <span>
      Sort by <span className="asset-card--selected-option">{selectedSortOption.label}</span>
    </span>
  );
  const shouldSortDescending = ![assetFieldNames.LAST_SYNCED, assetFieldNames.RUNTIME].includes(selectedSortOption.value);

  const handleSortChange = (_, selectedOption) => {
    setSelectedSortOption(selectedOption);
  };

  useEffect(() => {
    startTransition(() => {
      const sortedAssetList = [...assetList].sort(createTableSorter(
        selectedSortOption.value,
        shouldSortDescending,
      ));
      setTupleizedAssetList(
        tupleizeArray(sortedAssetList),
      );
    });
  }, [assetList, selectedSortOption]);

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setScreenWidth(width);
      }}
    >
      <div className="asset-list-cards">
        <div>
          <Select
            size="large"
            style={{ width: 300 }}
            onChange={handleSortChange}
            options={assetCardListSortOptions}
            className="asset-list-cards--select"
            value={{
              value: selectedSortOption.value,
              label: selectedOptionLabel,
            }}
          />
        </div>
        <div className="asset-list-cards--list-container">
          <List
            height={700}
            width="100%"
            itemData={screenWidth > 1026 ? itemData : {
              tupleizedAssetList: createSingleTupleizedList([...assetList].sort(createTableSorter(
                selectedSortOption.value,
                shouldSortDescending,
              ))),
            }}
            itemSize={() => 200}
            itemCount={screenWidth > 1026 ? tupleizedAssetList.length : assetList.length}
          >
            {AssetCardRow}
          </List>
        </div>
      </div>
    </ResizeObserver>
  );
};

export default AssetListCards;

import { repositoryFetch } from 'shared/helpers/repositoryHelpers';

export const fetchUnits = ({ accessToken, body }) => repositoryFetch({
  body,
  accessToken,
  endpoint: '/v1/axil/flux/views/asset_list',
});

export const getCompleteEOList = ({ accessToken }) => repositoryFetch({
  accessToken,
  endpoint: '/enterprise-objects/all?size=10000',
  method: 'GET',
  isAxil: true,
});

export const checkDigitalTwinExistence = (assetName, setDigitalTwinExistence) => {
  const ursaleoUrl = 'https://ursaleo.axil.ai';
  fetch(`${ursaleoUrl}/devices/${assetName}`)
    .then((res) => res.json())
    .then((data) => setDigitalTwinExistence(data))
    .catch(() => setDigitalTwinExistence(false));
};

import {
  IoFilterOutline,
  IoCloseOutline,
} from 'react-icons/io5';
import { useState } from 'react';
import { Input, AutoComplete } from 'antd';

import './FluxAutoComplete.scss';

const FluxAutoComplete = ({
  value,
  suffix,
  onChange,
  placeholder,
  prefix = <div />,
  ...otherProps
}) => {
  const defaultInputValue = '';
  const [inputValue, setInputValue] = useState(defaultInputValue);

  const handleTextInputChange = (textValue) => {
    onChange(textValue);
    setInputValue(textValue);
  };

  const handleClear = (event) => {
    event.stopPropagation();
    setInputValue(defaultInputValue);
    onChange(defaultInputValue);
  };

  const defaultSuffix = inputValue
    ? <IoCloseOutline onClick={handleClear} role="button" />
    : null;

  return (
    <div className="flux-auto-complete">
      <AutoComplete
        open
        {...otherProps}
        value={value ?? inputValue}
        onChange={handleTextInputChange}
        data-testid="flux-auto-complete"
      >
        <Input
          prefix={prefix}
          placeholder={placeholder}
          addonBefore={<IoFilterOutline />}
          addonAfter={suffix ?? defaultSuffix}
        />
      </AutoComplete>
    </div>
  );
};

export default FluxAutoComplete;

import React from 'react';
import GoogleMapReact from 'google-map-react';
import { IoLocation } from 'react-icons/io5';

import './AssetGoogleMap.scss';

const MapMarker = ({ lat, lng }) => (
  <IoLocation
    size={30}
    lat={lat}
    lng={lng}
    color="#F05921"
  />
);

const AssetGoogleMap = ({ lat, lng }) => {
  const center = [lat, lng];
  const defaultProps = {
    center: [lat, lng],
    zoom: 1,
  };

  return (
    <div className="asset-google-map-container">
      <GoogleMapReact
        zoom={10}
        center={center}
        defaultZoom={defaultProps.zoom}
        defaultCenter={defaultProps.center}
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
      >
        <MapMarker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};

export default AssetGoogleMap;

import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from 'shared/actions/notificationsActionTypes';

export const defaultState = {
  shownNotification: null,
};

const handleAddNewNotification = (state, { notification }) => ({
  ...state,
  shownNotification: notification,
});

const handleRemoveNotification = (state) => ({
  ...state,
  shownNotification: null,
});

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionTypes.NOTIFICATIONS__ADD_NEW_NOTIFICATION, handleAddNewNotification);
    builder.addCase(actionTypes.NOTIFICATIONS__REMOVE_NOTIFICATION, handleRemoveNotification);
  },
});

export const selectNotifications = (state) => state?.notifications;
export default notificationSlice.reducer;

import { Skeleton } from 'antd';

import './NotificationSkeleton.scss';

const NotificationSkeleton = () => (
  <div>
    <Skeleton
      active
      paragraph={{ rows: 2 }}
      title={{ width: '40%' }}
      className="notification-top-skeleton"
    />
    <Skeleton
      active
      title={{ width: '30%' }}
      className="notification-body-skeleton"
      paragraph={{ rows: 1, width: ['100%'] }}
    />
  </div>
);

export default NotificationSkeleton;

import React, { useState } from 'react';
import { Button } from '@flogistix/flo-ui';

import FluxTooltip from 'shared/components/FluxTooltip';
import { ICalculationFactorsData, IEmissionData, IGasAnalysisData } from 'shared/constants/emissionsCalculatorConstants';

import MethaneBreakdownCard from 'single-asset/components/emissions-calculator/emissions-results-tab/MethaneBreakdownCard';
import FlaredEmissionsBreakdown from 'single-asset/components/emissions-calculator/emissions-results-tab/FlaredEmissionsBreakdown';

import './TotalEmissionsBreakdownCard.scss';

const CardDetail = ({ label, value }: { label: string, value: number }) => (
  <div className="function-detail">
    <span className="value">{value?.toString()}</span>
    <span className="label">{label}</span>
  </div>
);

interface TotalEmissionsBreakdownCardProps {
  emissionsData: IEmissionData | null;
  gasAnalysis: IGasAnalysisData | null;
  calculationFactors: ICalculationFactorsData | null;
}

const TotalEmissionsBreakdownCard: React.FC<TotalEmissionsBreakdownCardProps> = ({
  gasAnalysis,
  emissionsData,
  calculationFactors,
}) => {
  const [showCalculations, setShowCalculations] = useState(false);

  const methaneEmissionsValue = Number(emissionsData?.methaneVented?.toFixed(2)) || 0;
  const driverEmissionsValue = Number(emissionsData?.driveEmissions?.toFixed(2)) || 0;
  const flaredEmissionsValue = Number(emissionsData?.flared?.co2e?.total?.toFixed(2)) || 0;
  const netEmissionsAbatedValue = Number(flaredEmissionsValue + methaneEmissionsValue - driverEmissionsValue).toFixed(2);

  return (
    <div className="total-emissions-breakdown-card">
      <div className="total-emissions-breakdown-card-title-container">
        <h1 className="total-emissions-breakdown-card-title">
          Total Emissions Breakdown
        </h1>
        <FluxTooltip
          tooltip={null}
          circleColor="#1B1A1A"
          title="Volume of vapors captured (MCF)"
          description="The total volume of all gas captured by vapor recovery."
        />
      </div>
      <div className="total-emissions-breakdown-card-function">
        <CardDetail label="Flared Emissions" value={flaredEmissionsValue} />
        <span className="function-detail">+</span>
        <CardDetail label="Methane Emissions" value={methaneEmissionsValue} />
        <span className="function-detail">-</span>
        <CardDetail label="Driver Emissions" value={driverEmissionsValue} />
        <span className="function-detail">=</span>
        <CardDetail label="Net Emissions Abated" value={Number(netEmissionsAbatedValue)} />
      </div>
      {showCalculations && (
        <>
          <FlaredEmissionsBreakdown
            gasAnalysis={gasAnalysis}
            emissionsData={emissionsData}
            calculationFactors={calculationFactors}
          />
          <MethaneBreakdownCard
            gasAnalysis={gasAnalysis}
            emissionsData={emissionsData}
            calculationFactors={calculationFactors}
          />
        </>
      )}
      <Button
        style={{
          margin: '16px 8px',
          alignSelf: 'flex-end',
        }}
        variation="black-outline"
        onClick={() => (showCalculations
          ? setShowCalculations(false)
          : setShowCalculations(true))}
      >
        {showCalculations ? 'Hide Calculations' : 'Show Calculations'}
      </Button>
    </div>
  );
};

export default TotalEmissionsBreakdownCard;

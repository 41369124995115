import dayjs from 'dayjs';
import { WEEKDAYS, TIME_OPTIONS, FREQUENCY_OPTIONS } from 'reporting/constants/reportingConstants';

const valueIsAll = (val) => val === '*';

export const getReportFrequency = (cronString, returnWhole) => {
  const cronSplit = cronString?.split(' ');
  const everyMonth = valueIsAll(cronSplit?.at(2));
  const everyDay = valueIsAll(cronSplit?.at(4));
  let frequency = 'Monthly';
  if (everyMonth && everyDay) frequency = 'Daily';
  if (everyMonth && !everyDay) frequency = 'Weekly';
  if (cronSplit?.at(2) === '1-7,14-21') frequency = 'Every 2 weeks';
  return returnWhole ? FREQUENCY_OPTIONS.find((f) => f.value === frequency) : frequency;
};

export const getWeekdayFromCron = (cronString) => {
  const weekday = cronString?.split(' ')?.at(4);
  return WEEKDAYS?.find((day) => day?.value === parseInt(weekday, 10));
};

export const getMonthlyDateFromCron = (cronString) => {
  const dayOfMonth = cronString?.split(' ')?.at(2);
  return dayjs()?.date(dayOfMonth);
};

export const getTimeFromCron = (cronString) => {
  const minute = cronString?.split(' ')?.at(0);
  const hour = cronString?.split(' ')?.at(1);
  const timezoneOffsetHours = new Date().getTimezoneOffset() / 60;
  const offsetHour = parseInt(hour, 10) - timezoneOffsetHours;
  const timezoneCorrectHour = offsetHour < 0 ? (24 + offsetHour) : offsetHour;
  return TIME_OPTIONS?.find((option) => option?.value === `${timezoneCorrectHour}:${minute}`);
};

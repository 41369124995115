import { asyncActionCreator } from 'shared/helpers/actionHelpers';
import { getUserSubscriptions, updateUserSubscriptions } from 'shared/repositories/accountSettingsRepository';

import * as actionTypes from 'shared/actions/subscriptionsActionTypes';

export const fetchUserSubscriptions = asyncActionCreator({
  repositoryAPI: getUserSubscriptions,
  PENDING_ACTION_TYPE: actionTypes.FETCH_SUBSCRIPTIONS,
  FAILED_ACTION_TYPE: actionTypes.FETCH_SUBSCRIPTIONS_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS,
});

export const editUserSubscriptions = asyncActionCreator({
  repositoryAPI: updateUserSubscriptions,
  PENDING_ACTION_TYPE: actionTypes.UPDATE_SUBSCRIPTIONS,
  FAILED_ACTION_TYPE: actionTypes.UPDATE_SUBSCRIPTIONS_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.UPDATE_SUBSCRIPTIONS_SUCCESS,
  responseTransformer: (result, body) => ({
    ...result,
    subscriptions: body.subscriptions,
  }),
});

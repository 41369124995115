export const chartRangeSelectOptions = [
  { label: 'Last 1 Hour', value: 'Last 1 Hour' },
  { label: 'Last 24 Hours', value: 'Last 24 Hours' },
  { label: 'Last 14 Days', value: 'Last 14 Days' },
  { label: 'Last 28 Days', value: 'Last 28 Days' },
  { label: 'Last 60 Days', value: 'Last 60 Days' },
  { label: 'Custom', value: 'Custom' },
];

export const DEFAULT_CHART_RANGE_LABEL = 'Last 24 Hours';
export const DEFAULT_OPTION_DESCRIPTOR_TEXT = 'DEFAULT';

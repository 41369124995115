import { Skeleton } from 'antd';

const AssetEventLoadingColumn = () => (
  <Skeleton.Input active />
);

export const ASSET_EVENTS_TABLE_LOADING_COLUMNS = [
  {
    title: 'Time',
    dataIndex: 'epoch_milliseconds',
    render: AssetEventLoadingColumn,
  },
  {
    title: 'Message',
    dataIndex: 'msg',
    render: AssetEventLoadingColumn,
  },
];

export default AssetEventLoadingColumn;

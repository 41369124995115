import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@flogistix/flo-ui';

import './LogoutButton.scss';

const Logout = () => {
  const { logout } = useAuth0();
  return (
    <Button
      variation="light-red"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Logout
    </Button>
  );
};

export default Logout;

import { parameterizeDate } from 'shared/helpers/emissionsHelpers';

/* eslint-disable consistent-return */
const ENV_URL = process.env.REACT_APP_BUILD_ENV === 'production'
  ? 'https://'
  : 'https://dev-';

const QUERIES_URL = `${ENV_URL}queries.api.axil.ai`;

const EMISSIONS_BASE_URL = '/v1/carbonCapture';
const EMISSIONS_INFO_URL = `${QUERIES_URL}${EMISSIONS_BASE_URL}/aggregation`;
const CALCULATION_FACTORS_URL = `${QUERIES_URL}${EMISSIONS_BASE_URL}/calculationFactors`;

export const getFleetEmissionDetails = async ({
  filters,
  endDate,
  startDate,
  accessToken,
}) => {
  const formattedFilters = !filters?.length ? '' : filters?.join('');
  const FORMATTED_URL = `${EMISSIONS_INFO_URL}?start_date=${startDate}&end_date=${endDate}${formattedFilters}`;

  try {
    const response = await fetch(FORMATTED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching additional emission details: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching additional emission details.');
  }
};

export const getUnitEmissionDetails = async ({
  unitId,
  endDate,
  startDate,
  accessToken,
}) => {
  const FORMATTED_URL = `${EMISSIONS_INFO_URL}?start_date=${startDate}&end_date=${endDate}&unit_id=${unitId}`;

  try {
    const response = await fetch(FORMATTED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching additional emission details: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching additional emission details.');
  }
};

export const getCalculationFactors = async ({ accessToken, unitId }) => {
  const today = new Date();
  const pastDate = new Date();
  pastDate.setDate(today.getDate() - 1);

  const formattedDate = parameterizeDate(pastDate);

  const FORMATTED_URL = `${CALCULATION_FACTORS_URL}?date=${formattedDate}&unit_id=${unitId}`;

  try {
    const response = await fetch(FORMATTED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching emissions calculations factors: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching emissions calculations factors.');
  }
};

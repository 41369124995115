import React from 'react';

import RecipientEntryComponent from './AssetEventRecipientComponent';

const RecipientInfoContainer = ({ recipients }) => (
  <div data-testid="asset-event-recipient-container">
    {recipients?.map((entry) => (
      <RecipientEntryComponent
        entry={entry}
        key={`${entry?.rule_uuid} ${entry?.email}`}
      />
    ))}
  </div>
);

export default RecipientInfoContainer;

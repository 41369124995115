import {
  IEmissionData,
  IFlaredDetails,
  IEmissionsDetails,
} from 'shared/constants/emissionsCalculatorConstants';

export const generateDisplayedEmissionTimeRange = (dateString: string) => {
  const date = new Date(dateString);

  const month = date.getMonth() + 1; // getMonth() returns month index (0-11)
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

const toCamelCase = (str: string) => str.split(/[-\s]/).map((word: string, index: number) => {
  if (index === 0) return word.toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}).join('');

const createDetails = (entry: any, emissionsData: IEmissionData | null, gasAnalysis: any) => {
  const camelName = toCamelCase(entry?.name);

  const percentKey = `${camelName}Pct` as keyof IEmissionData;
  const percent = gasAnalysis?.gasMolPercents?.[percentKey];

  const flaredKey = camelName as keyof IEmissionData;
  const flared = emissionsData?.flared?.co2e?.[flaredKey];

  const additionalFlareData: IFlaredDetails | undefined = emissionsData?.flared;

  const nameKey = `${entry?.propertyName}` as keyof IEmissionsDetails;
  const btu = additionalFlareData?.btu?.[nameKey];
  const ngl = additionalFlareData?.ngl?.[nameKey];

  return {
    btu,
    ngl,
    flared,
    key: entry?.name,
    label: entry?.name,
    title: entry?.name,
    percent: Number(percent) * 100,
  };
};

export const generateVaporCompositionTableData = (emissionsData: IEmissionData | null, gasAnalysis: any) => [
  { name: 'Carbon Dioxide', propertyName: 'carbonDioxide' },
  { name: 'Nitrogen', propertyName: 'nitrogen' },
  { name: 'Methane', propertyName: 'methane' },
  { name: 'Ethane', propertyName: 'ethane' },
  { name: 'Propane', propertyName: 'propane' },
  { name: 'Butane', propertyName: 'butane' },
  { name: 'I-Butane', propertyName: 'iButane' },
  { name: 'Pentane', propertyName: 'pentane' },
  { name: 'I-Pentane', propertyName: 'iPentane' },
  { name: 'Hexane', propertyName: 'hexane' },
  { name: 'TOTAL', propertyName: 'total' },
].map((entry) => {
  if (entry?.name === 'TOTAL') {
    return {
      percent: 1,
      key: 'TOTAL',
      label: 'TOTAL',
      title: 'TOTAL',
      flared: emissionsData?.flared?.total?.toFixed(5),
      btu: emissionsData?.flared?.btu?.total?.toFixed(5)?.toString(),
      ngl: emissionsData?.flared?.ngl?.total?.toFixed(5)?.toString(),
    };
  }
  return createDetails(entry, emissionsData, gasAnalysis);
});

export const parameterizeDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const standardizeDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}-${day}-${year}`;
};

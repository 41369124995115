import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { checkDecoderPermissions, hasCustomerDecoderPermissions } from 'single-asset/helpers/singleAssetHelpers';
import { IDropdownOption } from 'emissions/pages/EmissionsPage';
import { selectOrganizations } from 'shared/reducers/organizationsSlice';
import { Dropdown } from '@flogistix/flo-ui';
import Decoder from 'shared/components/decoder/Decoder';
import { isUserExternal } from 'shared/helpers/permissionHelpers';

import '../../shared/styles/DecoderPage.scss';
import './FleetDecoder.scss';
import { selectSession } from 'shared/reducers/sessionReducer';

interface Organization {
  orgId: number;
  orgName: string;
}

const FleetDecoder = () => {
  const { user } = useSelector(selectSession);
  const { groups: userGroups } = useSelector(selectPermissions);
  const { organizations } = useSelector(selectOrganizations);
  const { body: localOrganizations } = JSON.parse(localStorage.getItem('organizations') || '{}');
  const { body: localGroups } = JSON.parse(localStorage.getItem('groups') || '{}');
  const nonDuplicateOrganizations = ((navigator.onLine ? organizations : localOrganizations) ?? [])?.filter(
    (org: Organization, index: number, self: Organization[]) => self.findIndex((t) => (t.orgId === org.orgId)) === index,
  );
  const externalUser = isUserExternal(user?.email);
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [orgSearch, setOrgSearch] = useState<string>('');
  const userHasCustomerDecoder = hasCustomerDecoderPermissions((navigator.onLine ? userGroups : localGroups) ?? []);
  const organizationsOptions = nonDuplicateOrganizations.map((org: Organization) => ({ label: org.orgName, value: org.orgId }));

  useEffect(() => {
    const orgsToUse = navigator.onLine ? organizations : localOrganizations;
    if (!!externalUser && orgsToUse && selectedOrgId === null && orgsToUse.length === 1) {
      setSelectedOrgId(orgsToUse.at(0)?.orgId);
    }
  }, [organizations, localOrganizations]);

  return checkDecoderPermissions(navigator.onLine ? userGroups : localGroups) ? (
    <div className="decoder-page">
      <h1>Decoder</h1>
      <p className="decoder-page--subtitle">All units</p>
      {
        userHasCustomerDecoder && (
        <Dropdown
          options={organizationsOptions.filter((org: IDropdownOption) => org.label.toLowerCase().includes(orgSearch.toLowerCase()))}
          placeholder="Select an organization"
          size="small"
          onSelect={({ value }) => {
            setSelectedOrgId(value as number);
            setOrgSearch('');
          }}
          value={organizationsOptions.find((org: IDropdownOption) => org.value === selectedOrgId)?.label}
          onClear={() => setSelectedOrgId(null)}
          searchValue={orgSearch}
          onSearchChange={(val) => setOrgSearch(val)}
        />
        )
      }
      {
        ((selectedOrgId !== null) || !externalUser) && (
          <Decoder orgId={selectedOrgId} />
        )
      }
    </div>
  ) : null;
};

export default FleetDecoder;

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from '@flogistix/flo-ui';

import {
  assetListStatusOptionDropdownItems,
  defaultAssetListStatusDropdownOption,
} from 'shared/constants/assetConstants';

import { removeAreaParameter } from 'dashboard/helpers/dashboardHelpers';

import './AssetFilterDropdowns.scss';

const AssetFilterDropdowns = ({
  getUnits,
  filterAreas,
  dashboardAreaFilter,
  selectedStatusFilter,
  updateAreaFilter,
  setSelectedStatusFilter,
}) => {
  const navigate = useNavigate();

  const { search, pathname } = useLocation();
  const currentUrl = `${pathname}${search}`;

  const [areaFilterString, setAreaFilterString] = useState('');

  const allAreasOption = { label: 'All Areas', value: -1 };
  const areaOptions = [allAreasOption, ...filterAreas.map(({ label, value }) => ({
    label,
    value,
    key: value,
  }))];

  const resetAreaFilter = () => {
    updateAreaFilter(-1);
    getUnits({ filters: [] });

    const newUrl = removeAreaParameter(currentUrl);
    navigate(newUrl);
  };

  const handleAreaSelect = ({ value }) => {
    updateAreaFilter(value);
    getUnits({ filters: [value] });
  };

  const handleStatusSelect = ({ value }) => {
    setSelectedStatusFilter(value);
  };

  const handleAreaSearch = (searchValue) => {
    setAreaFilterString(searchValue);
  };

  return (
    <div className="asset-filter-dropdowns">
      <Dropdown
        placeholder="All Areas"
        size="small"
        onSelect={handleAreaSelect}
        options={areaOptions.filter((group) => group.label.toLowerCase().includes(areaFilterString.toLowerCase()))}
        onClear={dashboardAreaFilter !== -1 && resetAreaFilter}
        value={areaOptions.find((group) => Number(group.value) === Number(dashboardAreaFilter))?.label}
        searchValue={areaFilterString}
        onSearchChange={handleAreaSearch}
      />
      <Dropdown
        size="small"
        onSelect={handleStatusSelect}
        options={assetListStatusOptionDropdownItems}
        onClear={selectedStatusFilter !== defaultAssetListStatusDropdownOption.value && (() => handleStatusSelect(defaultAssetListStatusDropdownOption))}
        value={assetListStatusOptionDropdownItems.find((option) => option.value === selectedStatusFilter).label}
      />
    </div>
  );
};

export default AssetFilterDropdowns;

import { useEffect, useState } from 'react';

import { Dropdown, SearchBar } from '@flogistix/flo-ui';

import {
  DND_OPTIONS,
  DELIVERY_METHOD_TOGGLE_TEXTS,
  NOTIFICATIONS_ASSET_FILTER_PLACEHOLDER,
} from 'account-settings/constants/accountSettingsConstants';

import ToggleButton from 'shared/components/ToggleButton';

const NotificationSettings = ({
  dndSettings,
  preferences,
  activeSection,
  assetFilterText,
  clearDndSettings,
  setAssetFilterText,
  handleDndStopChange,
  handleDndStartChange,
  updateReceivingTexts,
  updateReceivingEmails,
}) => {
  const [localEnd, setLocalEnd] = useState(null);
  const [localStart, setLocalStart] = useState(null);
  const [timesCleared, setTimesCleared] = useState(false);

  const handleClear = () => {
    setTimesCleared(true);
    clearDndSettings();
  };

  const handleEnd = (option) => {
    setTimesCleared(false);
    handleDndStopChange(option);
  };

  const handleStart = (option) => {
    setTimesCleared(false);
    handleDndStartChange(option);
  };

  useEffect(() => {
    if (timesCleared) {
      setLocalEnd(null);
      setLocalStart(null);
    } else {
      setLocalEnd(DND_OPTIONS.find((option) => option.value === dndSettings?.dnd_stop)?.label
      || DND_OPTIONS.find((option) => option.value === preferences?.dnd_stop)?.label);

      setLocalStart(DND_OPTIONS.find((option) => option.value === dndSettings?.dnd_start)?.label
      || DND_OPTIONS.find((option) => option.value === preferences?.dnd_start)?.label);
    }
  }, [dndSettings, preferences, timesCleared]);

  return (
    <div className="filter-section">
      <div className="text-and-toggle">
        <div className="toggle-group">
          <ToggleButton
            disabled={false}
            showTooltip={false}
            onClick={updateReceivingEmails}
            checked={preferences?.send_email}
            title={DELIVERY_METHOD_TOGGLE_TEXTS.email}
          />
          <ToggleButton
            disabled={false}
            showTooltip={false}
            checked={preferences?.send_sms}
            onClick={updateReceivingTexts}
            title={DELIVERY_METHOD_TOGGLE_TEXTS.text}
          />
        </div>
        <SearchBar
          size="small"
          value={assetFilterText}
          placeholder={NOTIFICATIONS_ASSET_FILTER_PLACEHOLDER}
          onChange={(e) => setAssetFilterText(e?.target?.value)}
        />
      </div>
      {activeSection === 'My Notifications' && (
      <div className="dnd-section">
        <div className="dnd-label-container">
          <p>Do Not Disturb</p>
          {localEnd || localStart
            ? (
              <div
                className="clear-button"
                onClick={() => handleClear()}
              >
                Clear Times
              </div>
            )
            : <div />}
        </div>
        <div className="dnd-pickers">
          <Dropdown
            fixedWidth="250px"
            value={localStart}
            options={DND_OPTIONS}
            onSelect={handleStart}
            placeholder="Select start time"
          />
          <Dropdown
            value={localEnd}
            fixedWidth="250px"
            onSelect={handleEnd}
            options={DND_OPTIONS}
            placeholder="Select end time"
          />
        </div>
      </div>
      )}
    </div>
  );
};

export default NotificationSettings;

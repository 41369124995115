import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { extendedDayJs } from 'shared/helpers/timeHelpers';
import { selectSession } from 'shared/reducers/sessionReducer';
import { standardizeDate } from 'shared/helpers/emissionsHelpers';
import { fetchEventsByAltitude } from 'shared/repositories/axilEventsRepository';
import { fetchEventRecipients } from 'shared/repositories/eventRecipientsRepository';

import { createTableSorter } from 'dashboard/helpers/dashboardHelpers';

import { rowClassName } from 'single-asset/helpers/workOrderHelpers';
import { loadingKeyedData } from 'single-asset/constants/moreInfoConstants';
import { sharedRequestBody } from 'single-asset/helpers/singleAssetHelpers';
import AssetEventsTable from 'single-asset/components/events/AssetEventsTable';
import AssetEventsSearchForm from 'single-asset/components/events/AssetEventsSearchForm';
import AssetEventMessageColumn from 'single-asset/components/events/AssetEventsMessageColumn';
import AssetEventRecipientModal from 'single-asset/components/events/AssetEventRecipientModal';
import { ASSET_EVENTS_TABLE_LOADING_COLUMNS } from 'single-asset/components/events/AssetEventLoadingColumn';
import { formatAssetEvents, filterEventDateRange, filterByKeywords } from 'single-asset/helpers/eventHelpers';

import './EventsPage.scss';

const EventsPage = ({ asset }) => {
  const currentSession = useSelector(selectSession);

  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [selectedEventFilters, setSelectedEventFilters] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [defaultAssetEvents, setDefaultAssetEvents] = useState([]);
  const [currentEventForModal, setCurrentEventForModal] = useState(null);
  const [filteredAssetEvents, setFilteredAssetEvents] = useState(defaultAssetEvents);

  const [unitEventsLoading, setUnitEventsLoading] = useState(false);
  const [unitEventRecipients, setUnitEventRecipients] = useState([]);
  const [unitEventRecipientsLoading, setUnitEventRecipientsLoading] = useState(false);

  const assetEventTableColumns = [
    {
      title: 'Time',
      defaultSortOrder: 'descend',
      dataIndex: 'epoch_milliseconds',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('epoch_milliseconds'),
      },
      render: (value) => {
        const date = extendedDayJs(value);
        const formattedDate = date.format('hh:mm A - MMM DD, YYYY');
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: 'Message',
      dataIndex: 'msg',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('msg'),
      },
      render: (value, record) => (
        <AssetEventMessageColumn
          record={record}
          currentEventForModal={currentEventForModal}
        />
      ),
    },
  ];

  const getEventsRequestBody = {
    filters: [],
    key: asset?.asset_id,
    ...sharedRequestBody(asset),
    unit_number: asset?.asset_name,
  };

  const getRecipientsRequestBody = {
    rule_uuid: currentEventForModal?.rule_uuid,
  };

  const handleClearDates = () => {
    setEndDate(null);
    setStartDate(null);
    setFilteredAssetEvents(defaultAssetEvents);
  };

  const clearEventCloseModal = () => {
    setCurrentEventForModal({});
    setShowModal(false);
  };

  const handleRowClick = (record) => ({
    onClick: () => {
      setEventOpenModal(record);
    },
  });

  const setEventOpenModal = (record) => {
    setCurrentEventForModal(record);
    setShowModal(true);
  };

  const handleDates = (newStart, newEnd) => {
    const formattedEnd = standardizeDate(newEnd);
    const formattedStart = standardizeDate(newStart);

    const filteredEvents = filterEventDateRange(
      defaultAssetEvents,
      formattedStart,
      formattedEnd,
    );

    setFilteredAssetEvents(filteredEvents);
  };

  const getEvents = async (body) => {
    setUnitEventsLoading(true);

    try {
      const response = await fetchEventsByAltitude({
        body,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const unitEventResponseData = await response.json();
      const formattedEvents = formatAssetEvents(unitEventResponseData);

      setDefaultAssetEvents(formattedEvents);
      setFilteredAssetEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching unit events:', error);
    } finally {
      setUnitEventsLoading(false);
    }
  };

  const getEventRecipients = async (body) => {
    setUnitEventRecipientsLoading(true);

    try {
      const response = await fetchEventRecipients({
        body,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      setUnitEventRecipients(responseData);
    } catch (error) {
      console.error('Error fetching event recipients:', error);
    } finally {
      setUnitEventRecipientsLoading(false);
    }
  };

  const handleEventFilters = (filters) => {
    if (filters?.length) {
      const usableFilters = filters?.find((entry) => entry?.label === 'Alarms')?.label
        ? [...filters, { label: 'Alarming', value: 'alarming' }]
        : filters;

      const newFilteredEvents = filterByKeywords(filteredAssetEvents, usableFilters?.map((entry) => entry?.value));

      setSelectedEventFilters(filters);
      setFilteredAssetEvents(newFilteredEvents);
    } else {
      setSelectedEventFilters([]);
      setFilteredAssetEvents(defaultAssetEvents);
    }
  };

  useEffect(() => {
    currentSession?.token && asset && getEvents(getEventsRequestBody);
  }, [currentSession, asset]);

  useEffect(() => {
    currentEventForModal && asset && getEventRecipients(getRecipientsRequestBody);
  }, [currentEventForModal, asset]);

  return (
    <section className="asset-events-page-container">
      <div className="asset-events-page">
        <AssetEventRecipientModal
          isOpen={showModal}
          closeModal={clearEventCloseModal}
          recipients={unitEventRecipients}
          isPending={unitEventRecipientsLoading}
        />
        <AssetEventsSearchForm
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          handleDates={handleDates}
          setStartDate={setStartDate}
          disabled={unitEventsLoading}
          handleClearDates={handleClearDates}
          formattedAssetEvents={filteredAssetEvents}
          selectedEventFilters={selectedEventFilters}
          setSelectedEventFilters={handleEventFilters}
        />
        <AssetEventsTable
          rowClassName={rowClassName}
          handleClick={handleRowClick}
          data={unitEventsLoading
            ? loadingKeyedData
            : filteredAssetEvents}
          columns={unitEventsLoading
            ? ASSET_EVENTS_TABLE_LOADING_COLUMNS
            : assetEventTableColumns}
        />
      </div>
    </section>
  );
};

export default EventsPage;

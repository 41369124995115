const defaultApplicationTypeLayouts = {
  'Gas Lift': {
    id: 'defaultGasLift',
    charts: [{
      order: 0,
      title: 'Inlet Filter Differential Pressure',
      chartValues: [
        {
          label: 'Inlet Filter Differential Pressure',
          tag: 595,
          key: 'register_tag__spare_analog_in15',
          color: '#0A84FF',
        },
      ],
    },
    {
      order: 1,
      title: 'Critical Rate',
      chartValues: [
        {
          color: '#0A84FF',
          tag: 498,
          key: '_flogistix_critical_rate__gas_injection_calculation',
          label: 'Critical Rate Calculation',
        },
        {
          tag: 11,
          key: 'surface_casing_pressure',
          label: 'Surface Casing Pressure',
          color: '#00C278',
        },
      ],
    },
    {
      order: 2,
      title: 'Force Feed Lube System Pressure',
      chartValues: [
        {
          label: 'Force Feed Lube System Pressure',
          tag: 591,
          key: 'register_tag__force_feed_lube_system_pressure',
          color: '#0A84FF',
        },
      ],
    },
    {
      order: 3,
      title: 'Driver Vitals',
      chartValues: [
        {
          label: 'Engine Manifold Pressure',
          tag: 607,
          key: 'register_tag__engine_manifold_pressure',
          color: '#0A84FF',
        },
        {
          label: 'Engine Oil Pressure',
          tag: 613,
          key: 'register_tag__engine_oil_pressure',
          color: '#00C278',
        },
        {
          label: 'Engine Jacket Water Temperature',
          tag: 617,
          key: 'register_tag__engine_jacket_water_temperature',
          color: '#FFC700',
        },
      ],
    },
    {
      order: 4,
      title: 'Compressor Critical Temps',
      chartValues: [
        {
          label: '1st stage discharge temp',
          tag: 615,
          key: 'register_tag__1st_stage_discharge_temperature',
          color: '#0A84FF',
        },
        {
          label: '2nd stage discharge temp',
          tag: 606,
          key: 'register_tag__2nd_stage_discharge_temperature',
          color: '#00C278',
        },
        {
          label: '3rd stage discharge temp',
          tag: 614,
          key: 'register_tag__3rd_stage_discharge_temperature',
          color: '#FFC700',
        },
        {
          label: 'Final discharge temp',
          tag: 612,
          key: 'register_tag__final_discharge_temperature',
          color: '#FFC700',
        },
        {
          label: 'Compressor lube oil temp',
          tag: 616,
          key: 'register_tag__compressor_lube_oil_temperature',
          color: '#FFC700',
        },
      ],
    },
    {
      order: 5,
      title: 'Surface casing pressure vs injection rate',
      chartValues: [
        {
          label: 'Surface casing pressure',
          tag: 552,
          key: '_flogistix_critical_rate__register_casing_surface_pressure',
          color: '#0A84FF',
        },
        {
          label: 'gas injection rate',
          tag: 9,
          key: 'gas_injection_rate',
          color: '#00C278',
        },
      ],
    },
    {
      order: 6,
      title: 'Driver Speed + Gas Injection Rate',
      chartValues: [
        {
          label: 'Engine average speed',
          tag: 577,
          key: 'register_tag__engine_average_speed',
          color: '#0A84FF',
        },
        {
          label: 'gas injection rate',
          tag: 578,
          key: 'register_tag__gas_injection_rate',
          color: '#00C278',
        },
      ],
    },
    {
      order: 7,
      title: 'Oil, gas, & water - Yesterday',
      chartValues: [
        {
          label: 'Gas production - last day',
          tag: 657,
          key: 'register_tag__gas_production_-_last_day',
          color: '#0A84FF',
        },
        {
          label: 'Oil production - last day',
          tag: 649,
          key: 'register_tag__oil_production_-_last_day',
          color: '#00C278',
        },
        {
          label: 'Water production - last day',
          tag: 654,
          key: 'register_tag__water_production_-_last_day',
          color: '#FFC700',
        },
      ],
    },
    {
      order: 8,
      title: 'Oil, gas, & water - current day',
      chartValues: [
        {
          label: 'Gas production - current day',
          tag: 656,
          key: 'register_tag__gas_production_-_current_day',
          color: '#0A84FF',
        },
        {
          label: 'Oil production - current day',
          tag: 647,
          key: 'register_tag__oil_production_-_current_day',
          color: '#00C278',
        },
        {
          label: 'Water production - current day',
          tag: 652,
          key: 'register_tag__water_production_-_current_day',
          color: '#FFC700',
        },
      ],
    },
    {
      order: 9,
      title: 'Sales line pressure vs gas injection rate',
      chartValues: [
        {
          label: 'Gas injection rate',
          tag: 9,
          key: 'gas_injection_rate',
          color: '#0A84FF',
        },
        {
          label: 'Sales line pressure',
          tag: 658,
          key: 'register_tag__sales_line_pressure',
          color: '#00C278',
        },
      ],
    },
    {
      order: 10,
      title: 'Surface casing and discharge pressure',
      chartValues: [
        {
          label: 'Discharge pressure',
          tag: 7,
          key: 'discharge_pressure',
          color: '#0A84FF',
        },
        {
          label: 'Surface casing pressure',
          tag: 609,
          key: 'register_tag__surface_casing_pressure',
          color: '#00C278',
        },
      ],
    },
    {
      order: 11,
      title: 'Suction & Tubing Pressure',
      chartValues: [
        {
          label: 'Suction pressure',
          tag: 564,
          key: 'register_tag__suction_pressure',
          color: '#0A84FF',
        },
        {
          label: 'Tubing pressure',
          tag: 608,
          key: 'register_tag__tubing_pressure',
          color: '#00C278',
        },
      ],
    },
    {
      order: 12,
      title: 'Suction Pressure and Controls',
      chartValues: [
        {
          label: 'Suction control valve open %',
          tag: 579,
          key: 'register_tag__suction_control_valve_open_percent',
          color: '#0A84FF',
        },
        {
          label: 'Recycle control valve open %',
          tag: 580,
          key: 'register_tag__recycle_control_valve_open_percent',
          color: '#00C278',
        },
        {
          label: 'Suction pressure',
          tag: 16,
          key: 'suction_pressure',
          color: '#FFC700',
        },
      ],
    },
    {
      order: 13,
      title: 'Gas Injection Rate',
      chartValues: [
        {
          label: 'Gas injection rate',
          tag: 9,
          key: 'gas_injection_rate',
          color: '#0A84FF',
        },
        {
          label: 'Injection rate target set-point',
          tag: 587,
          key: 'register_tag__injection_rate_target_set-point',
          color: '#00C278',
        },
      ],
    }],
  },
};

export default defaultApplicationTypeLayouts;

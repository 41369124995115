import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from 'shared/actions/permissionsActionTypes';

export const defaultState = {
  isPending: false,
  fetchAttempts: 0,
  groups: [],
};

const handleGroupFetch = (state) => ({
  ...state,
  fetchAttempts: state.fetchAttempts + 1,
  isPending: true,
});

const handleGroupFetchSuccess = (state, { result: { groups } }) => ({
  ...state,
  isPending: false,
  groups: groups.map((group) => group.groupId),
});

const handleGroupFetchFailed = (state) => ({
  ...defaultState,
  fetchAttempts: state.fetchAttempts,
});

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionTypes.PERMISSIONS__FETCH_PERMISSION_GROUPS, handleGroupFetch);
    builder.addCase(actionTypes.PERMISSIONS__FETCH_PERMISSION_GROUPS_SUCCESS, handleGroupFetchSuccess);
    builder.addCase(actionTypes.PERMISSIONS__FETCH_PERMISSION_GROUPS_FAILED, handleGroupFetchFailed);
  },
});

export const selectPermissions = (state) => state?.permissions;
export default permissionsSlice.reducer;

import React from 'react';

const RecipientEntryComponent = ({ entry }) => (
  <div
    key={entry?.rule_uuid}
    className="asset-event-recipient-container"
  >
    <div className="recipient-info-entry">
      <p className="recipient-info-title">Name</p>
      <p className="recipient-info-value">{entry?.first} {entry?.last}</p>
    </div>
    <div className="recipient-info-entry">
      <p className="recipient-info-title">Email</p>
      <p className="recipient-info-value">{entry?.email}</p>
    </div>
    <div className="recipient-info-entry">
      <p className="recipient-info-title">Notification Type</p>
      <p className="recipient-info-value">{entry?.type?.toUpperCase()}</p>
    </div>
  </div>
);

export default RecipientEntryComponent;

import { useState } from 'react';

import ChartStatsRow from 'single-asset/components/charts/chart-details/ChartStatsRow';
import ChartStatDetailedRow from 'single-asset/components/charts/chart-details/ChartStatDetailedRow';

import './ChartDetails.scss';

const ChartDetails = ({
  chartData,
  chartStats,
  aggregateData,
  displayGridView,
  unitChartConfig,
  chartLayout,
}) => {
  const [currentStatDetail, setCurrentStatDetail] = useState({});
  return (
    <div className="chart-details">
      <div className="chart-stats-row">
        {currentStatDetail?.label ? (
          <ChartStatDetailedRow
            chartStats={chartStats}
            aggregateData={aggregateData}
            currentStatDetail={currentStatDetail}
            setCurrentStatDetail={setCurrentStatDetail}
          />
        ) : (
          <ChartStatsRow
            chartStats={chartStats}
            chartData={chartData}
            aggregateData={aggregateData}
            displayGridView={displayGridView}
            unitChartConfig={unitChartConfig}
            chartLayout={chartLayout.chartValues}
            setCurrentStatDetail={setCurrentStatDetail}
          />
        )}
      </div>
    </div>
  );
};

export default ChartDetails;

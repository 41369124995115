import { extendedDayJs } from 'shared/helpers/timeHelpers';

const ChartsXAxisTick = ({ x, y, payload }) => (
  <g className="x-axis-tick" transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} fill="#BCBCBC">
      <tspan textAnchor="middle" x="0">{extendedDayJs(payload.value).format('MMM DD, YYYY')}</tspan>
      <tspan textAnchor="middle" x="0" dy="20">{extendedDayJs(payload.value).format('h:mm a')}</tspan>
    </text>
  </g>
);

export default ChartsXAxisTick;

import React from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';

import './MathCard.scss';

interface MathCardProps {
  bordered?: boolean;
  value?: string | number;
  showDownArrow?: boolean;
  handleClick?: () => void;
  label?: string | React.ReactNode;
}

const MathCard: React.FC<MathCardProps> = ({
  value,
  label,
  handleClick,
  bordered = false,
  showDownArrow = false,
}) => (
  <div
    onClick={handleClick}
    className={`${showDownArrow ? 'math-card-clickable'
      : 'math-card'}${bordered ? '-bordered' : ''}`}
  >
    <div className="math-card-content">
      <p className="math-value">{value}</p>
      <p className="math-label">{label}</p>
    </div>
    {showDownArrow && <IoChevronDownOutline size="18px" color="#BCBCBC" />}
  </div>
);

MathCard.defaultProps = {
  value: '',
  label: '',
  bordered: false,
  showDownArrow: false,
  handleClick: () => {},
};

export default MathCard;

import _ from 'lodash';

import * as singleAssetActionTypes from 'single-asset/actions/singleAssetActionTypes';

export const defaultState = {};

const assetReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case singleAssetActionTypes.SINGLE_ASSET__SET_ASSET: {
      return _.cloneDeep(action.payload);
    }

    default: {
      return state;
    }
  }
};

export const selectAsset = ({ singleAsset: { asset } }) => asset;
export default assetReducer;

import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@flogistix/flo-ui';

import './ReportingHeader.scss';

const ReportingHeader = ({
  reportBeingEdited,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="heading-content">
      {!reportBeingEdited ? (
        <>
          <h1>Flux Reporting</h1>
          <Tabs
            defaultActiveKey={pathname.includes('scheduled') ? 'scheduled' : 'create-new-report'}
            headerSize="h3"
            color="black"
            className="notification-tabs"
          >
            <Tab
              eventKey="create-new-report"
              title="Create new report"
              onClick={() => navigate('')}
            />
            <Tab
              eventKey="scheduled"
              title="Scheduled reports"
              onClick={() => navigate('scheduled')}
            />
          </Tabs>
        </>
      ) : (
        <>
          <h1>Editing Report</h1>
          <h2>{reportBeingEdited?.editName ?? reportBeingEdited.userReportName}</h2>
        </>
      )}
    </div>
  );
};

export default ReportingHeader;

import { Skeleton } from 'antd';

import './PageHeadingSkeleton.scss';

const PageHeadingSkeleton = () => (
  <Skeleton
    active
    title={{ width: '10%' }}
    className="page-heading-skeleton"
    paragraph={{ rows: 1, width: ['15%'] }}
  />
);

export default PageHeadingSkeleton;

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectSession } from 'shared/reducers/sessionReducer';
import { addNotification } from 'shared/actions/notificationsActions';
import { deleteScheduledReport, getScheduledReports } from 'shared/repositories/reportingRepository';

import { DELETE_MODAL_TITLE } from 'reporting/constants/reportingConstants';
import ScheduledReportsTable from 'reporting/components/scheduled-reports/ScheduledReportsTable';
import DeleteConfirmationModal from 'reporting/components/scheduled-reports/DeleteConfirmationModal';
import ScheduledReportTableSkeleton from 'reporting/components/scheduled-reports/ScheduledReportTableSkeleton';

import './ScheduledReportSection.scss';

const ScheduledReportSection = ({ setReportBeingEdited, organizations }) => {
  const dispatch = useDispatch();

  const session = useSelector(selectSession);

  const [reportToDelete, setReportToDelete] = useState(null);
  const [scheduledReports, setScheduledReports] = useState([]);
  const [scheduledReportsLoading, setScheduledReportsLoading] = useState(true);

  const fetchScheduledReports = async () => {
    setScheduledReports([]);
    setScheduledReportsLoading(true);

    try {
      const response = await getScheduledReports({ accessToken: session.token });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const reportsData = await response.json();
      setScheduledReports(reportsData?.reports);
    } catch (error) {
      console.error('Error fetching scheduled reports:', error);
    } finally {
      setScheduledReportsLoading(false);
    }
  };

  const deleteReport = async () => {
    setScheduledReportsLoading(true);

    try {
      const response = await deleteScheduledReport({
        accessToken: session.token,
        userReportName: reportToDelete.userReportName,
      });

      if (!response.ok) {
        throw new Error(`Delete failed with status: ${response.status}`);
      }

      fetchScheduledReports();
    } catch (error) {
      console.error('Error fetching scheduled reports:', error);
    } finally {
      setScheduledReportsLoading(false);
    }
  };

  const handleDelete = () => {
    deleteReport();
    dispatch(addNotification({
      type: 'success',
      message: `Report '${reportToDelete?.userReportName}' was successfully deleted`,
    }));
    setReportToDelete(null);
  };

  useEffect(() => {
    fetchScheduledReports();
  }, []);

  const DeleteModalContent = () => (
    <div className="delete-report-content">
      <p>Report: <span>{reportToDelete?.editName ?? reportToDelete.userReportName}</span></p>
      <p>Customer: <span>{organizations.find((org) => org?.orgId === reportToDelete?.options?.orgId)?.orgName ?? 'Name unavailable'}</span></p>
      <p>Report Type: <span>{reportToDelete.reportName}</span></p>
    </div>
  );

  return (
    <div className="scheduled-reports">
      {!scheduledReportsLoading
        ? (
          <ScheduledReportsTable
            reports={scheduledReports}
            organizations={organizations}
            setReportToDelete={setReportToDelete}
            setReportBeingEdited={setReportBeingEdited}
          />
        )
        : <ScheduledReportTableSkeleton />}
      <DeleteConfirmationModal
        isOpen={!!reportToDelete}
        modalTitle={DELETE_MODAL_TITLE}
        onSubmit={handleDelete}
        closeModal={() => setReportToDelete(null)}
        modalContent={reportToDelete && <DeleteModalContent />}
      />
    </div>
  );
};

export default ScheduledReportSection;

import React from 'react';
import { v4 as uuid } from 'uuid';

import { IEmissionsEquation } from 'shared/constants/emissionsCalculatorConstants';

import EmissionsPill from 'single-asset/components/emissions-calculator/equations-breakdown-tab/EmissionsPill';
import EmissionsEquation from 'single-asset/components/emissions-calculator/equations-breakdown-tab/EmissionsEquation';

import './EquationBreakdownCard.scss';

interface EquationBreakdownCardProps {
  equations: IEmissionsEquation[];
  label: React.ReactElement;
}

const EquationBreakdownCard: React.FC<EquationBreakdownCardProps> = ({
  label,
  equations,
}) => (
  <section className="equation-breakdown-card">
    <div className="equation-row">
      <p className="equation-row-label">
        {label}
      </p>
      <section className="equation-breakdown-container">
        {label?.props.children === 'Flared Emissions'
          && (
            <div className="equation-summation">
              <EmissionsPill
                label="Σ"
                style={{ marginRight: '5px' }}
              />
            </div>
          )}
        <div className="equation-components">
          {equations?.map(((entry: IEmissionsEquation) => (
            <EmissionsEquation
              key={uuid()}
              entry={entry}
            />
          )))}
        </div>
      </section>
    </div>
  </section>
);

export default EquationBreakdownCard;

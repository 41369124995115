export const REPORTING_GROUP = 'reporting';
export const FLOGISTIX_DECODER = 'decoder-flogistix-programmer';
export const FLOGISTIX_FIELD_SERVICE_TECH = 'field-service-tech';
export const CUSTOMER_DECODER = 'decoder-customer-programmer';
export const CUSTOMER_ADVANCED_TECH = 'decoder-customer-advanced-tech';
export const FLOGISTIX_ADVANCED_TECH = 'decoder-flogistix-advanced-tech';

export const CUSTOMER_DECODER_GROUPS = [CUSTOMER_ADVANCED_TECH, CUSTOMER_DECODER];
export const FLOGISTIX_DECODER_GROUPS = [FLOGISTIX_ADVANCED_TECH, FLOGISTIX_DECODER];
export const ALL_DECODER_GROUPS = [
  ...CUSTOMER_DECODER_GROUPS,
  ...FLOGISTIX_DECODER_GROUPS,
];

export const decoderGroupTitleMap = {
  [CUSTOMER_DECODER]: 'Customer Programmer',
  [CUSTOMER_ADVANCED_TECH]: 'Customer Advanced Tech',
  [FLOGISTIX_DECODER]: 'Flogistix Programmer',
  [FLOGISTIX_ADVANCED_TECH]: 'Flogistix Advanced Tech',
};

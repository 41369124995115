import React from 'react';
import { Modal } from 'antd';

import { Button, Header, Icon } from '@flogistix/flo-ui';

import './GasAnalysisModal.scss';

interface GasAnalysisModalProps {
  isOpen: boolean,
  message: string,
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  redirectClick: (e: React.MouseEvent) => void;
}

const GasAnalysisModal: React.FC<GasAnalysisModalProps> = ({
  isOpen,
  message,
  closeModal,
  redirectClick,
}) => {
  const errorModal = message?.includes('error');

  return (
    <Modal
      footer={[]}
      open={isOpen}
      onCancel={() => closeModal()}
      className="gas-analysis-modal"
      wrapClassName="gas-analysis-modal-dialog"
    >
      {errorModal
        ? <Icon height={24} width={24} iconName="X" color="#F01628" />
        : <Icon height={24} width={24} iconName="Check" color="#0A84FF" />}
      <Header headerSize="h3">
        {errorModal
          ? 'Gas Analysis Error'
          : 'Gas Analysis  Added' }
      </Header>
      <p className="gas-analysis-modal-description">
        {message}
      </p>
      {errorModal
        ? (
          <div className="modal-buttons">
            <Button
              size="medium"
              variation="ghost"
              onClick={closeModal}
              style={{ margin: '0 auto 8px auto' }}
            >
              Close
            </Button>
          </div>
        )
        : (
          <div className="modal-buttons">
            <Button
              size="medium"
              variation="ghost"
              onClick={closeModal}
              style={{ marginBottom: '8px' }}
            >
              Close
            </Button>
            <Button
              size="medium"
              onClick={redirectClick}
              style={{ marginBottom: '8px' }}
            >
              Visit Unit
            </Button>
          </div>
        )}
    </Modal>
  );
};

export default GasAnalysisModal;

import React from 'react';

import {
  netEquation,
  cO2DensityEquation,
  cH4DensityEquation,
  gedEmissionsEquation,
  flaredEmissionsEquation,
  methaneEmissionsEquation,
  electricDriveEmissionsEquation,
} from 'single-asset/constants/emissionsConstants';
import EquationBreakdownCard from 'single-asset/components/emissions-calculator/equations-breakdown-tab/EquationBreakdownCard';

import './EquationTable.scss';

const EquationTable: React.FC = () => (
  <section className="equation-table">
    <div className="equation-table-section">
      <EquationBreakdownCard
        equations={flaredEmissionsEquation}
        label={<span>Flared Emissions</span>}
      />
      <EquationBreakdownCard
        equations={methaneEmissionsEquation}
        label={<span>Methane Emissions</span>}
      />
      <EquationBreakdownCard
        equations={methaneEmissionsEquation}
        label={<span>Venting - Tons of CO2 (considering Methane as GHG)</span>}
      />
      <EquationBreakdownCard
        equations={gedEmissionsEquation}
        label={<span>GED Drive Emissions</span>}
      />
    </div>
    <div className="equation-table-section">
      <EquationBreakdownCard
        equations={cO2DensityEquation}
        label={<span>CO<sub>2</sub> Density</span>}
      />
      <EquationBreakdownCard
        equations={cH4DensityEquation}
        label={<span>CH<sub>4</sub> Density</span>}
      />
      <EquationBreakdownCard
        equations={netEquation}
        label={<span>Net Emissions Abated</span>}
      />
      <EquationBreakdownCard
        label={<span>ELEC Drive Emissions</span>}
        equations={electricDriveEmissionsEquation}
      />
    </div>
  </section>
);

export default EquationTable;

import { Skeleton } from 'antd';

const AssetListSkeleton = () => (
  <Skeleton
    className="asset-list-skeleton"
    active
    title={{
      width: '100%',
    }}
    paragraph={{
      rows: 5,
      width: '100%',
    }}
  />
);

export default AssetListSkeleton;

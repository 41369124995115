import React from 'react';
import { Skeleton } from 'antd';

const ChartSkeleton = () => (
  <div>
    <Skeleton
      active
      paragraph={{ rows: 2 }}
      title={{ width: '30%' }}
      className="chart-form-skeleton"
    />
    <Skeleton
      active
      title={{ width: '100%' }}
      className="chart-body-skeleton"
      paragraph={{ rows: 1, width: ['100%'] }}
    />
  </div>

);

export default ChartSkeleton;

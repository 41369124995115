import React from 'react';

import './EmissionsCalculatorTab.scss';

interface EmissionsCalculatorTabProps {
  label: string;
  active: boolean;
  disabled?: boolean;
  style: React.CSSProperties;
  handleClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const EmissionsCalculatorTab: React.FC<EmissionsCalculatorTabProps> = ({
  label,
  style,
  active,
  handleClick,
  disabled = false,
}) => (
  <div
    onClick={disabled ? () => {} : handleClick}
    className={`emissions-calculator-tab${disabled ? '-disabled' : ''}`}
  >
    <p
      style={style}
      className={active
        ? 'emissions-calculator-tab-label-active'
        : 'emissions-calculator-tab-label'}
    >
      {label}
    </p>
  </div>
);

EmissionsCalculatorTab.defaultProps = {
  disabled: false,
};

export default EmissionsCalculatorTab;

import { useState } from 'react';

import './PillToggleButton.scss';

const PillToggleButton = ({
  variant,
  onToggle,
  disabled,
  isChecked = false,
  toggleNameList: [name1, name2],
}) => {
  const [checked, setChecked] = useState(isChecked);

  const handleToggle = () => {
    setChecked(!checked);
    onToggle();
  };

  return (
    <div className={`${variant ? `${variant}-pill-toggle-button` : 'pill-toggle-button'}`}>
      <input id="e" type="checkbox" disabled={disabled} checked={checked} readOnly />
      <label htmlFor="e">
        <div
          className="pill-toggle-button__switch"
          data-toggle-1={name1}
          data-toggle-2={name2}
          onClick={handleToggle}
        />
      </label>
    </div>
  );
};

export default PillToggleButton;

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { selectSession } from 'shared/reducers/sessionReducer';

import { sharedRequestBody } from 'single-asset/helpers/singleAssetHelpers';
import { tagsForRequestBody } from 'single-asset/constants/moreInfoConstants';
import { formUnitProfileEntries } from 'single-asset/helpers/moreInfoHelpers';
import { getUnitProfileInfo } from 'shared/repositories/moreInfoStatsRepository';
import { getUnitProfileImage } from 'shared/repositories/moreInfoImageRepository';
import UnitProfileCardsContainer from 'single-asset/components/more-info/UnitProfileCardsContainer';
import LocationAndImageContainer from 'single-asset/components/more-info/LocationAndImageContainer';

import './MoreInformationPage.scss';

const MoreInformationPage = ({ asset }) => {
  const currentSession = useSelector(selectSession);

  const [assetImage, setAssetImage] = useState('');
  const [assetLatitude, setAssetLatitude] = useState(null);
  const [assetLongitude, setAssetLongitude] = useState(null);

  const [moreInfoStats, setMoreInfoStats] = useState(null);
  const [formattedEntries, setFormattedEntries] = useState([]);
  const [moreInfoStatsLoading, setMoreInfoStatsLoading] = useState(false);
  const [moreInfoLocationLoading, setMoreInfoLocationLoading] = useState(false);

  const getUnitProfileInfoRequestBody = {
    tags: tagsForRequestBody,
    ...sharedRequestBody(asset),
  };

  const getMoreInfoStats = async () => {
    try {
      setMoreInfoStatsLoading(true);

      const response = await getUnitProfileInfo({
        accessToken: currentSession?.token,
        body: getUnitProfileInfoRequestBody,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      setMoreInfoStats(responseData?.[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setMoreInfoStatsLoading(false);
    }
  };

  const getMoreInfoImage = async () => {
    try {
      setMoreInfoLocationLoading(true);

      const response = await getUnitProfileImage({
        body: asset?.asset_name,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      setAssetImage(responseData?.url);
    } catch (error) {
      console.log(error);
    } finally {
      setMoreInfoLocationLoading(false);
    }
  };

  useEffect(() => {
    if (currentSession?.token && asset) {
      getMoreInfoStats();
      getMoreInfoImage();
    }
  }, [asset, currentSession]);

  useEffect(() => {
    if (moreInfoStats) {
      const arrayedData = Object.entries(moreInfoStats);
      const formatted = formUnitProfileEntries(arrayedData);

      setFormattedEntries(formatted);
      setAssetLatitude(moreInfoStats?.lat);
      setAssetLongitude(moreInfoStats?.lon);
    }
  }, [moreInfoStats]);

  return (
    <section className="more-information-page-container">
      <div className="more-information-page">
        <h1>Unit Profile</h1>
        <UnitProfileCardsContainer
          formattedEntries={formattedEntries}
          unitProfileLoading={moreInfoStatsLoading}
        />
        {!moreInfoLocationLoading
          && (
            <LocationAndImageContainer
              image={assetImage}
              lat={assetLatitude}
              lng={assetLongitude}
            />
          )}
      </div>
    </section>
  );
};

export default MoreInformationPage;

import './DashboardNavigation.scss';

const DashboardNavigation = () => (
  <div className="dashboard-nav">
    <div className="navigation-buttons">
      <p className="context-title">All Assets</p>
    </div>
  </div>
);

export default DashboardNavigation;

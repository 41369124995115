import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';

dayjs
  .extend(utc)
  .extend(isBetween)
  .extend(relativeTime);

export { dayjs as extendedDayJs };

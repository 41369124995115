import { IoAddOutline, IoClose, IoRemoveOutline } from 'react-icons/io5';

export const vaporCompositionTableColumns = [
  {
    key: 'Component',
    title: <p className="table-header-cell-text">Component</p>,
  },
  {
    key: 'Mol%',
    title: <p className="table-header-cell-text">Mol %</p>,
  },
  {
    key: 'Flare Emissions',
    title: (
      <p className="table-header-cell-text">
        CO<sub>2</sub> Emissions
      </p>
    ),
  },
  {
    key: 'MMBTUs',
    title: (
      <p className="table-header-cell-text">
        MM BTUs
      </p>
    ),
  },
  {
    key: 'NGLs Recovered',
    title: (
      <p className="table-header-cell-text">
        NGLs Recovered
      </p>
    ),
  },
];

const flaredEmissionsPart1 = {
  description: <span>Volume of all gas</span>,
  mathFunction: <IoClose />,
};
const flaredEmissionsPart2 = {
  description: <span>CO<sub>2</sub> Density</span>,
  mathFunction: <IoClose />,
};
const flaredEmissionsPart3 = {
  description: <span>Flare Efficiency</span>,
  mathFunction: <IoAddOutline style={{ marginRight: '100px' }} />,
};
const flaredEmissionsPart4 = {
  description: <span>Component gas mol %</span>,
  mathFunction: <IoClose />,
};
const flaredEmissionsPart5 = {
  description: <span># of carbon molecules contained in component gas</span>,
};

export const flaredEmissionsEquation = [
  flaredEmissionsPart1,
  flaredEmissionsPart2,
  flaredEmissionsPart3,
  flaredEmissionsPart4,
  flaredEmissionsPart5,
];

const methaneEmissionsPart1 = {
  description: <span>Volume of all gas</span>,
  mathFunction: <IoClose />,
};
const methaneEmissionsPart2 = {
  description: <span>CH<sub>4</sub> Density</span>,
  mathFunction: <IoClose />,
};
const methaneEmissionsPart3 = {
  description: <span>GWP</span>,
  mathFunction: <IoClose />,
};
const methaneEmissionsPart4 = {
  description: <span>Methane mol %</span>,
  mathFunction: <IoClose />,
};
const methaneEmissionsPart5 = {
  description: <span>1 - Flare Efficiency</span>,
};

export const methaneEmissionsEquation = [
  methaneEmissionsPart1,
  methaneEmissionsPart2,
  methaneEmissionsPart3,
  methaneEmissionsPart4,
  methaneEmissionsPart5,
];

const gedEmissionsPart1 = {
  description: <span>Fuel Burn MCFD</span>,
  mathFunction: <IoClose />,
};
const gedEmissionsPart2 = {
  description: <span>Emissions Factor</span>,
  mathFunction: <IoClose />,
};
const gedEmissionsPart3 = {
  description: <span>Energy Content of Fuel Gas</span>,
  mathFunction: <h4>/</h4>,
};
const gedEmissionsPart4 = {
  description: <span>1000</span>,
};

export const gedEmissionsEquation = [
  gedEmissionsPart1,
  gedEmissionsPart2,
  gedEmissionsPart3,
  gedEmissionsPart4,
];

export const netEquation = [
  {
    description: <span>Flared Emissions</span>,
    mathFunction: <IoAddOutline />,
  },
  {
    description: <span>Methane Emissions</span>,
    mathFunction: <IoRemoveOutline />,
  },
  {
    description: <span>Driver Emissions</span>,
  },
];

const electricDriveEmissionsPart1 = {
  description: <span>Virtual Flow Demand HP (average per day)</span>,
  mathFunction: <IoClose />,
};
const electricDriveEmissionsPart2 = {
  description: <span>Kw per HP</span>,
  mathFunction: <IoClose />,
};
const electricDriveEmissionsPart3 = {
  description: <span>24</span>,
  mathFunction: <IoClose style={{ marginRight: '100px' }} />,
};
const electricDriveEmissionsPart4 = {
  description: <span>Emissions Factor By State</span>,
  mathFunction: <IoClose />,
};
const electricDriveEmissionsPart5 = {
  description: <span>Conversion Factor</span>,
};

export const electricDriveEmissionsEquation = [
  electricDriveEmissionsPart1,
  electricDriveEmissionsPart2,
  electricDriveEmissionsPart3,
  electricDriveEmissionsPart4,
  electricDriveEmissionsPart5,
];

export const cO2DensityEquation = [
  { description: <span>0.05262 Metric Tons / MSCF at 14.73 psia, 60° F</span> },
];

export const cH4DensityEquation = [
  { description: <span>0.01913 Metric Tons / MSCF at 14.73 psia, 60° F</span> },
];

export const gasSelectOptions = [
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Carbon Dioxide</span>
      </div>
    ),
    value: 'Carbon Dioxide',
    dropdownLabel: 'Carbon Dioxide',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Nitrogen</span>
      </div>
    ),
    value: 'Nitrogen',
    dropdownLabel: 'Nitrogen',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Methane</span>
      </div>
    ),
    value: 'Methane',
    dropdownLabel: 'Methane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Ethane</span>
      </div>
    ),
    value: 'Ethane',
    dropdownLabel: 'Ethane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Butane</span>
      </div>
    ),
    value: 'Butane',
    dropdownLabel: 'Butane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Propane</span>
      </div>
    ),
    value: 'Propane',
    dropdownLabel: 'Propane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">I-Butane</span>
      </div>
    ),
    value: 'I-Butane',
    dropdownLabel: 'I-Butane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Pentane</span>
      </div>
    ),
    value: 'Pentane',
    dropdownLabel: 'Pentane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">I-Pentane</span>
      </div>
    ),
    value: 'I-Pentane',
    dropdownLabel: 'I-Pentane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Hexane</span>
      </div>
    ),
    value: 'Hexane',
    dropdownLabel: 'Hexane',
  },
];

import { Modal } from 'antd';

import { Button } from '@flogistix/flo-ui';

import './DeleteConfirmationModal.scss';

const DeleteConfirmationModal = ({
  isOpen,
  onSubmit,
  closeModal,
  modalTitle = 'Title',
  modalContent = <p>content</p>,
  cancelButtonText = 'No, Keep It',
  deleteButtonText = 'Yes, Delete It',
}) => (
  <Modal
    centered
    footer={[]}
    open={isOpen}
    title={modalTitle}
    onCancel={closeModal}
    className="delete-modal"
    wrapClassName="delete-modal-dialog"
  >
    {modalContent}
    <div className="button-group">
      <Button
        variation="gray"
        onClick={closeModal}
        style={{ marginRight: '8px' }}
      >
        {cancelButtonText}
      </Button>
      <Button variation="blue" onClick={onSubmit}>
        {deleteButtonText}
      </Button>
    </div>
  </Modal>
);

export default DeleteConfirmationModal;

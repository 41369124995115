import { Skeleton } from 'antd';

import './NewReportSkeleton.scss';

const NewReportSkeleton = () => (
  <Skeleton
    active
    className="new-report-skeleton"
    title={{ width: '25%' }}
    paragraph={{
      rows: 2,
      width: ['65%', '65%'],
    }}
  />
);

export default NewReportSkeleton;

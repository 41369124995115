import React from 'react';
import { IoClose } from 'react-icons/io5';

import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';

import './ChartStatDetailedRow.scss';

const ChartStatDetailedRow = ({
  chartStats,
  aggregateData,
  currentStatDetail,
  setCurrentStatDetail,
}) => {
  const currentStat = chartStats?.find((stat) => stat.dataKey === currentStatDetail?.label);

  const statSeriesData = Object.entries(aggregateData);
  const currentStatSeriesDataArray = statSeriesData?.filter((entry) => entry?.[0] === currentStatDetail?.label);
  const currentStatSeriesValues = currentStatSeriesDataArray[0];
  const currentStatSeriesMin = currentStatSeriesValues?.[1]?.min;
  const currentStatSeriesMax = currentStatSeriesValues?.[1]?.max;
  const currentStatSeriesMean = currentStatSeriesValues?.[1]?.mean;

  const closeRow = () => {
    setCurrentStatDetail({});
  };

  const calculateSummaryValue = (chartValue) => {
    if (typeof chartValue === 'object' && chartValue !== null) {
      return Number(chartValue.y).toFixed(2) || MISSING_FIELD_VALUE_PLACEHOLDER;
    }
    return Number(chartValue).toFixed(2) || MISSING_FIELD_VALUE_PLACEHOLDER;
  };

  return (
    <section className="chart-stat-row">
      <div className="main-stat">
        <h6>
          {currentStatDetail?.label}
          <div
            className="color"
            style={{ backgroundColor: currentStatDetail?.color || '#b3b3b3' }}
          />
        </h6>
        <h4>{calculateSummaryValue(currentStat?.value)}</h4>
      </div>
      <div className="detailed-stats">
        <div className="detailed-stat">
          <span>{calculateSummaryValue(currentStatSeriesMin)}</span>
          <p className="detailed-stat-label">min</p>
        </div>
        <div className="detailed-stat">
          <span>{calculateSummaryValue(currentStatSeriesMax)}</span>
          <p className="detailed-stat-label">max</p>
        </div>
        <div className="detailed-stat">
          <span>{calculateSummaryValue(currentStatSeriesMean)}</span>
          <p className="detailed-stat-label">avg</p>
        </div>
      </div>
      <IoClose
        size={20}
        color="#424242"
        onClick={closeRow}
        className="row-close-button"
      />
    </section>
  );
};
export default ChartStatDetailedRow;

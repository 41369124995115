import React, { useState, useEffect } from 'react';
import { IoGridOutline, IoMenuOutline } from 'react-icons/io5';

import {
  oneDayAgo,
  oneHourAgo,
  todayFullDate,
  todayFullTime,
  getOffsetDateArr,
  chartRangeMappings,
} from 'shared/helpers/datePickerHelpers';
import {
  chartRangeSelectOptions,
  DEFAULT_CHART_RANGE_LABEL,
} from 'shared/constants/datePickerConstants';
import FluxTooltip from 'shared/components/FluxTooltip';
import { extendedDayJs } from 'shared/helpers/timeHelpers';
import FluxDatePicker from 'shared/components/FluxDatePicker';

import { formatTimeStringsForCharts } from 'single-asset/helpers/singleAssetHelpers';
import FluxCustomSelect from 'single-asset/components/charts/edit-charts/FluxCustomSelect';
import EditChartsSection from 'single-asset/components/charts/edit-charts/EditChartsSection';
import ConfirmTemplateModal from 'single-asset/components/charts/edit-charts/ConfirmTemplateModal';
import ConfirmEditChartsSection from 'single-asset/components/charts/edit-charts/ConfirmEditChartsSection';

import './ChartForm.scss';

const ChartForm = ({
  getCharts,
  chartDates,
  chartsRange,
  setChartDates,
  setChartsRange,
  displayGridView,
  isEditingCharts,
  resetChartsLayout,
  setIsEditingCharts,
  setDisplayGridView,
  updateChartsLayout,
  addChartToLayout,
  chartables,
  applyLayoutToTemplate,
  chartLayoutType,
  chartLayout,
}) => {
  const customDateRangeOption = chartRangeSelectOptions.find((option) => option.label === 'Custom');
  const initialDateRangeOption = chartRangeSelectOptions.find((option) => option.label === DEFAULT_CHART_RANGE_LABEL);

  const [metricSearch, setMetricSearch] = useState('');
  const [newChartIsOpen, setNewChartIsOpen] = useState(false);
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [chartDropdownValues, setChartDropdownValues] = useState([]);
  const [defaultChartRangeOption, setDefaultChartRangeOption] = useState(initialDateRangeOption);

  const chartFormHeaderText = !isEditingCharts ? 'Asset Charts' : 'Editing Chart Layout';
  const chartableOptions = chartables?.map((entry) => ({
    value: entry?.label,
    label: entry?.label,
    key: entry?.label,
  }));

  const showDatesResetButton = isCustomSelected || defaultChartRangeOption?.label !== DEFAULT_CHART_RANGE_LABEL;

  const resetDefaultStates = () => {
    setChartDropdownValues([]);
    setMetricSearch('');
  };

  const handleChartCreation = (addToTemplate) => {
    const newChartOptions = chartDropdownValues.map((newItem) => chartables.find((entry) => entry.label === newItem));
    addChartToLayout(newChartOptions, addToTemplate);
    setNewChartIsOpen(false);
  };

  const resetDatesToDefault = () => {
    setIsCustomSelected(false);
    setChartsRange(initialDateRangeOption?.label);

    const dateRange = getOffsetDateArr([
      extendedDayJs(oneDayAgo),
      extendedDayJs(todayFullTime),
    ]);

    getCharts({ dates: dateRange });
  };

  const handleDropdownToggle = () => {
    newChartIsOpen && resetDefaultStates();
    setNewChartIsOpen(!newChartIsOpen);
  };

  const handleChartLayoutEdit = () => {
    setIsEditingCharts(false);
    updateChartsLayout();
  };

  const handleTemplateLayoutConfirmation = (templateType) => {
    applyLayoutToTemplate(templateType);
    setConfirmModalIsOpen(false);
  };

  const handleMetricSelection = (value) => {
    chartDropdownValues.includes(value)
      ? setChartDropdownValues(chartDropdownValues.filter((m) => m !== value))
      : setChartDropdownValues([...chartDropdownValues, value]);
  };

  const handleRangeSelect = (dateRangeOption) => {
    const rangeString = dateRangeOption?.label;
    const mappedOption = chartRangeMappings
      ?.find((mapping) => mapping?.label === rangeString)?.value;

    setIsCustomSelected(false);
    setChartsRange(rangeString);

    if (rangeString === 'Custom') {
      setDefaultChartRangeOption(customDateRangeOption);
    }

    if (!mappedOption) {
      return setIsCustomSelected(true);
    }

    if (rangeString?.includes('Days')) {
      const dateRange = getOffsetDateArr([
        extendedDayJs(mappedOption),
        extendedDayJs(todayFullDate),
      ]);
      return getCharts({ dates: dateRange });
    }

    if (rangeString?.includes('24')) {
      return handleTimeRangeChange([
        extendedDayJs(mappedOption),
        extendedDayJs(todayFullTime),
      ]);
    }

    return handleTimeRangeChange([
      extendedDayJs(oneHourAgo),
      extendedDayJs(todayFullTime),
    ]);
  };

  const handleTimeRangeChange = (newDateRange) => {
    getCharts({ dates: formatTimeStringsForCharts(newDateRange) });
  };

  useEffect(() => {
    if (chartsRange) {
      const selectedOption = chartRangeSelectOptions
        .find((entry) => entry?.label === chartsRange);

      if (selectedOption) {
        setDefaultChartRangeOption(selectedOption);

        if (selectedOption?.label === 'Custom') {
          setIsCustomSelected(true);
        }
      }
    }
  }, [chartsRange]);

  return (
    <form className="chart-form">
      <h1 className="chart-form-header-text">
        {chartFormHeaderText}
      </h1>
      {isEditingCharts ? (
        <ConfirmEditChartsSection
          resetChartsLayout={resetChartsLayout}
          setIsEditingCharts={setIsEditingCharts}
          handleChartLayoutEdit={handleChartLayoutEdit}
        />
      ) : (
        <div className="chart-form-edit-section">
          <div className={isCustomSelected
            ? 'chart-form--dates-with-calendar'
            : 'chart-form--dates'}
          >
            <div className="chart-form-edit-subsection">
              <FluxCustomSelect
                handleChange={handleRangeSelect}
                options={chartRangeSelectOptions}
                showResetButton={showDatesResetButton}
                handleResetClick={resetDatesToDefault}
                defaultOption={defaultChartRangeOption}
              />
              <FluxTooltip
                showInfoCircle={false}
                title={`${displayGridView ? 'List' : 'Grid'} View`}
                description={
                  `View all of your charts in a ${displayGridView ? 'single list' : 'grid layout'}.`
                }
                tooltip={displayGridView ? (
                  <IoMenuOutline
                    size={24}
                    color="#BCBCBC"
                    onClick={() => setDisplayGridView(!displayGridView)}
                  />
                ) : (
                  <IoGridOutline
                    size={24}
                    color="#BCBCBC"
                    onClick={() => setDisplayGridView(!displayGridView)}
                  />
                )}
              />
            </div>
            {isCustomSelected && (
              <FluxDatePicker
                popupClasses="charts"
                containerClasses="charts"
                finalizedDates={chartDates}
                setFinalizedDates={setChartDates}
                clearFunction={resetDefaultStates}
              />
            )}
          </div>
          <EditChartsSection
            metricSearch={metricSearch}
            chartableOptions={chartableOptions}
            newChartIsOpen={newChartIsOpen}
            setMetricSearch={setMetricSearch}
            setIsEditingCharts={setIsEditingCharts}
            handleChartCreation={handleChartCreation}
            chartDropdownValues={chartDropdownValues}
            handleDropdownToggle={handleDropdownToggle}
            handleMetricSelection={handleMetricSelection}
            chartLayoutType={chartLayoutType}
            chartLayout={chartLayout}
            setConfirmModalIsOpen={setConfirmModalIsOpen}
          />
        </div>
      )}
      <ConfirmTemplateModal
        isOpen={confirmModalIsOpen}
        onSubmit={handleTemplateLayoutConfirmation}
        closeModal={() => setConfirmModalIsOpen(false)}
      />
    </form>
  );
};

export default ChartForm;

import React from 'react';

import { getTitleCase } from 'single-asset/helpers/moreInfoHelpers';
import { LATITUDE_ENTRY_VALUE, LONGITUDE_ENTRY_VALUE } from 'single-asset/constants/moreInfoConstants';

import './UnitProfileCard.scss';

const UnitProfileCard = ({ entry }) => {
  const entryValue = entry.value;
  let title = getTitleCase(entry?.title);

  if (entry?.title === LATITUDE_ENTRY_VALUE) {
    title = 'Latitude';
  }

  if (entry?.title === LONGITUDE_ENTRY_VALUE) {
    title = 'Longitude';
  }

  return (
    <div className="unit-profile-card">
      <p className="card-value">{entryValue}</p>
      <p className="card-title">{title}</p>
    </div>
  );
};

export default UnitProfileCard;

const RightChartsYAxisTick = ({
  x, y, payload, stroke,
}) => (
  <g className="y-axis-tick" transform={`translate(${x + 20},${y})`}>
    <text x={0} y={0} fontSize="12px" fill={stroke} fontWeight={600}>
      <tspan textAnchor="middle">{payload.value}</tspan>
    </text>
  </g>
);

export default RightChartsYAxisTick;

import { Tooltip } from 'antd';
import { IoInformationCircleOutline } from 'react-icons/io5';

import './FluxTooltip.scss';

const FluxTooltip = ({
  title,
  tooltip,
  description,
  arrow = true,
  placement = 'top',
  circleColor = 'black',
  circleSize = '16px',
  showInfoCircle = true,
  ...otherProps
}) => {
  const TitleComponent = () => (
    <div className="flux-tooltip-title">
      <h5 className="flux-tooltip-title__header">
        {title}
      </h5>
      <h6 className="flux-tooltip-title__description">
        {description}
      </h6>
    </div>
  );

  return (
    <Tooltip
      arrow={arrow}
      placement={placement}
      className="flux-tooltip"
      title={<TitleComponent />}
      {...otherProps}
    >
      <div className="flux-tooltip-content">
        {tooltip}
        {showInfoCircle && <IoInformationCircleOutline color={circleColor} size={circleSize} />}
      </div>
    </Tooltip>
  );
};

export default FluxTooltip;

import * as notifactionsActions from 'shared/actions/notificationsActionTypes';

export const addNotification = (notification) => ({
  type: notifactionsActions.NOTIFICATIONS__ADD_NEW_NOTIFICATION,
  notification,
});

export const removeNotification = () => ({
  type: notifactionsActions.NOTIFICATIONS__REMOVE_NOTIFICATION,
});

import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import FluxTooltip from 'shared/components/FluxTooltip';

import ChartStatCard from 'single-asset/components/charts/chart-details/ChartStatCard';

const ChartStatsRow = ({
  chartData,
  chartStats,
  aggregateData,
  displayGridView,
  setCurrentStatDetail,
  chartLayout,
}) => aggregateData && Object.entries(aggregateData).map(([title], i) => (
  <FluxTooltip
    title={title}
    key={uuidv4()}
    showInfoCircle={false}
    tooltip={(
      <ChartStatCard
        title={title}
        chartData={chartData}
        chartStats={chartStats}
        aggregateData={aggregateData}
        displayGridView={displayGridView}
        setCurrentStatDetail={setCurrentStatDetail}
        color={chartLayout?.at(i).color || '#848383'}
        statValue={chartStats[i]?.payload[title] ?? aggregateData[title]?.mean}
      />
    )}
  />
));
export default ChartStatsRow;

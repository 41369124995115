import React from 'react';
import { Skeleton } from 'antd';

import './UnitProfileCard.scss';

const LoadingCard = () => (
  <div className="unit-profile-card">
    <Skeleton.Input active size="small" />
    <Skeleton.Input active />
  </div>
);

export default LoadingCard;

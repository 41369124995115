import React from 'react';

import { extendedDayJs } from 'shared/helpers/timeHelpers';

import { CHART_TIME_FORMAT, MONTH_DAY_YEAR } from 'single-asset/constants/singleAssetConstants';

const EventTooltip = (
  { eventDataEntry: { eventMessage: { eventMessage }, x } },
) => (
  <div className="event-tooltip-content">
    <p className="event-message">{eventMessage}</p>
    <div className="time-container">
      <p className="event-time">
        {extendedDayJs(x).format(MONTH_DAY_YEAR)}
      </p>
      <div className="event-time-dot" />
      <p className="event-time">
        {extendedDayJs(x).format(CHART_TIME_FORMAT)}
      </p>
    </div>
  </div>
);

export default EventTooltip;

import ChartsList from 'single-asset/components/charts/ChartsList';
import DraggableChartList from 'single-asset/components/charts/edit-charts/DraggableChartList';

const ChartsContentSection = ({
  getCharts,
  chartsData,
  isEditingCharts,
  displayGridView,
  resetChartsLayout,
  chartLayout,
  setEditedChartLayout,
}) => (
  <div className="charts-container--chart-content">
    {!isEditingCharts ? (
      <ChartsList
        getCharts={getCharts}
        chartsData={chartsData}
        displayGridView={displayGridView}
        resetChartsLayout={resetChartsLayout}
        chartLayout={chartLayout}
      />
    ) : (
      <DraggableChartList
        chartLayout={chartLayout}
        setEditedChartLayout={setEditedChartLayout}
      />
    )}
  </div>
);

export default ChartsContentSection;

import { repositoryFetch } from 'shared/helpers/repositoryHelpers';
import { DEFAULT_SERIES_DATA_VERSION } from 'shared/constants/repositoryConstants';

export const axilSeriesByDateEndpoint = 'axil/series/hot/by_date';

export const fetchSeriesByDate = ({
  url,
  body,
  accessToken,
  version = DEFAULT_SERIES_DATA_VERSION,
}) => repositoryFetch({
  body,
  accessToken,
  endpoint: `/v${version}/${axilSeriesByDateEndpoint}?label=${url}`,
});

export const fetchChartMetrics = ({
  accessToken,
  body,
}) => repositoryFetch({
  accessToken,
  body,
  endpoint: '/v2/axil/widgets/builder/custom_chart',
});

export const modifyTelemetryCharts = ({
  accessToken,
  body,
}) => repositoryFetch({
  accessToken,
  body,
  endpoint: '/v1/axil/layouts/save',
});

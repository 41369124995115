import { Switch } from 'antd';

import './BlueToggle.scss';

const BlueToggle = ({
  checked,
  onChange,
}) => (
  <Switch
    className="blue-toggle"
    checked={checked}
    onChange={onChange}
  />
);

export default BlueToggle;

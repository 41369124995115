import Charting from 'classes/charting';
import React, {
  createContext,
  ReactNode,
  useMemo,
  useState,
} from 'react';

export interface ChartingContextType {
  charting: Charting | null;
  // eslint-disable-next-line no-unused-vars
  setCharting: (charting: Charting) => void;
}

const defaultCharting = {
  charting: null,
  setCharting: () => {},
};

export const ChartingContext = createContext<ChartingContextType>(defaultCharting);

export const ChartingProvider = ({ children }: { children: ReactNode }) => {
  const [charting, setCharting] = useState<Charting | null>(new Charting({}));

  const providerValue = useMemo(() => ({
    charting,
    setCharting,
  }), [charting, setCharting]);

  return (
    <ChartingContext.Provider value={providerValue}>
      {children}
    </ChartingContext.Provider>
  );
};

import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IoMenuOutline } from 'react-icons/io5';

import fluxLogo from 'assets/images/new-flux-logo.png';

const MobileHeader = ({ navLinks, activeLink, mobileVisibility }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { logout } = useAuth0();

  useEffect(() => {
    if (mobileVisibility === 'none') setMobileMenuOpen(false);
  }, [mobileVisibility]);

  return (
    <nav className="mobile-header">
      {!mobileMenuOpen
        ? (
          <div className="closed-menu-content">
            <a className="flux-logo" href="/">
              <img src={fluxLogo} />
            </a>
            <IoMenuOutline
              size={50}
              className="menu-outline"
              onClick={() => setMobileMenuOpen(true)}
            />
          </div>
        )
        : (
          <div className={`${mobileMenuOpen ? 'open-menu' : ''}`}>
            <div
              role="button"
              tabIndex={0}
              className="void-space"
              aria-label="Close mobile navigation"
              onClick={() => setMobileMenuOpen(false)}
            />
            <div className="mobile-menu">
              <div className="link-container">
                {navLinks.map((linkItem) => {
                  const isActive = activeLink.key === linkItem.key;
                  return (
                    <div
                      key={linkItem.key}
                      className={`mobile-links ${isActive ? 'active-link' : ''}`}
                    >
                      <NavLink
                        to={linkItem.path}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {linkItem.label}
                      </NavLink>
                    </div>
                  );
                })}
              </div>
              <div className="mobile-bottom">
                <div className={`${activeLink.key === 'Settings' ? 'active-link' : ''}`}>
                  <NavLink
                    to="/notifications"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Account Settings
                  </NavLink>
                </div>
                <div>
                  <button onClick={() => {
                    logout({ returnTo: window.location.origin });
                    setMobileMenuOpen(false);
                  }}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
    </nav>
  );
};

export default MobileHeader;

export const EXTERNAL_ACCOUNT_INFO_STATS = [
  'name',
  'email',
  'phone number',
];

export const INTERNAL_ACCOUNT_INFO_STATS = [
  ...EXTERNAL_ACCOUNT_INFO_STATS,
  'demo mode',
];

export const NUM_REGEX = /^\d+$/;

export const NOTIFICATION_TYPES = [
  'debug',
  'info',
  'warning',
  'danger',
  'success',
];

export const TABLE_NOTIFICATION_TYPES = [
  'all',
  ...NOTIFICATION_TYPES,
];

export const NOTIFICATION_SECTIONS = {
  subscribed: 'subscribed',
  addNew: 'addNew',
};

export const DELIVERY_METHOD_TOGGLE_TEXTS = {
  email: 'Notify me by email',
  text: 'Notify me by text',
};

export const NOTIFICATIONS_ASSET_FILTER_PLACEHOLDER = 'Filter by asset, location, organization...';

export const notificationsTableScroll = {
  y: 600,
  x: '50vw',
};

export const NOTIFICATIONS_TABLE_ROW_HEIGHT = 64;

export const DND_OPTIONS = [
  {
    label: '12:00 am',
    value: 0,
  },
  {
    label: '1:00 am',
    value: 1,
  },
  {
    label: '2:00 am',
    value: 2,
  },
  {
    label: '3:00 am',
    value: 3,
  },
  {
    label: '4:00 am',
    value: 4,
  },
  {
    label: '5:00 am',
    value: 5,
  },
  {
    label: '6:00 am',
    value: 6,
  },
  {
    label: '7:00 am',
    value: 7,
  },
  {
    label: '8:00 am',
    value: 8,
  }, {
    label: '9:00 am',
    value: 9,
  },
  {
    label: '10:00 am',
    value: 10,
  },
  {
    label: '11:00 am',
    value: 11,
  },
  {
    label: '12:00 pm',
    value: 12,
  },
  {
    label: '1:00 pm',
    value: 13,
  },
  {
    label: '2:00 pm',
    value: 14,
  },
  {
    label: '3:00 pm',
    value: 15,
  },
  {
    label: '4:00 pm',
    value: 16,
  },
  {
    label: '5:00 pm',
    value: 17,
  },
  {
    label: '6:00 pm',
    value: 18,
  },
  {
    label: '7:00 pm',
    value: 19,
  },
  {
    label: '8:00 pm',
    value: 20,
  },
  {
    label: '9:00 pm',
    value: 21,
  },
  {
    label: '10:00 pm',
    value: 22,
  },
  {
    label: '11:00 pm',
    value: 23,
  },
];

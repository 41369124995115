import { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DraggableChartCard from 'single-asset/components/charts/edit-charts/DraggableChartCard';

const DraggableChartList = ({
  chartLayout,
  setEditedChartLayout,
}) => {
  const [editLayout, setEditLayout] = useState(chartLayout.charts);

  const removeChart = (indexOfItem) => {
    const chartsCopy = [...editLayout];
    chartsCopy.splice(indexOfItem, 1);
    const newLayout = chartsCopy.map((c, index) => ({
      ...c,
      order: index,
    }));
    setEditLayout(newLayout);
    setEditedChartLayout(newLayout);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return false;
    const chartCopy = [...editLayout];
    chartCopy.splice(result.destination.index, 0, chartCopy.splice(result.source.index, 1).at(0));
    const newLayout = chartCopy.map((c, index) => ({
      ...c,
      order: index,
    }));
    setEditLayout(newLayout);
    return setEditedChartLayout(newLayout);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-charts">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {
              editLayout?.map((c, index) => (
                <DraggableChartCard
                  key={c.title}
                  uuid={c.title}
                  index={index}
                  seriesData={c.chartValues}
                  removeChart={removeChart}
                />
              ))
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableChartList;

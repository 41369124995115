import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import ChartCard from 'single-asset/components/charts/ChartCard';

import './ChartsList.scss';

const ChartsList = ({
  name,
  getCharts,
  chartsData,
  getChartsByDate,
  displayGridView,
  unitChartConfig,
  chartLayout,
}) => {
  const [processedData, setProcessedData] = useState(null);

  useEffect(() => {
    const allTimestamps = chartsData.reduce((acc, chart) => {
      const chartTimestamps = chart.data?.map((data) => data.x);
      return acc.concat(chartTimestamps);
    }, []);
    const noDuplicateTimestamps = allTimestamps.filter((v, i, a) => a.findIndex((t) => (t === v)) === i);
    const newChartsData = chartsData.map((singleChartData) => {
      const updatedChartData = singleChartData;
      updatedChartData.data = noDuplicateTimestamps.map((timestamp) => {
        const data = singleChartData.data.find((d) => d.x === timestamp);
        if (data) {
          return data;
        }
        return { x: timestamp };
      }).sort((a, b) => a.x - b.x);
      return updatedChartData;
    });
    setProcessedData(newChartsData);
  }, [chartsData]);

  return (
    <div className="charts-list-container">
      {processedData?.map((chartData, index) => (
        <ChartCard
          name={name}
          key={uuid()}
          index={index}
          getCharts={getCharts}
          chartData={chartData}
          unitChartConfig={unitChartConfig}
          displayGridView={displayGridView}
          getChartsByDate={getChartsByDate}
          chartLayout={chartLayout.charts.at(index)}
          width={displayGridView ? '48%' : '98.5%'}
        />
      ))}
    </div>
  );
};

export default ChartsList;

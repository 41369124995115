const NUMBER_OF_KEYS = 8;
export const loadingKeyedData = [...Array(NUMBER_OF_KEYS)].map((_, index) => ({ key: index + 1 }));

export const tagsForRequestBody = {
  lat: {
    key: 'lat',
    tag_id: 30,
    agg_func: 'last_value',
    round: false,
  },
  lon: {
    key: 'lon',
    tag_id: 30,
    agg_func: 'last_value',
    round: false,
  },
  application_type: {
    order: 3,
    tag_id: 34,
    key: 'str_val',
    agg_func: 'last_value',
  },
  compressor_serial: {
    order: 4,
    tag_id: 43,
    key: 'value',
    agg_func: 'last_value',
  },
  driver: {
    order: 2,
    tag_id: 31,
    key: 'str_val',
    agg_func: 'last_value',
  },
  driver_rated_hp: {
    order: 5,
    tag_id: 107,
    key: 'value',
    agg_func: 'last_value',
  },
  last_pm_date: {
    order: 7,
    tag_id: 33,
    key: 'str_val',
    agg_func: 'last_value',
  },
  lease: {
    order: 1,
    tag_id: 58,
    key: 'value',
    agg_func: 'last_value',
  },
  next_pm_date: {
    order: 1,
    tag_id: 32,
    key: 'str_val',
    agg_func: 'last_value',
  },
};

export const LATITUDE_ENTRY_VALUE = 'lat';
export const LONGITUDE_ENTRY_VALUE = 'lon';

export const GET_UNIT_PROFILE_INFO_ENDPOINT = '/v1/axil/config/tag_values/by_tag_id';
export const GET_UNIT_PROFILE_IMAGE_ENDPOINT = '/unit-profiles/images/assets?assetName=';

import React, { useEffect, useState } from 'react';
import { Card } from '@flogistix/flo-ui';

interface DecoderCardProps {
  roleTitle: string;
  plcValue: string;
  // eslint-disable-next-line no-unused-vars
  decodingFunction: (plcValue: string, orgId: number) => string;
  orgId: number | null;
}

const DecoderCard = ({
  roleTitle,
  plcValue,
  orgId,
  decodingFunction,
}: DecoderCardProps) => {
  const [decoderValue, setDecoderValue] = useState<string | null>(null);

  useEffect(() => {
    if (plcValue) {
      setDecoderValue(decodingFunction(plcValue, orgId as number));
    } else {
      setDecoderValue(null);
    }
  }, [plcValue, decodingFunction, orgId]);

  return (
    <Card>
      <p className="role-title">{roleTitle}</p>
      <p className="decoder-value">{decoderValue || '<Encoded>'}</p>
    </Card>
  );
};

export default DecoderCard;

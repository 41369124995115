import React from 'react';

import { IEmissionsEquation } from 'shared/constants/emissionsCalculatorConstants';

import EmissionsPill from 'single-asset/components/emissions-calculator/equations-breakdown-tab/EmissionsPill';

interface EmissionsEquationProps {
  entry: IEmissionsEquation;
}

const EmissionsEquation: React.FC<EmissionsEquationProps> = ({ entry }) => (
  <section className="equation-breakdown">
    <EmissionsPill label={entry.description} />
    {entry.mathFunction
      ? <div className="carbon-calculator-math-symbol">{entry.mathFunction}</div>
      : <div style={{ width: '5px' }} />}
  </section>
);

export default EmissionsEquation;

import AssetListFormInput from 'dashboard/components/filtration/AssetListFormInput';
import { Toggle } from '@flogistix/flo-ui';

import './AssetListForm.scss';

const AssetListForm = ({
  units,
  disabled,
  savedFilters,
  removeFilter,
  setSavedFilters,
  setAssetFilterText,
  onDisplayTypeChange,
  selectedDisplayType,
  assetFilterText,
}) => {
  const handleInputText = (e) => {
    setAssetFilterText(e?.target?.value?.toString());
  };

  return (
    <div className="asset-list-form">
      <AssetListFormInput
        units={units}
        disabled={disabled}
        savedFilters={savedFilters}
        removeFilter={removeFilter}
        handleInputText={handleInputText}
        setSavedFilters={setSavedFilters}
        setAssetFilterText={setAssetFilterText}
        assetFilterText={assetFilterText}
      />
      <Toggle
        firstOption="Table View"
        secondOption="Card View"
        onToggle={onDisplayTypeChange}
        activeOption={selectedDisplayType === 'Table' ? 'Table View' : 'Card View'}
      />
    </div>
  );
};

export default AssetListForm;

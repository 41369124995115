import { Table } from 'antd';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { extendedDayJs } from 'shared/helpers/timeHelpers';
import { filterArray } from 'shared/helpers/filterHelpers';
import { selectSession } from 'shared/reducers/sessionReducer';
import { loadingKeyedTableData } from 'shared/constants/tableDataConstants';
import { getWorkOrders, showWorkOrderInfo } from 'shared/repositories/workOrderRepository';

import { createTableSorter } from 'dashboard/helpers/dashboardHelpers';

import { rowClassName } from 'single-asset/helpers/workOrderHelpers';
import { sharedRequestBody } from 'single-asset/helpers/singleAssetHelpers';
import WorkOrdersSearchForm from 'single-asset/components/work-orders/WorkOrdersSearchForm';
import { WORK_ORDER_TABLE_LOADING_COLUMNS } from 'single-asset/components/work-orders/WorkOrderLoadingColumn';

import './WorkOrdersPage.scss';

const WorkOrdersPage = ({ asset }) => {
  const currentSession = useSelector(selectSession);

  const [workOrders, setWorkOrders] = useState([]);
  const [workOrdersLoading, setWorkOrdersLoading] = useState(false);

  const [searchString, setSearchString] = useState('');
  const [finalizedDates, setFinalizedDates] = useState(null);
  const [defaultWorkOrders, setDefaultWorkOrders] = useState([]);
  const [formattedWorkOrders, setFormattedWorkOrders] = useState([]);

  const workOrderTableColumns = [
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('start_time'),
      },
      render: (value) => (
        extendedDayJs(value).format('hh:mm MM/DD/YYYY')
      ),
    },
    {
      title: 'Work Order Id',
      dataIndex: 'flogistix_id',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('flogistix_id'),
      },
    },
    {
      title: 'Mechanic',
      dataIndex: 'mechanic_name',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('mechanic_name'),
      },
    },
    {
      title: 'Type',
      dataIndex: 'call_type',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('call_type'),
      },
      render: (value) => (
        <span className="type-column-value">{value}</span>
      ),
    },
    {
      title: 'Job Safety Analysis',
      render: ({ id, hash }) => (
        <span
          className="jsa-button"
          onClick={(e) => {
            e.stopPropagation();
            showWorkOrderInfo(id, hash, true);
          }}
        >
          View JSA
        </span>
      ),
    },
  ];

  const handleRowClick = (record) => {
    const { id, hash } = record;
    return {
      onClick: () => showWorkOrderInfo(id, hash, false),
    };
  };

  const handleDateChange = () => {
    const formattedEnd = extendedDayJs(finalizedDates[1]).format('MM/DD/YYYY');
    const formattedStart = extendedDayJs(finalizedDates[0]).format('MM/DD/YYYY');

    const filteredWorkOrders = defaultWorkOrders?.filter((entry) => {
      const date = entry?.start_time;
      const formattedDate = extendedDayJs(date).format('MM/DD/YYYY');

      return formattedDate === formattedEnd
        || formattedDate === formattedStart
        || extendedDayJs(formattedDate).isBetween(formattedStart, formattedEnd);
    });

    setFormattedWorkOrders(filteredWorkOrders);
  };

  const handleClearDates = () => {
    setFinalizedDates(null);
    setFormattedWorkOrders(defaultWorkOrders);
  };

  const getWorkOrdersRequestBody = {
    key: asset?.asset_id,
    ...sharedRequestBody(asset),
    unit_number: asset?.asset_name,
  };

  const getWorkOrdersData = async () => {
    setWorkOrdersLoading(true);

    try {
      const response = await getWorkOrders({
        body: getWorkOrdersRequestBody,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      setWorkOrders(responseData);
    } catch (error) {
      console.error('Error fetching unit events:', error);
    } finally {
      setWorkOrdersLoading(false);
    }
  };

  useEffect(() => {
    if (!searchString) {
      return setFormattedWorkOrders(defaultWorkOrders);
    }

    const filteredWorkOrders = filterArray(defaultWorkOrders, searchString?.toLowerCase());
    return setFormattedWorkOrders(filteredWorkOrders);
  }, [searchString]);

  useEffect(() => {
    if (finalizedDates?.length) {
      handleDateChange();
    }
  }, [finalizedDates]);

  useEffect(() => {
    if (workOrders.length) {
      const currentFormattedWorkOrders = workOrders
        ?.map((entry) => (
          {
            ...entry,
            key: entry?.id,
          }
        ));

      setDefaultWorkOrders(currentFormattedWorkOrders);
      setFormattedWorkOrders(currentFormattedWorkOrders);
    }
  }, [workOrders]);

  useEffect(() => {
    currentSession?.token && asset && getWorkOrdersData();
  }, [currentSession, asset]);

  return (
    <section className="work-orders-page-container">
      <div className="work-orders-page">
        <WorkOrdersSearchForm
          finalizedDates={finalizedDates}
          handleFiltering={setSearchString}
          handleClearDates={handleClearDates}
          setFinalizedDates={setFinalizedDates}
          formattedWorkOrders={formattedWorkOrders}
        />
        <Table
          pagination={false}
          scroll={{ y: 500 }}
          onRow={handleRowClick}
          rowClassName={rowClassName}
          className="work-orders-table"
          dataSource={workOrdersLoading
            ? loadingKeyedTableData
            : formattedWorkOrders}
          columns={workOrdersLoading
            ? WORK_ORDER_TABLE_LOADING_COLUMNS
            : workOrderTableColumns}
        />
      </div>
    </section>
  );
};

export default WorkOrdersPage;

import PageHeadingSkeleton from 'account-settings/components/PageHeadingSkeleton';
import NotificationSkeleton from 'account-settings/components/notifications/NotificationSkeleton';
import AccountInformationSkeleton from 'account-settings/components/account-information/AccountInformationSkeleton';

const AccountSettingsPageSkeleton = () => (
  <div className="account-settings-page">
    <PageHeadingSkeleton />
    <AccountInformationSkeleton />
    <div className="notifications-content">
      <NotificationSkeleton />
    </div>
  </div>
);

export default AccountSettingsPageSkeleton;

import './LiveCount.scss';

const LiveCount = ({
  count,
  countType,
  filterLabel = 'All',
}) => (
  <section data-testid="live-count" className="live-count">
    <div>
      <h2>{filterLabel}</h2>
      <h1>
        {countType} { ' ' }
        <span className="number-badge">
          {count}
        </span>
      </h1>
    </div>
  </section>
);

export default LiveCount;

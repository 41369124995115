import { Skeleton } from 'antd';

import './ScheduledReportTableSkeleton.scss';

const ScheduledReportTableSkeleton = () => (
  <Skeleton
    active
    className="reports-table-skeleton"
    title={{ width: '100%' }}
    paragraph={{
      rows: 2,
      width: ['25%', '100%'],
    }}
  />
);

export default ScheduledReportTableSkeleton;

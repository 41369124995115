/* eslint-disable consistent-return */
const ENV_URL = process.env.REACT_APP_BUILD_ENV === 'production'
  ? 'https://'
  : 'https://dev-';

const QUERIES_URL = `${ENV_URL}gas-analysis.api.axil.ai`;
const GAS_ANALYSIS_URL = `${QUERIES_URL}/gas-analysis`;

export const getGasAnalysisForUnit = async ({
  accessToken,
  gasAnalysisId,
}) => {
  const FORMATTED_URL = `${GAS_ANALYSIS_URL}/${gasAnalysisId}`;

  try {
    const response = await fetch(FORMATTED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching gas analysis details: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching gas analysis details.');
  }
};

export const postGasAnalysis = async ({
  body,
  accessToken,
}) => {
  try {
    const response = await fetch(
      GAS_ANALYSIS_URL,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(`Error fetching gas analysis details: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching gas analysis details.');
  }
};

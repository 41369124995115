import React from 'react';

import './EmissionsPill.scss';

interface EmissionsPillProps {
  style?: React.CSSProperties;
  label: string | React.ReactNode;
}

const EmissionsPill: React.FC<EmissionsPillProps> = ({ label, style }) => (
  <div style={style} className="emissions-pill">
    {label}
  </div>
);

EmissionsPill.defaultProps = {
  style: {},
};

export default EmissionsPill;

import React from 'react';
import { Button } from 'antd';

import { EDIT_CHART_LAYOUT_HELPER_TEXT } from 'single-asset/constants/telemetryConstants';

const ConfirmEditChartsSection = ({
  resetChartsLayout,
  setIsEditingCharts,
  handleChartLayoutEdit,
}) => (
  <div className="chart-form-confirm-edit-section">
    <p className="edit-helper-text">{EDIT_CHART_LAYOUT_HELPER_TEXT}</p>
    <div className="buttons-container">
      <Button
        className="cancel-button"
        onClick={() => {
          setIsEditingCharts(false);
          resetChartsLayout();
        }}
      >
        Cancel
      </Button>
      <Button className="save-button" onClick={handleChartLayoutEdit}>
        Save
      </Button>
    </div>
  </div>
);

export default ConfirmEditChartsSection;

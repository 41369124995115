export const findAllChartSeries = (nodes) => nodes
  ?.map((node) => node?.config?.series?.map((seriesItem) => ({
    ...seriesItem,
    formattedLabel: seriesItem?.label?.toLowerCase().replace(/\s+/g, '-'),
  })) || [])
  ?.filter((series) => series?.length);

export const addFormattedUrlToEntries = (config) => config?.map((innerArray) => {
  const combinedLabel = innerArray?.map((item) => item?.formattedLabel)?.join(',');
  return innerArray.map((item) => ({
    ...item,
    combinedLabel,
  }));
});

export const addUnitDataToEntries = (config, asset) => config?.map((series) => series.map((seriesItem) => ({
  ...seriesItem,
  org_id: asset?.org_id,
  site_id: asset?.site_id,
  asset_id: asset?.asset_id,
  device_id: asset?.device_id,
})));

export const addCombinedUrlToEntries = (entries) => entries?.map((entry) => ({
  series: entry,
  url: entry?.[0]?.combinedLabel,
}));

export const findLineColor = (arr, label) => {
  const matchingSeries = arr.flatMap((obj) => obj.config?.series || [])
    .find((series) => series.label === label);
  return matchingSeries ? matchingSeries.seriesProps.lineColor : null;
};

export const addCombinedLabel = (obj) => {
  const labels = obj.config.series.map((series) => series.label);
  const combinedLabel = labels.sort().join(', ');

  return {
    ...obj,
    combinedLabel,
  };
};

export const getArrayEntriesFromArrayStrings = (objectsArray, namesArray) => objectsArray.filter((object) => namesArray.includes(object.name));

export const generateNewChartConfig = (inputObject) => ({
  config: {
    series: [
      {
        agg: {
          max: ['y'],
          mean: ['y'],
          min: ['y'],
        },
        map: {
          val: 'y',
          uom: 'uom',
          epoch_ms: 'x',
        },
        seriesProps: {
          type: 'line',
          lineColor: '#0A84FF',
        },
        label: inputObject.label,
        tag_id: inputObject.tag_id,
      },
    ],
  },
});

export const getObjectBasedOnNumber = (number) => {
  switch (number) {
    case 915:
      return {
        value: {
          key: 'val',
          tag_id: number,
          agg_func: 'avg',
        },
      };
    case 34:
      return {
        value: {
          tag_id: number,
          key: 'str_val',
          agg_func: 'last_value',
        },
      };
    case 23:
    case 1688:
    case 14:
    case 13:
    case 564:
    case 565:
    case 869:
      return {
        value: {
          key: 'val',
          tag_id: number,
          agg_func: 'last_value',
        },
      };
    default:
      return {
        value: {
          tag_id: number,
          key: 'enum_label',
          agg_func: 'last_value',
        },
      };
  }
};

import { useSelector, useDispatch } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';

import { ChartingContext } from 'context/ChartingContext';

import { URL_PREFIX } from 'shared/repositories/urls';
import { selectSession } from 'shared/reducers/sessionReducer';
import { fetchTagById } from 'shared/repositories/axilTagRepository';
import { addNotification } from 'shared/actions/notificationsActions';
import { fetchSeriesByDate } from 'shared/repositories/axilSeriesRepository';
import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';

import {
  STATUS_STAT_ID,
  FAULTED_STAT_ID,
  RUNTIME_STAT_ID,
  ELEVATION_STAT_ID,
  CHARTABLES_COLORS,
  ENGINE_HOURS_STAT_ID,
  DISCHARGE_TEMP_STAT_ID,
  APPLICATION_TYPE_STAT_ID,
  SUCTION_PRESSURE_STAT_ID,
  DISCHARGE_PRESSURE_STAT_ID,
  SUCTION_PRESSURE_STAT_UOM_ID,
  DISCHARGE_PRESSURE_STAT_UOM_ID,
} from 'single-asset/constants/telemetryConstants';
import UnitStatList from 'single-asset/components/asset/UnitStatList';
import ChartSkeleton from 'single-asset/components/charts/ChartSkeleton';
import ChartsContainer from 'single-asset/components/charts/ChartsContainer';
import { getDefaultDateRange } from 'single-asset/helpers/singleAssetHelpers';
import { getObjectBasedOnNumber } from 'single-asset/helpers/telemetryHelpers';
import { getEngineMotorSpeedTag } from 'single-asset/constants/telemetryStatTagConfiguration';

import './TelemetryPage.scss';

const TelemetryPage = ({ enterpriseObject, asset, unit }) => {
  const session = useSelector(selectSession);

  const dispatch = useDispatch();
  const { charting } = useContext(ChartingContext);

  const [unitStats, setUnitStats] = useState([]);
  const [unitStatsUOMs, setUnitStatsUOMs] = useState([]);
  const [chartsLoading, setChartsLoading] = useState(false);
  const [unitStatsLoading, setUnitStatsLoading] = useState(false);

  const [unitStatusStat, setUnitStatusStat] = useState(null);
  const [unitFaultedStat, setUnitFaultsStat] = useState(null);
  const [unitRuntimeStat, setUnitRuntimeStat] = useState(null);
  const [unitElevationStat, setUnitElevationStat] = useState(null);
  const [unitEngineHoursStat, setUnitEngineHoursStat] = useState(null);
  const [unitDischargeTempStat, setUnitDischargeTempStat] = useState(null);
  const [unitElectricSpeedStat, setUnitElectricSpeedStat] = useState(null);
  const [unitSuctionPressureStat, setUnitSuctionPressureStat] = useState(null);
  const [unitApplicationTypeStat, setUnitApplicationTypeStat] = useState(null);
  const [unitDischargePressureStat, setUnitDischargePressureStat] = useState(null);

  const [unitSuctionPressureStatUOM, setUnitSuctionPressureStatUOM] = useState(null);
  const [unitDischargePressureStatUOM, setUnitDischargePressureStatUOM] = useState(null);

  const getAndSetUnitStat = async (tagId, setStatFunction, errorMessage) => {
    const tagConfig = getObjectBasedOnNumber(tagId);

    const requestBody = {
      agg: true,
      tags: tagConfig,
      asset_id: enterpriseObject?.assetId,
      device_id: enterpriseObject?.deviceId,
      site_id: enterpriseObject?.site?.siteId,
      org_id: enterpriseObject?.organization?.orgId,
      device_type_id: enterpriseObject?.assetTypeId,
    };

    try {
      const response = await fetchTagById({
        body: requestBody,
        accessToken: session?.token,
      });

      if (!response.ok) {
        throw new Error(`${errorMessage}: ${response.status}`);
      }

      const responseData = await response.json();

      setStatFunction(responseData?.[0] ?? MISSING_FIELD_VALUE_PLACEHOLDER);
    } catch (error) {
      console.error(`${errorMessage}:`, error);
      setStatFunction(MISSING_FIELD_VALUE_PLACEHOLDER);
    }
  };

  const getUnitStatusStat = () => (
    getAndSetUnitStat(STATUS_STAT_ID, setUnitStatusStat, 'Error fetching Unit Status stat')
  );

  const getUnitFaultsStat = () => (
    getAndSetUnitStat(FAULTED_STAT_ID, setUnitFaultsStat, 'Error fetching Unit Faulted stat')
  );

  const getUnitRuntimeStat = async () => (
    getAndSetUnitStat(RUNTIME_STAT_ID, setUnitRuntimeStat, 'Error fetching Unit Runtime stat')
  );

  const getUnitElevationStat = async () => (
    getAndSetUnitStat(ELEVATION_STAT_ID, setUnitElevationStat, 'Error fetching Unit Elevation stat')
  );

  const getUnitApplicationType = async () => (
    getAndSetUnitStat(APPLICATION_TYPE_STAT_ID, setUnitApplicationTypeStat, 'Error fetching Unit Application Type stat')
  );

  const getUnitDischargeTempStat = async () => (
    getAndSetUnitStat(DISCHARGE_TEMP_STAT_ID, setUnitDischargeTempStat, 'Error fetching Unit Discharge Temp stat')
  );

  const getUnitElectricSpeedStat = async () => (
    getAndSetUnitStat(getEngineMotorSpeedTag(unit?.driver ?? 'GED'), setUnitElectricSpeedStat, 'Error fetching Unit Electric Speed stat')
  );

  const getUnitEngineHoursStat = async () => (
    getAndSetUnitStat(ENGINE_HOURS_STAT_ID, setUnitEngineHoursStat, 'Error fetching Unit Engine Hours stat')
  );

  const getUnitSuctionPressureStat = async () => {
    getAndSetUnitStat(SUCTION_PRESSURE_STAT_ID, setUnitSuctionPressureStat, 'Error fetching Unit Suction Pressure stat');
    getAndSetUnitStat(SUCTION_PRESSURE_STAT_UOM_ID, setUnitSuctionPressureStatUOM, 'Error fetching Unit Suction Pressure stat UOM');
  };

  const getUnitDischargePressureStat = async () => {
    getAndSetUnitStat(DISCHARGE_PRESSURE_STAT_ID, setUnitDischargePressureStat, 'Error fetching Unit Discharge Pressure stat');
    getAndSetUnitStat(DISCHARGE_PRESSURE_STAT_UOM_ID, setUnitDischargePressureStatUOM, 'Error fetching Unit Discharge Pressure stat UOM');
  };

  const getUnitStats = async () => {
    setUnitStatsLoading(true);

    await Promise.all([
      getUnitStatusStat(),
      getUnitFaultsStat(),
      getUnitRuntimeStat(),
      getUnitElevationStat(),
      getUnitEngineHoursStat(),
      getUnitApplicationType(),
      getUnitElectricSpeedStat(),
      getUnitDischargeTempStat(),
      getUnitSuctionPressureStat(),
      getUnitDischargePressureStat(),
    ]);

    setUnitStatsLoading(false);
  };

  const defaultDates = getDefaultDateRange();

  const [chartables, setChartables] = useState(null);
  const [chartLayout, setChartLayout] = useState(null);
  const [chartBodies, setChartBodies] = useState(null);
  const [editedChartLayout, setEditedChartLayout] = useState(null);
  const [chartLayoutType, setChartLayoutType] = useState('default');

  const [charts, setCharts] = useState([]);
  const [chartsRange, setChartsRange] = useState(null);
  const [chartDates, setChartDates] = useState(defaultDates);

  const modifySeriesUOM = (dataArray) => dataArray.map((item) => ({
    ...item,
    map: {
      ...item.map,
      uom: `${item.formattedLabel}_uom`,
    },
  }));

  const getChart = async ({
    url,
    label,
    dates,
    series,
  // eslint-disable-next-line consistent-return
  }) => {
    const getChartRequestBody = {
      stop: dates[1],
      start: dates[0],
      series: modifySeriesUOM(series),
    };

    try {
      const response = await fetchSeriesByDate({
        url,
        body: getChartRequestBody,
        accessToken: session?.token,
      });

      if (!response.ok) {
        throw new Error(`Error fetching Chart ${label}: ${response.status}`);
      }

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(`Error fetching Chart ${label}:`, error);
    }
  };

  const getCharts = async ({
    dates,
    chartRequestBodies = chartBodies,
  }) => {
    setChartsLoading(true);
    const currentCharts = await Promise.all(chartRequestBodies?.map((requestBody) => getChart({
      dates,
      url: requestBody?.url,
      series: requestBody?.series,
    })));
    setChartsLoading(false);
    setCharts(currentCharts);
  };

  const getChartables = async () => {
    const queryString = `org_id=${asset?.org_id}&site_id=${asset?.site_id}&asset_id=${asset?.asset_id}`;
    try {
      const response = await fetch(`https://${URL_PREFIX}queries.api.axil.ai/v1/charts/chartables?${queryString}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${session.token}`,
          'Content-Type': 'application/json',
        },
      });
      const responseData = await response.json();
      if (responseData.statusCode) throw new Error('Error fetching chartables');
      setChartables(responseData);
    } catch (err) {
      console.error('Error fetching chartables:', err);
      setChartables(null);
    }
  };

  const resetAndRefetchCharts = async () => {
    const { layout, chartBodies: bodies, layoutType } = await charting.getUnitLayoutAndApiChartBodies(
      { ...asset, driver: unit?.driver ?? 'GED' },
      unit?.applicationType,
    );
    setChartBodies(bodies);
    setChartLayout(layout);
    setChartLayoutType(layoutType);
    getCharts({ dates: defaultDates, chartRequestBodies: bodies });
  };

  const updateChartsLayout = async (updatedChartLayout = null, addToTemplate = false) => {
    const newChartLayout = updatedChartLayout ?? editedChartLayout;
    if (!newChartLayout) {
      // no changes to existing layout
      return;
    }
    if (chartLayoutType === 'unit' || addToTemplate) {
      const result = await charting.updateLayout(chartLayout, newChartLayout, session?.token);
      if (!result) {
        // eslint-disable-next-line consistent-return
        return dispatch(addNotification({
          type: 'error',
          message: 'An error occurred while updating the chart layout. Please try again.',
        }));
      }
    } else {
      const { email } = session.user;
      const result = await charting.createLayout(newChartLayout, email, { ...asset, driver: unit?.driver ?? 'GED' }, unit?.applicationType, session?.token);
      if (!result) {
        // eslint-disable-next-line consistent-return
        return dispatch(addNotification({
          type: 'error',
          message: 'An error occurred while updating the chart layout. Please try again.',
        }));
      }
    }
    resetAndRefetchCharts();
  };

  const addChartToLayout = async (chartToAdd, addToTemplate) => {
    const formattedChartValues = chartToAdd.map((c, i) => ({
      key: c.key,
      label: c.label,
      tag: c.tag,
      color: CHARTABLES_COLORS[i % CHARTABLES_COLORS.length],
    }));
    const combinedLabels = chartToAdd.map((c) => c.label).join('- ');
    const newChart = {
      title: combinedLabels,
      order: chartLayout.charts.length,
      chartValues: formattedChartValues,
    };
    const newChartLayout = [
      ...chartLayout.charts,
      newChart,
    ];
    updateChartsLayout(newChartLayout, addToTemplate);
  };

  // layoutType is either 'global', 'driver', or 'applicationType'
  const applyLayoutToTemplate = async (layoutType) => {
    const { email } = session.user;
    let result = null;
    if (layoutType === 'global') {
      result = await charting.updateGlobalTemplateLayout(chartLayout.charts, email, session?.token);
    } else if (layoutType === 'driver') {
      result = await charting.updateDriverTemplateLayout(unit?.driver ?? 'GED', unit?.applicationType, chartLayout.charts, email, session?.token);
    } else if (layoutType === 'applicationType') {
      result = await charting.updateApplicationTemplateLayout(unit?.applicationType, chartLayout.charts, email, session?.token);
    }
    if (!result) {
      return dispatch(addNotification({
        type: 'error',
        message: 'An error occurred while setting the driver template. Please try again.',
      }));
    }
    // successfully applied layout to template, delete unit layout
    charting.deleteLayout(chartLayout.id, session?.token);
    setChartLayout(result);
    return setChartLayoutType(layoutType);
  };

  useEffect(() => {
    session?.token && asset && getUnitStats();
    session?.token && asset && getChartables();
  }, [session, asset]);

  useEffect(() => {
    chartDates !== defaultDates
      && getCharts({ dates: chartDates, chartRequestBodies: chartBodies });
  }, [chartDates]);

  useEffect(() => {
    if (!chartBodies) return;
    !chartsLoading && getCharts({ dates: defaultDates, chartRequestBodies: chartBodies });
  }, [chartBodies]);

  useEffect(() => {
    if (asset && unit) {
      const { layout, chartBodies: bodies, layoutType } = (asset && unit && charting.getUnitLayoutAndApiChartBodies({
        ...asset,
        driver: (unit?.driver ?? enterpriseObject?.driver) ?? 'GED',
      }, unit?.applicationType)) || {};
      setChartLayout(layout);
      setChartBodies(bodies);
      setChartLayoutType(layoutType);
    }
  }, [asset]);

  useEffect(() => {
    const newStats = [
      { name: 'Suction Pressure', uom: unitSuctionPressureStatUOM?.value },
      { name: 'Discharge Pressure', uom: unitDischargePressureStatUOM?.value },
    ];

    setUnitStatsUOMs(newStats);
  }, [unitDischargePressureStatUOM, unitSuctionPressureStatUOM]);

  useEffect(() => {
    const newStats = [
      { name: 'RTP', stat: unitRuntimeStat },
      { name: 'Status', stat: unitStatusStat },
      { name: 'Faulted', stat: unitFaultedStat },
      { name: 'Elevation', stat: unitElevationStat },
      { name: 'Engine Hours', stat: unitEngineHoursStat },
      { name: unit?.driver === 'ELEC' ? 'Electric Speed' : 'Engine Speed', stat: unitElectricSpeedStat },
      { name: 'Suction Pressure', stat: unitSuctionPressureStat },
      { name: 'Application Type', stat: unitApplicationTypeStat },
      { name: 'Discharge Temperature', stat: unitDischargeTempStat },
      { name: 'Discharge Pressure', stat: unitDischargePressureStat },
    ];

    setUnitStats(newStats);
  }, [
    unitStatusStat,
    unitRuntimeStat,
    unitFaultedStat,
    unitElevationStat,
    unitEngineHoursStat,
    unitElectricSpeedStat,
    unitDischargeTempStat,
    unitSuctionPressureStat,
    unitApplicationTypeStat,
    unitDischargePressureStat,
  ]);

  return (
    <div className="telemetry-page">
      <UnitStatList
        unitStats={unitStats}
        unitStatsUOMs={unitStatsUOMs}
        statsLoading={unitStatsLoading}
        driver={unit?.driver ?? 'GED'}
      />
      {(!chartsLoading && chartables && charts?.length) || chartBodies?.length === 0
        ? (
          <ChartsContainer
            charts={charts}
            getCharts={getCharts}
            chartables={chartables}
            chartDates={chartDates}
            asset={enterpriseObject}
            chartsRange={chartsRange}
            chartLayout={chartLayout}
            setChartDates={setChartDates}
            setChartsRange={setChartsRange}
            chartLayoutType={chartLayoutType}
            addChartToLayout={addChartToLayout}
            updateChartsLayout={updateChartsLayout}
            setEditedChartLayout={setEditedChartLayout}
            applyLayoutToTemplate={applyLayoutToTemplate}
          />
        )
        : <ChartSkeleton />}
    </div>
  );
};

export default TelemetryPage;

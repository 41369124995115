import assetReducer from 'single-asset/reducers/assetReducer';
import * as singleAssetActionTypes from 'single-asset/actions/singleAssetActionTypes';

export const defaultState = {
  asset: {},
};

const singleAssetReducer = (state = defaultState, action = {}) => (
  action.type === singleAssetActionTypes.SINGLE_ASSET__CLEAR_STATE
    ? defaultState
    : {
      asset: assetReducer(state.asset, action),
    });

export default singleAssetReducer;

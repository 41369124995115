/* eslint-disable */

const theme = (mode = 'light') => {
  if (mode === 'light') {
    return {
      "palette": {
        "neutral": {
          "neutral-0": "#fff",
          "neutral-100": "#f5f7fa",
          "neutral-200": "#e9ecef",
          "neutral-300": "#ccd1d9",
          "neutral-400": "#aab2bd",
          "neutral-500": "#656d78",
          "neutral-600": "#434a54",
          "neutral-700": "#3d3b3d",
          "neutral-800": "#333333",
          "neutral-900": "#212529",
          "neutral-1000": "#000"
        },
        "primary": {
          "full": "#FF6600",
          "light": "#FF9966",
          "muted": "#FFCC99",
          "dark": "#EB4008",
          "boxShadow": "0 0 0 2px #FF6600",
          "background": "#edeff3"
        },
        "secondary": {
          "full": "#4786ff",
          "light": "#8fb5ff",
          "muted": "#D5E0FF"
        },
        "danger": {
          "full": "#ee6c4d",
          "light": "#f5a592",
          "muted": "#efb9ab",
          "dark": "#cc3333",
        },
        "success": {
          "full": "#25a75c",
          "light": "#7bc99c",
          "muted": "#A2D7B1",
          "dark": "#009933",
        },
        "link": {
          "base": "#4786ff",
          "hovered": "#3971DB",
          "disabled": "#4786ff"
        },
        "buttons": {
          "default": {
            "background": {
              "full": "none",
              "light": "none",
              "muted": "none",
              "dark": "none",
            },
            "color": {
              "full": "#656d78",
              "light": "#aab2bd",
              "muted": "#e9ecef",
              "dark": "#333333",
            },
            "border": {
              "full": "#656d78",
              "light": "#aab2bd",
              "muted": "#e9ecef",
              "dark": "#333333",
            }
          },
          "primary": {
            "background": {
            "full": "#FF6600",
            "light": "#FF9966",
            "muted": "#FFCC99",
            "dark": "#EB4008",
            },
            "color": {
              "full": "#fff",
              "light": "#fff",
              "muted": "#fff",
              "dark": "#fff",
            },
            "border": {
            "full": "#FF6600",
            "light": "#FF9966",
            "muted": "#FFCC99",
            "dark": "#EB4008",
            }
          },
          "success": {
            "background": {
              "full": "#25a75c",
              "light": "#7bc99c",
              "muted": "#A2D7B1",
              "dark": "#009933",
            },
            "color": {
              "full": "#fff",
              "light": "#fff",
              "muted": "#fff",
              "dark": "#fff",
            },
            "border": {
              "full": "#25a75c",
              "light": "#7bc99c",
              "muted": "#A2D7B1",
              "dark": "#009933",
            }
          },
          "danger": {
            "background": {
              "full": "#ee6c4d",
              "light": "#f5a592",
              "muted": "#efb9ab",
              "dark": "#cc3333",
            },
            "color": {
              "full": "#fff",
              "light": "#fff",
              "muted": "#fff",
              "dark": "#fff",
            },
            "border": {
              "full": "#ee6c4d",
              "light": "#f5a592",
              "muted": "#efb9ab",
              "dark": "#cc3333",
            }
          },
        }
      },
      "widgets": {
        "background": {
          "full": "white",
          "light": "white",
          "dark": "#dee2e6",
          "hover": "#e6e6e6",
        },
        "color": {
          "full": "#666666",
          "light": "#00000073",
          "dark": "#272727",
          "contrast": 'white',
          "hover": "#272727",
        },
        "border": {
          "border": {
            "full": "1px solid #cdd4da",
            "light": "1px solid #cdd4da",
            "dark": "1px solid black",
          },
          "color": "#cdd4da",
          "boxShadow": "1px 1px 2px #cdd4da",
        },
      },
      "textShadow": {
          "white": "0px 1px 0px rgba(255,255,255,0.5)",
          "black": "0px 1px 0px rgba(33,37,41,0.28)",
          "whiteFocused": "0px -1px 0px rgba(255, 255, 255, 0.5)",
          "blackFocused": "0px -1px 0px rgba(33, 37, 41, 0.28)"
        }
      };
    }
    else if (mode === 'dark') {
      return {
        "palette": {
          "neutral": {
            "neutral-0": "#333333",
            "neutral-100": "#f5f7fa",
            "neutral-200": "#e9ecef",
            "neutral-300": "#ccd1d9",
            "neutral-400": "#aab2bd",
            "neutral-500": "#656d78",
            "neutral-600": "#434a54",
            "neutral-700": "#3d3b3d",
            "neutral-800": "#333333",
            "neutral-900": "#212529",
            "neutral-1000": "#000"
          },
          "primary": {
            "full": "#FF6600",
            "light": "black",
            "muted": "black",
            "dark": "black",
            "boxShadow": "0 0 0 2px #FF6600",
            "background": "#18191a"
          },
          "secondary": {
            "full": "#4786ff",
            "light": "#8fb5ff",
            "muted": "#D5E0FF"
          },
          "danger": {
            "full": "#ee6c4d",
            "light": "#f5a592",
            "muted": "#efb9ab",
            "dark": "#cc3333",
          },
          "success": {
            "full": "#0de267",
            "light": "#7bc99c",
            "muted": "#A2D7B1",
            "dark": "#009933",
          },
          "link": {
            "base": "#4786ff",
            "hovered": "#3971DB",
            "disabled": "#4786ff"
          },
          "buttons": {
            "default": {
              "background": {
                "full": "none",
                "light": "none",
                "muted": "none",
                "dark": "none",
              },
              "color": {
                "full": "#656d78",
                "light": "#aab2bd",
                "muted": "#e9ecef",
                "dark": "#333333",
              },
              "border": {
                "full": "#656d78",
                "light": "#aab2bd",
                "muted": "#e9ecef",
                "dark": "#333333",
              }
            },
            "primary": {
              "background": {
              "full": "#FF6600",
              "light": "#FF9966",
              "muted": "#FFCC99",
              "dark": "#EB4008",
              },
              "color": {
                "full": "#fff",
                "light": "#fff",
                "muted": "#fff",
                "dark": "#fff",
              },
              "border": {
              "full": "#FF6600",
              "light": "#FF9966",
              "muted": "#FFCC99",
              "dark": "#EB4008",
              }
            },
            "success": {
              "background": {
                "full": "#25a75c",
                "light": "#7bc99c",
                "muted": "#A2D7B1",
                "dark": "#009933",
              },
              "color": {
                "full": "#fff",
                "light": "#fff",
                "muted": "#fff",
                "dark": "#fff",
              },
              "border": {
                "full": "#25a75c",
                "light": "#7bc99c",
                "muted": "#A2D7B1",
                "dark": "#009933",
              }
            },
            "danger": {
              "background": {
                "full": "#ee6c4d",
                "light": "#f5a592",
                "muted": "#efb9ab",
                "dark": "#cc3333",
              },
              "color": {
                "full": "#fff",
                "light": "#fff",
                "muted": "#fff",
                "dark": "#fff",
              },
              "border": {
                "full": "#ee6c4d",
                "light": "#f5a592",
                "muted": "#efb9ab",
                "dark": "#cc3333",
              }
            },
          }
        },
        "widgets": {
          "background": {
            "full": "#373737",
            "light": "#4e4e4e",
            "dark": "#272727",
            "hover": "#4e4e4e",
          },
          "color": {
            "full": "#d2d2d2",
            "light": "white",
            "contrast": 'black',
            "hover": 'black'
          },
          "border": {
            "border": {
              "full": "1px solid #18191a",
              "light": "none",
              "dark": "1px solid black",
            },
            "boxShadow": "none",
            "color": "#272727"
          },
        },
        "textShadow": {
            "white": "0px 1px 0px rgba(255,255,255,0.5)",
            "black": "0px 1px 0px rgba(33,37,41,0.28)",
            "whiteFocused": "0px -1px 0px rgba(255, 255, 255, 0.5)",
            "blackFocused": "0px -1px 0px rgba(33, 37, 41, 0.28)"
          }
        };
      }
  }

export default theme;

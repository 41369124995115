import { Modal } from 'antd';

import { Button } from '@flogistix/flo-ui';

import './ConfirmTemplateModal.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { TEMPLATE_CONFIRMATION_MODAL_TITLE } from 'single-asset/constants/telemetryConstants';

const ConfirmTemplateModal = ({
  isOpen,
  onSubmit,
  closeModal,
}) => (
  <Modal
    centered
    footer={[]}
    open={isOpen}
    title={TEMPLATE_CONFIRMATION_MODAL_TITLE}
    onCancel={closeModal}
    className="confirm-modal"
    wrapClassName="confirm-modal-dialog"
    closeIcon={<IoCloseOutline size={24} />}
  >
    <div className="button-group">
      <Button
        fixedWidth="49.5%"
        onClick={() => onSubmit('driver')}
      >
        Apply to units of this application and driver type
      </Button>
      <Button
        fixedWidth="49.5%"
        onClick={() => onSubmit('applicationType')}
      >
        Apply to units of this application type
      </Button>
    </div>
    <Button
      fixedWidth="100%"
      className="global-template"
      onClick={() => onSubmit('global')}
      variation="gray"
    >
      Apply to ALL units
    </Button>
    <button onClick={closeModal} className="cancel-button">Cancel</button>
  </Modal>
);

export default ConfirmTemplateModal;

/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  Pill,
  DateRangePicker,
  MultiFiltrationDropdown,
} from '@flogistix/flo-ui';

import FluxTooltip from 'shared/components/FluxTooltip';

import {
  getEndDate,
  getStartDate,
  getOrgIdValues,
  getAreaIdValues,
  getSiteIdValues,
  removeDateParameters,
} from 'dashboard/helpers/dashboardHelpers';

import { selectSession } from 'shared/reducers/sessionReducer';
import { parameterizeDate } from 'shared/helpers/emissionsHelpers';
import { getUniqueValuesWithLabel } from 'shared/helpers/unitHelpers';
import { fetchFilterAreas } from 'shared/repositories/filterAreasRepository';
import { IEmissionData } from 'shared/constants/emissionsCalculatorConstants';
import { getFleetEmissionDetails } from 'shared/repositories/emissionsCalculatorRepository';

import { IDropdownOption } from 'emissions/pages/EmissionsPage';

import './EmissionsFleetView.scss';

const today = new Date();
const pastDate = new Date();
pastDate.setDate(today.getDate() - 28);

interface EmissionsFleetViewProps { units: any[]; }

const EmissionsFleetView: React.FC<EmissionsFleetViewProps> = ({ units }) => {
  const session = useSelector(selectSession);

  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const currentUrl = `${pathname}${search}`;
  const newUrl = new URL(currentUrl, window.location.origin);

  const [emissionsDataPending, setEmissionsDataPending] = useState(false);
  const [emissionsData, setEmissionsData] = useState<IEmissionData | null>(null);

  const [endDate, setEndDate] = useState<Date>(today);
  const [startDate, setStartDate] = useState<Date>(pastDate);

  const [orgSearchString, setOrgSearchString] = useState('');
  const [orgDropdownOptions, setOrgDropdownOptions] = useState<IDropdownOption[]>([]);
  const [selectedOrgOptions, setSelectedOrgOptions] = useState<IDropdownOption[]>([]);

  const [areaSearchString, setAreaSearchString] = useState('');
  const [areaDropdownOptions, setAreaDropdownOptions] = useState<IDropdownOption[]>([]);
  const [selectedAreaOptions, setSelectedAreaOptions] = useState<IDropdownOption[]>([]);

  const [siteSearchString, setSiteSearchString] = useState('');
  const [siteDropdownOptions, setSiteDropdownOptions] = useState<IDropdownOption[]>([]);
  const [selectedSiteOptions, setSelectedSiteOptions] = useState<IDropdownOption[]>([]);

  const handleEmissionsDates = (newStartDate: Date | null, newEndDate: Date | null) => {
    if (!newStartDate || !newEndDate) {
      return;
    }

    const parameterizedEndDate = parameterizeDate(newEndDate as Date);
    const parameterizedStartDate = parameterizeDate(newStartDate as Date);

    const url = new URL(currentUrl, window.location.origin);
    url.searchParams.set('end_date', parameterizedEndDate);
    url.searchParams.set('start_date', parameterizedStartDate);
    navigate(url.pathname + url.search);
  };

  const handleEndDate = (newEndDate: Date | null) => {
    setEndDate(newEndDate as Date);
  };

  const handleStartDate = (newStartDate: Date | null) => {
    setStartDate(newStartDate as Date);
  };

  const clearDates = () => {
    setEndDate(today);
    setStartDate(pastDate);

    const datelessUrl = removeDateParameters(currentUrl);
    navigate(datelessUrl);
  };

  const handleOrgFilter = (selectedOption: IDropdownOption) => {
    newUrl.searchParams.append('org_id', selectedOption?.value?.toString());
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const handleAreaFilter = (selectedOption: IDropdownOption) => {
    newUrl.searchParams.append('group_id', selectedOption?.value?.toString());
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const handleSiteFilter = (selectedOption: IDropdownOption) => {
    newUrl.searchParams.append('site_id', selectedOption?.value?.toString());
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const removeOrgParam = (selectOrgOption: IDropdownOption) => {
    const orgIds = newUrl.searchParams.getAll('org_id');
    const updatedOrgIds = orgIds.filter((orgId) => orgId !== selectOrgOption.value?.toString());

    newUrl.searchParams.delete('org_id');
    updatedOrgIds.forEach((orgId) => newUrl.searchParams.append('org_id', orgId));
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const removeOrgParamById = (orgIdToRemove: string) => {
    const orgIds = newUrl.searchParams.getAll('org_id');
    const updatedOrgIds = orgIds.filter((orgId) => orgId !== orgIdToRemove);

    newUrl.searchParams.delete('org_id');
    updatedOrgIds.forEach((orgId) => newUrl.searchParams.append('org_id', orgId));
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const removeAreaParamById = (areaToRemove: string) => {
    const areaIds = newUrl.searchParams.getAll('group_id');
    const updatedAreaIds = areaIds.filter((areaId) => areaId !== areaToRemove);

    newUrl.searchParams.delete('group_id');
    updatedAreaIds.forEach((areaId) => newUrl.searchParams.append('group_id', areaId));
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const removeSiteParamById = (siteToRemove: string) => {
    const siteIds = newUrl.searchParams.getAll('site_id');
    const updatedSiteIds = siteIds.filter((siteId) => siteId !== siteToRemove);

    newUrl.searchParams.delete('site_id');
    updatedSiteIds.forEach((siteId) => newUrl.searchParams.append('site_id', siteId));
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const removeAreaParam = (selectOrgOption: IDropdownOption) => {
    const areaIds = newUrl.searchParams.getAll('group_id');
    const updatedAreaIds = areaIds.filter((areaId) => areaId !== selectOrgOption.value?.toString());

    newUrl.searchParams.delete('group_id');
    updatedAreaIds.forEach((areaId) => newUrl.searchParams.append('group_id', areaId));
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const removeSiteParam = (selectOrgOption: IDropdownOption) => {
    const siteIds = newUrl.searchParams.getAll('site_id');
    const updatedSiteIds = siteIds.filter((siteId) => siteId !== selectOrgOption.value?.toString());

    newUrl.searchParams.delete('site_id');
    updatedSiteIds.forEach((siteId) => newUrl.searchParams.append('site_id', siteId));
    navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`);
  };

  const removeUrlFilters = () => {
    const basePath = pathname.split('/emissions')[0];
    const updatedUrl = `${basePath}/emissions`;

    navigate(updatedUrl);
  };

  const generateDropdowns = () => {
    const uniqueOrgNames = getUniqueValuesWithLabel('org_name', units);
    const uniqueSiteNames = getUniqueValuesWithLabel('site_name', units);

    const formattedOrgOptions = uniqueOrgNames?.map((entry) => ({
      label: entry?.label,
      value: entry?.org_id,
    }));

    const formattedSiteOptions = uniqueSiteNames?.map((entry) => ({
      label: entry?.label,
      value: entry?.site_id,
    }));

    setOrgDropdownOptions(formattedOrgOptions);
    setSiteDropdownOptions(formattedSiteOptions);
  };

  const getFilterAreas = async () => {
    try {
      const response = await fetchFilterAreas({ accessToken: session?.token });
      const responseData = await response.json();

      setAreaDropdownOptions(responseData?.sort((a: IDropdownOption, b: IDropdownOption) => a.label.localeCompare(b.label)));
    } catch (error) {
      console.error('Error fetching filter areas:', error);
    }
  };

  const getEmissionDetails = async (
    filters = [''],
    newEndDate = endDate,
    newStartDate = startDate,
  ) => {
    const formattedEndDate = parameterizeDate(newEndDate);
    const formattedStartDate = parameterizeDate(newStartDate);

    setEmissionsData(null);
    setEmissionsDataPending(true);

    try {
      const response = await getFleetEmissionDetails({
        filters,
        endDate: formattedEndDate,
        accessToken: session?.token,
        startDate: formattedStartDate,
      });

      if (!response?.ok) {
        throw new Error(`Fetch failed with status: ${response?.status}`);
      }

      const responseData = await response.json();
      setEmissionsData(responseData);
    } catch (error) {
      console.error('Error fetching emissions data:', error);
    } finally {
      setEmissionsDataPending(false);
    }
  };

  const getDisplayedText = () => {
    if (emissionsDataPending) {
      return 'Loading';
    }

    const currentNetAbated = (
      Number(emissionsData?.flared?.co2e?.total || 0)
    + Number(emissionsData?.methaneVented || 0)
    - Number(emissionsData?.driveEmissions || 0));

    return (emissionsData ? currentNetAbated?.toFixed(4) : 'Data Error');
  };

  const currentDisplayedText = getDisplayedText();

  useEffect(() => {
    session?.token && getFilterAreas();
    units?.length && generateDropdowns();
  }, [units, session]);

  useEffect(() => {
    if (!search?.length) {
      setSelectedOrgOptions([]);
      setSelectedAreaOptions([]);
      setSelectedSiteOptions([]);

      setEndDate(today);
      setStartDate(pastDate);
      return;
    }

    const startDateParam = getStartDate(search);
    startDateParam ? setStartDate(new Date(startDateParam)) : setStartDate(pastDate);

    const endDateParam = getEndDate(search);
    endDateParam ? setEndDate(new Date(endDateParam)) : setEndDate(today);

    const paramOrgIds = getOrgIdValues(search);
    const paramSiteIds = getSiteIdValues(search);
    const paramAreaIds = getAreaIdValues(search);

    if (paramOrgIds?.length) {
      const unitWithOrgInfo = paramOrgIds?.map((entry) => units?.find((unit) => Number(unit?.org_id) === Number(entry)));
      const currentOrgOptions: IDropdownOption[] = unitWithOrgInfo?.map((unit) => ({ label: unit?.org_name, value: unit?.org_id }));

      currentOrgOptions && setSelectedOrgOptions(currentOrgOptions);
    } else setSelectedOrgOptions([]);

    if (paramAreaIds?.length) {
      const currentAreaOptions = paramAreaIds?.map((entry) => areaDropdownOptions?.find((option) => Number(option?.value) === Number(entry)));

      currentAreaOptions && setSelectedAreaOptions(currentAreaOptions as IDropdownOption[]);
    } else setSelectedAreaOptions([]);

    if (paramSiteIds?.length) {
      const unitWithSiteInfo = paramSiteIds?.map((entry) => units?.find((unit) => Number(unit?.site_id) === Number(entry)));
      const currentSiteOptions: IDropdownOption[] = unitWithSiteInfo?.map((unit) => ({ label: unit?.site_name, value: unit?.site_id }));

      currentSiteOptions && setSelectedSiteOptions(currentSiteOptions);
    } else setSelectedSiteOptions([]);
  }, [search, units, areaDropdownOptions]);

  useEffect(() => {
    if (session?.token) {
      const newFilters = [''];

      const orgIds = newUrl.searchParams.getAll('org_id');
      const siteIds = newUrl.searchParams.getAll('site_id');
      const areaIds = newUrl.searchParams.getAll('group_id');

      if (orgIds.length) {
        const orgFilterString = orgIds.map((orgId) => `&org_id=${orgId}`).join('');
        newFilters.push(orgFilterString);
      }

      if (areaIds.length) {
        const areaFilterString = areaIds.map((areaId) => `&group_id=${areaId}`).join('');
        newFilters.push(areaFilterString);
      }

      if (siteIds.length) {
        const siteFilterString = siteIds.map((siteId) => `&site_id=${siteId}`).join('');
        newFilters.push(siteFilterString);
      }
      getEmissionDetails(newFilters, endDate, startDate);
    }
  }, [session, startDate, endDate, selectedOrgOptions, selectedAreaOptions, selectedSiteOptions]);

  return (
    <section className="emissions-page-fleet-view">
      <div className="fleet-view-inputs">
        <DateRangePicker
          onClear={clearDates}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
          setSelectedEndDate={handleEndDate}
          setSelectedStartDate={handleStartDate}
          onSelectionChange={handleEmissionsDates}
        />
        <MultiFiltrationDropdown
          width="small"
          placeholder="Customers"
          searchPlaceholder="Search"
          values={selectedOrgOptions}
          options={orgDropdownOptions}
          searchValue={orgSearchString}
          onSelect={(option) => handleOrgFilter(option)}
          onDeselect={(option) => removeOrgParam(option)}
        />
        <MultiFiltrationDropdown
          width="small"
          placeholder="Areas"
          searchPlaceholder="Search"
          values={selectedAreaOptions}
          options={areaDropdownOptions}
          searchValue={areaSearchString}
          onSelect={(option) => handleAreaFilter(option)}
          onDeselect={(option) => removeAreaParam(option)}
        />
        <MultiFiltrationDropdown
          width="small"
          placeholder="Sites"
          searchPlaceholder="Search"
          values={selectedSiteOptions}
          options={siteDropdownOptions}
          searchValue={siteSearchString}
          onSelect={(option) => handleSiteFilter(option)}
          onDeselect={(option) => removeSiteParam(option)}
        />
        {selectedOrgOptions?.length || selectedSiteOptions?.length || selectedAreaOptions?.length || (endDate !== today && startDate !== pastDate)
          ? (
            <Button variation="ghost" onClick={removeUrlFilters}>
              Clear all
            </Button>
          )
          : <span />}
      </div>
      <div className="selected-filter-pills-container">
        {selectedOrgOptions?.length
          ? selectedOrgOptions?.map((entry) => (
            <Pill
              variation="filter"
              label={`Customer - ${entry?.label?.toString()}`}
              onRemove={() => removeOrgParamById(entry?.value?.toString())}
            />
          ))
          : <span />}
        {selectedAreaOptions?.length
          ? selectedAreaOptions?.map((entry) => (
            <Pill
              variation="filter"
              label={`Area - ${entry?.label?.toString()}`}
              onRemove={() => removeAreaParamById(entry?.value?.toString())}
            />
          ))
          : <span />}
        {selectedSiteOptions?.length
          ? selectedSiteOptions?.map((entry) => (
            <Pill
              variation="filter"
              label={`Site - ${entry?.label?.toString()}`}
              onRemove={() => removeSiteParamById(entry?.value?.toString())}
            />
          ))
          : <span />}
      </div>
      <div className="fleet-view-total-card">
        <section className="fleet-view-total-card-header">
          <p className="fleet-view-total-card-header-text">
            Total Emissions Abated (Metric Tons CO2e)
          </p>
          <FluxTooltip
            tooltip={null}
            circleSize="20px"
            circleColor="#101828"
            title="Total Emissions Abated"
            description="The total volume of all gas captured by vapor recovery."
          />
        </section>
        <p className="fleet-view-total-card-value">
          {currentDisplayedText}
        </p>
      </div>
    </section>
  );
};

export default EmissionsFleetView;

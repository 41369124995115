import { Switch } from 'antd';

import FluxTooltip from 'shared/components/FluxTooltip';

import './ToggleButton.scss';

const ToggleButton = ({
  title = '',
  tooltip = {},
  showTooltip = true,
  ...otherProps
}) => (
  <div className="toggle-button">
    {
      showTooltip && (
        <FluxTooltip
          {...tooltip}
        />
      )
    }
    {
      title.length > 0 && (
        <p className="toggle-label">{title}</p>
      )
    }
    <Switch
      {...otherProps}
      data-testid="flux-toggle-switch"
    />
  </div>
);

export default ToggleButton;

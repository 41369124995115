import { useEffect, useState } from 'react';

import { applyFilters, getFilteredAssetList } from 'shared/helpers/assetHelpers';
import { assetFieldNames } from 'shared/constants/altitudeConstants';
import FluxVirtualizedTable from 'shared/components/flux-virtualized-table/FluxVirtualizedTable';

import {
  assetListTableColumns,
  ASSET_LIST_TABLE_ROW_HEIGHT,
} from 'dashboard/constants/dashboardConstants';
import AssetListCards from 'dashboard/components/asset-display/AssetListCards';
import AssetListSkeleton from 'dashboard/components/asset-display/AssetListSkeleton';

import './AssetList.scss';

const AssetList = ({
  disabled,
  filterText,
  units = [],
  savedFilters,
  selectUnitView,
  selectedDisplayType,
  selectedStatusFilter,
  generateLinkForTableRow,
}) => {
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const isTableView = !disabled && selectedDisplayType === 'Table';
  const isCardView = !disabled && selectedDisplayType === 'Cards';

  const savedFilterLabels = savedFilters?.map((entry) => entry?.label);

  const filterFields = [
    assetFieldNames.MODEL,
    assetFieldNames.STATUS,
    assetFieldNames.DRIVER,
    assetFieldNames.ORG_NAME,
    assetFieldNames.SITE_NAME,
    assetFieldNames.ASSET_NAME,
  ];

  useEffect(() => {
    const newFilteredAssets = getFilteredAssetList(units, selectedStatusFilter, filterText, filterFields);
    setFilteredAssets(applyFilters(newFilteredAssets, savedFilterLabels));
  }, [units, filterText, selectedStatusFilter, savedFilters.length]);

  return (
    <div className="asset-list">
      {disabled && <AssetListSkeleton />}
      {isTableView && units.length > 0
        && (
          <FluxVirtualizedTable
            data={units}
            sortConfig={sortConfig}
            setSortConfig={setSortConfig}
            filterString={filterText}
            handleRowClick={selectUnitView}
            columns={assetListTableColumns}
            savedFilters={savedFilterLabels}
            rowHeight={ASSET_LIST_TABLE_ROW_HEIGHT}
            generateLinkForTableRow={generateLinkForTableRow}
          />
        )}
      {isCardView && <AssetListCards assetList={filteredAssets} />}
    </div>
  );
};

export default AssetList;

import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';
import { Switch } from 'antd';
import React, { useState, useEffect } from 'react';

import { extendedDayJs } from 'shared/helpers/timeHelpers';
import { getOffsetDateArr } from 'shared/helpers/datePickerHelpers';
import ChartXAxisTick from 'single-asset/components/charts/ChartsXAxisTick';
import ChartsYAxisTick from 'single-asset/components/charts/ChartsYAxisTick';
import EventTooltip from 'single-asset/components/charts/chart-details/EventTooltip';
import ChartDetails from 'single-asset/components/charts/chart-details/ChartDetails';
import RightChartsYAxisTick from 'single-asset/components/charts/RightChartsYAxisTick';
import { formatXAxisPropertyToDatestring } from 'single-asset/helpers/singleAssetHelpers';
import { MONTH_DAY_YEAR, CHART_TIME_FORMAT } from 'single-asset/constants/singleAssetConstants';

import './ChartCard.scss';

const ChartCard = ({
  width,
  index,
  getCharts,
  chartData,
  unitChartConfig,
  displayGridView,
  chartLayout,
}) => {
  const xAxisPropertyName = 'x';
  const formattedIndex = index + 1;
  const formattedDisplayedIndex = formattedIndex <= 9 ? `0${formattedIndex}` : formattedIndex;
  const formattedChartData = formatXAxisPropertyToDatestring(chartData?.data, xAxisPropertyName);

  const [chartStats, setChartStats] = useState([]);
  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');
  const [domainState, setDomainState] = useState(null);
  const [chartEventData, setChartEventData] = useState({});
  const [showMultipleYAxis, setShowMultipleYAxis] = useState(false);

  const setDomain = () => {
    let maxItem = 0;
    let lowItem = 0;

    formattedChartData?.forEach((item) => {
      const max = Math.max(...Object.values(item).filter((val) => typeof val === 'number'));
      const low = Math.min(...Object.values(item).filter((val) => typeof val === 'number'));
      if (max > maxItem) maxItem = max;
      if (low < lowItem) lowItem = low;
    });

    setDomainState([lowItem, maxItem]);
  };

  const handleChartZoom = () => {
    if (refAreaLeft && refAreaRight) {
      const newChartDates = extendedDayJs(refAreaLeft).isBefore(extendedDayJs(refAreaRight))
        ? [refAreaLeft, refAreaRight]
        : [refAreaRight, refAreaLeft];
      const offsetTimestamps = getOffsetDateArr(newChartDates);
      getCharts({ dates: offsetTimestamps });
    }
    setRefAreaLeft('');
    setRefAreaRight('');
  };

  const toggleMultipleYAxis = () => {
    setShowMultipleYAxis(!showMultipleYAxis);
  };

  useEffect(() => {
    if (!domainState) setDomain();
  }, [domainState]);

  return domainState?.length && (
    <div className="chart-card" style={{ width }}>
      <div className="chart-card-header">
        <h1 className="chart-card-index">
          {formattedDisplayedIndex}
        </h1>
        <div className="date-time">
          <p className="date">{extendedDayJs(chartStats[0]?.payload?.x).format(MONTH_DAY_YEAR)}</p>
          <p className="time">{extendedDayJs(chartStats[0]?.payload?.x).format(CHART_TIME_FORMAT)}</p>
        </div>
        <div className="toggles-container">
          {chartData && Object.keys(chartData?.agg)?.length > 1
            && (
              <div className="chart-info-toggle">
                <p>Multiple Y-Axis</p>
                <Switch onChange={toggleMultipleYAxis} />
              </div>
            )}
        </div>
      </div>
      <ChartDetails
        chartStats={chartStats}
        chartData={chartData}
        chartLayout={chartLayout}
        aggregateData={chartData?.agg}
        unitChartConfig={unitChartConfig}
        displayGridView={displayGridView}
      />
      <div className="chart-graph-container">
        <ResponsiveContainer width="102%" height={300}>
          <LineChart
            data={formattedChartData}
            onMouseUp={handleChartZoom}
            margin={{
              top: 5, left: 0, bottom: 5, right: 20,
            }}
            onMouseDown={(state) => {
              setRefAreaLeft(state?.activeLabel);
            }}
            onMouseMove={(state) => {
              setChartStats(state?.activePayload ?? []);
              refAreaLeft && setRefAreaRight(state?.activeLabel);
              state?.activePayload?.[2]?.payload?.eventMessage?.eventMessage
                ? setChartEventData(state?.activePayload?.[2]?.payload)
                : setChartEventData(null);
            }}
          >
            <CartesianGrid
              vertical={false}
              strokeDasharray="3 3"
            />
            <XAxis
              height={40}
              tickLine={false}
              interval="preserveEnd"
              tick={<ChartXAxisTick />}
              dataKey={xAxisPropertyName}
            />
            <YAxis
              hide
              key="event"
              includeHidden
              yAxisId="event"
              domain={[0, 10]}
            />
            {chartLayout.chartValues.map((chart, i) => (
              <YAxis
                tickLine={false}
                yAxisId={chart?.label}
                key={chart?.tag?.toString()}
                hide={!(showMultipleYAxis || i === 0)}
                orientation={i === 0 ? 'left' : 'right'}
                domain={!showMultipleYAxis && domainState}
                axisLine={{
                  strokeWidth: 3,
                  stroke: chart?.seriesProps?.lineColor,
                }}
                tick={i === 0
                  ? <ChartsYAxisTick stroke={chart?.color} />
                  : <RightChartsYAxisTick x={i * 10} stroke={chart?.color} />}
              />
            ))}
            {chartLayout.chartValues.map((chart) => (
              <Line
                dot={false}
                connectNulls
                type="linear"
                strokeWidth={2}
                name={chart?.label}
                domain={domainState}
                yAxisId={chart?.label}
                dataKey={chart?.label}
                key={chart?.tag?.toString()}
                stroke={chart?.color}
              />
            ))}
            {refAreaLeft && refAreaRight && (
              <ReferenceArea
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
                yAxisId={chartLayout.chartValues.at(0).label}
              />
            )}
            <Tooltip
              wrapperStyle={{ visibility: 'visible' }}
              cursor={{ visibility: 'default', stroke: '#BCBCBC', strokeWidth: 2 }}
              content={chartEventData?.eventMessage
                ? <EventTooltip eventDataEntry={chartEventData} />
                : <div />}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ChartCard;

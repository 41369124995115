const defaultDriverLayouts = {
  GED: {
    id: 'defaultGED',
    charts: [
      {
        order: 0,
        title: 'Tank Vapor Pressure',
        chartValues: [
          {
            key: 'tank_vapor_pressure',
            tag: 15,
            color: '#0A84FF',
            label: 'Tank Pressure',
          },
          {
            key: 'register_tag__tank_vapor_loading_set-point',
            tag: 1677,
            color: '#00C278',
            label: 'Tank Pressure Setpoint',
          },
        ],
      },
      {
        order: 1,
        title: 'Suction Pressure',
        chartValues: [
          {
            key: 'suction_pressure',
            tag: 16,
            color: '#0A84FF',
            label: 'Suction Pressure',
          },
          {
            key: 'register_tag__suction_loading_set-point',
            tag: 1676,
            color: '#00C278',
            label: 'Suction Pressure Setpoint',
          },
        ],
      },
      {
        order: 2,
        title: 'Max Flow - Virtual Flow Rate',
        chartValues: [
          {
            key: '_flogistix_virtual_flow__return_flow_mscfd_with_mod_max',
            tag: 1716,
            color: '#0A84FF',
            label: 'Max Flow Capacity',
          },
          {
            key: '_flogistix_virtual_flow__return_flow_mscfd_with_mod',
            tag: 1693,
            color: '#00C278',
            label: 'Virtual Flow Rate',
          },
        ],
      },
      {
        order: 3,
        title: 'Recycle Valve Status',
        chartValues: [
          {
            color: '#0A84FF',
            key: 'register_tag__bypass_valve_output_status',
            label: 'Recycle Valve Status',
            tag: 897,
          },
        ],
      },
      {
        order: 4,
        title: 'Engine Speed',
        chartValues: [
          {
            key: 'engine_speed',
            tag: 908,
            color: '#0A84FF',
            label: 'Engine Speed',
          },
          {
            key: 'register_tag__max_engine_speed_set-point',
            tag: 1708,
            color: '#00C278',
            label: 'Max Engine Speed Setpoint',
          },
        ],
      },
      {
        order: 5,
        title: 'Compressor Oil Injection Pressure - Discharge Pressure',
        chartValues: [
          {
            key: 'register_tag__compressor_oil_injection_pressure',
            tag: 893,
            color: '#0A84FF',
            label: 'Compressor Oil Injection Pressure',
          },
          {
            key: 'discharge_pressure',
            tag: 7,
            color: '#00C278',
            label: 'Discharge Pressure',
          },
        ],
      },
      {
        order: 6,
        title: 'Discharge Temp - Engine Jacket Water Temp',
        chartValues: [
          {
            key: 'register_tag__discharge_temperature',
            tag: 869,
            color: '#0A84FF',
            label: 'Discharge Temp',
          },
          {
            key: 'register_tag__engine_jacket_water_temperature',
            tag: 617,
            color: '#00C278',
            label: 'Engine Jacket Water Temp',
          },
        ],
      },
      {
        order: 7,
        title: 'Engine Manifold Pressure',
        chartValues: [
          {
            key: 'register_tag__engine_manifold_pressure',
            tag: 607,
            color: '#0A84FF',
            label: 'Engine Manifold Pressure',
          },
        ],
      },
      {
        order: 8,
        title: 'Engine Exhaust O2 Sensor Voltage - AFRC Valve % Open Reference Signal',
        chartValues: [
          {
            key: 'register_tag__engine_exhaust_o2_voltage',
            tag: 598,
            color: '#0A84FF',
            label: 'Engine Exhaust 02 Sensor Voltage',
          },
          {
            key: 'register_tag__afrc_valve_position_percentage',
            tag: 896,
            color: '#00C278',
            label: 'AFRC Valve % Open Reference Signal',
          },
        ],
      },
      {
        order: 9,
        title: 'Fuel Burn MSCFD - Virtual Flow Demand HP',
        chartValues: [
          {
            key: '_flogistix_virtual_flow__fuel_burn_mscfd',
            tag: 1745,
            color: '#0A84FF',
            label: 'Fuel Burn MSCFD',
          },
          {
            key: '_flogistix_virtual_flow__hp_with_mod',
            tag: 1694,
            color: '#00C278',
            label: 'Virtual Flow Demand HP',
          },
        ],
      },
    ],
  },
  GLU: {
    id: 'defaultGLU',
    charts: [
      {
        order: 0,
        title: 'Tank Vapor Pressure',
        chartValues: [
          {
            key: 'tank_vapor_pressure',
            tag: 15,
            color: '#0A84FF',
            label: 'Tank Pressure',
          },
          {
            key: 'register_tag__tank_vapor_loading_set-point',
            tag: 1677,
            color: '#00C278',
            label: 'Tank Pressure Setpoint',
          },
        ],
      },
      {
        order: 1,
        title: 'Suction Pressure',
        chartValues: [
          {
            key: 'suction_pressure',
            tag: 16,
            color: '#0A84FF',
            label: 'Suction Pressure',
          },
          {
            key: 'register_tag__suction_loading_set-point',
            tag: 1676,
            color: '#00C278',
            label: 'Suction Pressure Setpoint',
          },
        ],
      },
      {
        order: 2,
        title: 'Max Flow - Virtual Flow Rate',
        chartValues: [
          {
            key: '_flogistix_virtual_flow__return_flow_mscfd_with_mod_max',
            tag: 1716,
            color: '#0A84FF',
            label: 'Max Flow Capacity',
          },
          {
            key: '_flogistix_virtual_flow__return_flow_mscfd_with_mod',
            tag: 1693,
            color: '#00C278',
            label: 'Virtual Flow Rate',
          },
        ],
      },
      {
        order: 3,
        title: 'Recycle Valve Status',
        chartValues: [
          {
            color: '#0A84FF',
            key: 'register_tag__bypass_valve_output_status',
            label: 'Recycle Valve Status',
            tag: 897,
          },
        ],
      },
      {
        order: 4,
        title: 'Engine Speed',
        chartValues: [
          {
            key: 'engine_speed',
            tag: 908,
            color: '#0A84FF',
            label: 'Engine Speed',
          },
          {
            key: 'register_tag__max_engine_speed_set-point',
            tag: 1708,
            color: '#00C278',
            label: 'Max Engine Speed Setpoint',
          },
        ],
      },
      {
        order: 5,
        title: 'Compressor Oil Injection Pressure - Discharge Pressure',
        chartValues: [
          {
            key: 'register_tag__compressor_oil_injection_pressure',
            tag: 893,
            color: '#0A84FF',
            label: 'Compressor Oil Injection Pressure',
          },
          {
            key: 'discharge_pressure',
            tag: 7,
            color: '#00C278',
            label: 'Discharge Pressure',
          },
        ],
      },
      {
        order: 6,
        title: 'Discharge Temp - Engine Jacket Water Temp',
        chartValues: [
          {
            key: 'register_tag__discharge_temperature',
            tag: 869,
            color: '#0A84FF',
            label: 'Discharge Temp',
          },
          {
            key: 'register_tag__engine_jacket_water_temperature',
            tag: 617,
            color: '#00C278',
            label: 'Engine Jacket Water Temp',
          },
        ],
      },
      {
        order: 7,
        title: 'Engine Manifold Pressure',
        chartValues: [
          {
            key: 'register_tag__engine_manifold_pressure',
            tag: 607,
            color: '#0A84FF',
            label: 'Engine Manifold Pressure',
          },
        ],
      },
      {
        order: 8,
        title: 'Engine Exhaust O2 Sensor Voltage - AFRC Valve % Open Reference Signal',
        chartValues: [
          {
            key: 'register_tag__engine_exhaust_o2_voltage',
            tag: 598,
            color: '#0A84FF',
            label: 'Engine Exhaust 02 Sensor Voltage',
          },
          {
            key: 'register_tag__afrc_valve_position_percentage',
            tag: 896,
            color: '#00C278',
            label: 'AFRC Valve % Open Reference Signal',
          },
        ],
      },
      {
        order: 9,
        title: 'Fuel Burn MSCFD - Virtual Flow Demand HP',
        chartValues: [
          {
            key: '_flogistix_virtual_flow__fuel_burn_mscfd',
            tag: 1745,
            color: '#0A84FF',
            label: 'Fuel Burn MSCFD',
          },
          {
            key: '_flogistix_virtual_flow__hp_with_mod',
            tag: 1694,
            color: '#00C278',
            label: 'Virtual Flow Demand HP',
          },
        ],
      },
    ],
  },
  GLX: {
    id: 'defaultGLX',
    charts: [
      {
        order: 0,
        title: 'Tank Vapor Pressure',
        chartValues: [
          {
            key: 'tank_vapor_pressure',
            tag: 15,
            color: '#0A84FF',
            label: 'Tank Pressure',
          },
          {
            key: 'register_tag__tank_vapor_loading_set-point',
            tag: 1677,
            color: '#00C278',
            label: 'Tank Pressure Setpoint',
          },
        ],
      },
      {
        order: 1,
        title: 'Suction Pressure',
        chartValues: [
          {
            key: 'suction_pressure',
            tag: 16,
            color: '#0A84FF',
            label: 'Suction Pressure',
          },
          {
            key: 'register_tag__suction_loading_set-point',
            tag: 1676,
            color: '#00C278',
            label: 'Suction Pressure Setpoint',
          },
        ],
      },
      {
        order: 2,
        title: 'Max Flow - Virtual Flow Rate MCFD',
        chartValues: [
          {
            key: '_flogistix_virtual_flow__return_flow_mscfd_with_mod_max',
            tag: 1716,
            color: '#0A84FF',
            label: 'Max Flow Capacity',
          },
          {
            key: '_flogistix_virtual_flow__return_flow_mscfd_with_mod',
            tag: 1693,
            color: '#00C278',
            label: 'Virtual Flow Rate - MCFD',
          },
        ],
      },
      {
        order: 3,
        title: 'Main VFD Actual Speed',
        chartValues: [
          {
            key: 'main_vfd_actual_speed',
            tag: 14,
            color: '#0A84FF',
            label: 'Main VFD Actual Speed',
          },
        ],
      },
      {
        order: 4,
        title: 'ELEC Recycle',
        chartValues: [
          {
            key: 'register_tag__bypass_valve_digital_output_status',
            tag: 874,
            color: '#0A84FF',
            label: 'ELEC Recycle',
          },
        ],
      },
      {
        order: 5,
        title: 'Compressor Oil Temp - Discharge Temp',
        chartValues: [
          {
            key: 'register_tag__compressor_oil_temperature',
            tag: 879,
            color: '#0A84FF',
            label: 'Compressor Oil Temp',
          },
          {
            key: 'register_tag__discharge_temperature',
            tag: 869,
            color: '#00C278',
            label: 'Discharge Temp',
          },
        ],
      },
      {
        order: 6,
        title: 'Compressor Oil Injection Pressure - Discharge Pressure',
        chartValues: [
          {
            key: 'register_tag__compressor_oil_injection_pressure',
            tag: 893,
            color: '#0A84FF',
            label: 'Compressor Oil Injection Pressure',
          },
          {
            key: 'discharge_pressure',
            tag: 7,
            color: '#00C278',
            label: 'Discharge Pressure',
          },
        ],
      },
    ],
  },
  ELEC: {
    id: 'defaultELEC',
    charts: [
      {
        order: 0,
        title: 'Tank Vapor Pressure',
        chartValues: [
          {
            key: 'tank_vapor_pressure',
            tag: 15,
            color: '#0A84FF',
            label: 'Tank Pressure',
          },
          {
            key: 'register_tag__tank_vapor_loading_set-point',
            tag: 1677,
            color: '#00C278',
            label: 'Tank Pressure Setpoint',
          },
        ],
      },
      {
        order: 1,
        title: 'Suction Pressure',
        chartValues: [
          {
            key: 'suction_pressure',
            tag: 16,
            color: '#0A84FF',
            label: 'Suction Pressure',
          },
          {
            key: 'register_tag__suction_loading_set-point',
            tag: 1676,
            color: '#00C278',
            label: 'Suction Pressure Setpoint',
          },
        ],
      },
      {
        order: 2,
        title: 'Max Flow - Virtual Flow Rate MCFD',
        chartValues: [
          {
            key: '_flogistix_virtual_flow__return_flow_mscfd_with_mod_max',
            tag: 1716,
            color: '#0A84FF',
            label: 'Max Flow Capacity',
          },
          {
            key: '_flogistix_virtual_flow__return_flow_mscfd_with_mod',
            tag: 1693,
            color: '#00C278',
            label: 'Virtual Flow Rate - MCFD',
          },
        ],
      },
      {
        order: 3,
        title: 'Main VFD Actual Speed',
        chartValues: [
          {
            key: 'main_vfd_actual_speed',
            tag: 14,
            color: '#0A84FF',
            label: 'Main VFD Actual Speed',
          },
        ],
      },
      {
        order: 4,
        title: 'ELEC Recycle',
        chartValues: [
          {
            key: 'register_tag__bypass_valve_digital_output_status',
            tag: 874,
            color: '#0A84FF',
            label: 'ELEC Recycle',
          },
        ],
      },
      {
        order: 5,
        title: 'Compressor Oil Temp - Discharge Temp',
        chartValues: [
          {
            key: 'register_tag__compressor_oil_temperature',
            tag: 879,
            color: '#0A84FF',
            label: 'Compressor Oil Temp',
          },
          {
            key: 'register_tag__discharge_temperature',
            tag: 869,
            color: '#00C278',
            label: 'Discharge Temp',
          },
        ],
      },
      {
        order: 6,
        title: 'Compressor Oil Injection Pressure - Discharge Pressure',
        chartValues: [
          {
            key: 'register_tag__compressor_oil_injection_pressure',
            tag: 893,
            color: '#0A84FF',
            label: 'Compressor Oil Injection Pressure',
          },
          {
            key: 'discharge_pressure',
            tag: 7,
            color: '#00C278',
            label: 'Discharge Pressure',
          },
        ],
      },
    ],
  },
};

export default defaultDriverLayouts;

import {
  IoCloseOutline,
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoChevronBackCircleOutline,
  IoChevronForwardCircleOutline,
} from 'react-icons/io5';
import { DatePicker } from 'antd';
import { useEffect, useState } from 'react';

import { Button } from '@flogistix/flo-ui';

import { extendedDayJs as dayjs } from 'shared/helpers/timeHelpers';
import { datePickerTypes } from 'shared/constants/graphingAndStatisticsConstants';
import { MILLISECONDS_IN_SINGLE_DAY } from 'reporting/constants/reportingConstants';

const { RangePicker } = DatePicker;

const FluxRangePicker = ({
  isOpen,
  setIsOpen,
  panelTitle,
  extraClasses,
  panelSubtitle,
  clearFunction,
  finalizedDates,
  setFinalizedDates,
  timeIsLaterThanNow,
  pickerType = datePickerTypes.RANGE,
}) => {
  const [startDate, setStartDate] = useState(dayjs(finalizedDates?.[0]) || dayjs());
  const [endDate, setEndDate] = useState(dayjs(finalizedDates?.[1]) || dayjs());
  const [displayText, setDisplayText] = useState({
    title: panelTitle,
    subtitle: panelSubtitle,
  });

  const disabledFuture = (current) => current > dayjs().endOf('day');
  const disabledSingleCal = (current) => dayjs(current).date() > 28;

  const confirmDates = () => {
    if (pickerType === datePickerTypes.RANGE && startDate && endDate) {
      const finalDates = getChosenDates();
      setFinalizedDates(finalDates);
      setIsOpen(false);
    } else if (pickerType === datePickerTypes.SINGLE && startDate) {
      setFinalizedDates([startDate, null]);
      setIsOpen(false);
    } else if (clearFunction) {
      clearFunction();
      setIsOpen(false);
    }
  };

  const getChosenDates = () => (startDate.isAfter(endDate) ? [endDate, startDate] : [startDate, endDate]);

  const resetChosenDates = () => {
    setEndDate(null);
    setStartDate(null);
  };

  const getRangeHeading = () => {
    const chosenDates = getChosenDates();
    const daysBetween = Math.ceil((dayjs(chosenDates?.[1]).diff(chosenDates?.[0]) / MILLISECONDS_IN_SINGLE_DAY) + 1);
    const firstDate = dayjs(chosenDates?.[0]).format('ddd, MMM D');
    const secondDate = dayjs(chosenDates?.[1]).format('ddd, MMM D');
    return {
      title: `${daysBetween} days`,
      subtitle: `${firstDate} - ${secondDate}`,
    };
  };

  const getSingleHeading = () => {
    const selectedDate = dayjs(startDate).format('Do');
    const nextMonth = dayjs().add(1, 'month').format('MMMM');
    const month = dayjs(startDate).date() <= dayjs().date() && !timeIsLaterThanNow
      ? nextMonth
      : dayjs(startDate).format('MMMM');
    const yearOfDate = nextMonth === 'January'
      ? dayjs(startDate).add(1, 'year').format('YYYY')
      : dayjs(startDate).format('YYYY');
    return {
      title: `${selectedDate} of every month`,
      subtitle: `Starting ${month}, ${yearOfDate}`,
    };
  };

  useEffect(() => {
    const newDisplayText = {};

    if (pickerType === datePickerTypes.RANGE && startDate && endDate) {
      const { title, subtitle } = getRangeHeading();
      newDisplayText.title = title;
      newDisplayText.subtitle = subtitle;
    } else if (pickerType === datePickerTypes.SINGLE && startDate) {
      const { title, subtitle } = getSingleHeading();
      newDisplayText.title = title;
      newDisplayText.subtitle = subtitle;
    } else {
      newDisplayText.title = panelTitle;
      newDisplayText.subtitle = panelSubtitle;
    }
    setDisplayText(newDisplayText);
  }, [startDate, endDate]);

  return (
    <RangePicker
      allowClear
      size="large"
      format="MM-DD-YYYY"
      value={[startDate, endDate]}
      popupClassName={extraClasses}
      prevIcon={<IoChevronBackOutline />}
      nextIcon={<IoChevronForwardOutline />}
      superPrevIcon={<IoChevronBackCircleOutline />}
      superNextIcon={<IoChevronForwardCircleOutline />}
      allowEmpty={pickerType === datePickerTypes.RANGE
        ? [false, false]
        : [false, true]}
      disabled={pickerType === datePickerTypes.RANGE
        ? [false, false]
        : [false, true]}
      className={pickerType === datePickerTypes.RANGE
        ? 'flux-range-picker'
        : 'single-flux-date-picker'}
      disabledDate={pickerType === datePickerTypes.RANGE
        ? disabledFuture
        : disabledSingleCal}
      onCalendarChange={(dates, _, { range }) => {
        if (range === 'start') setStartDate(dates.at(0));
        else setEndDate(dates.at(1));
      }}
      panelRender={(panelNode) => (
        <div className="calendar-panel">
          <div className="panel-heading">
            <button
              aria-label="Close"
              className="close-calendar"
              onClick={() => setIsOpen(false)}
            >
              <IoCloseOutline />
            </button>
            <p className="panel-title">{displayText.title}</p>
            <p className="panel-subtitle">{displayText.subtitle}</p>
          </div>
          {panelNode}
        </div>
      )}
      renderExtraFooter={() => (
        <div className="panel-buttons">
          <button onClick={resetChosenDates}>Clear dates</button>
          <Button onClick={confirmDates}>Save</Button>
        </div>
      )}
      open={isOpen}
    />
  );
};

export default FluxRangePicker;

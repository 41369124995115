import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  fetchOffStat,
  fetchRtpStat,
  fetchFaultedStat,
  fetchRunningStat,
  fetchNotRunningStat,
  fetchAllUnitsStat,
} from 'shared/repositories/dashboardStatsRepository';
import { selectSession } from 'shared/reducers/sessionReducer';
import { fetchUnits } from 'shared/repositories/assetsRepository';
import { fetchFilterAreas } from 'shared/repositories/filterGroupsRepository';

import DashboardPage from 'dashboard/pages/DashboardPage';
import { DEFAULT_AREA_ID } from 'dashboard/constants/dashboardConstants';

const DashboardPageContainer = ({ user }) => {
  const session = useSelector(selectSession);

  const [rtpStat, setRtpStat] = useState(null);
  const [offStat, setOffStat] = useState(null);
  const [filterAreas, setFilterAreas] = useState([]);
  const [runningStat, setRunningStat] = useState(null);
  const [faultedStat, setFaultedStat] = useState(null);
  const [allUnitsStat, setAllUnitsStat] = useState(null);
  const [notRunningStat, setNotRunningStat] = useState(null);

  const [units, setUnits] = useState([]);
  const [unitsLoading, setUnitsLoading] = useState(false);

  const [statsLoading, setStatsLoading] = useState(false);
  const [dashboardStats, setDashboardStats] = useState(null);
  const [dashboardAreaFilter, setDashboardAreaFilter] = useState(DEFAULT_AREA_ID);

  const getFilterAreas = async () => {
    try {
      const response = await fetchFilterAreas({ accessToken: session?.token });
      const responseData = await response.json();

      setFilterAreas(responseData);
    } catch (error) {
      console.error('Error fetching filter areas:', error);
    }
  };

  const fetchAndStoreStat = async (fetchFunction, setFunction, filters) => {
    setFunction(null);
    try {
      const response = await fetchFunction({
        accessToken: session?.token,
        filters,
      });
      const responseData = await response.json();
      setFunction(responseData);
    } catch (error) {
      console.error('Error fetching stat:', error);
    }
  };

  const getRuntimeStat = (filters) => fetchAndStoreStat(fetchRtpStat, setRtpStat, filters);
  const getOffUnitsStat = (filters) => fetchAndStoreStat(fetchOffStat, setOffStat, filters);
  const getAllUnitsStat = (filters) => fetchAndStoreStat(fetchAllUnitsStat, setAllUnitsStat, filters);
  const getFaultedUnitsStat = (filters) => fetchAndStoreStat(fetchFaultedStat, setFaultedStat, filters);
  const getRunningUnitsStat = (filters) => fetchAndStoreStat(fetchRunningStat, setRunningStat, filters);
  const getNotRunningUnitsStat = (filters) => fetchAndStoreStat(fetchNotRunningStat, setNotRunningStat, filters);

  const getUnits = async (filters = []) => {
    setUnitsLoading(true);

    try {
      const response = await fetchUnits({ accessToken: session?.token, body: filters });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const filteredResponseData = responseData?.filter((asset) => !asset.invalidated_at);

      setUnits(filteredResponseData);
    } catch (error) {
      console.error('Error fetching units:', error);
    } finally {
      setUnitsLoading(false);
    }
  };

  const getDashboardStats = async ({
    areaFilter = DEFAULT_AREA_ID,
    orgFilter = null,
    siteFilter = null,
  }) => {
    setStatsLoading(true);
    await Promise.all([
      getRuntimeStat({ areaFilter, orgFilter, siteFilter }),
      getOffUnitsStat({ areaFilter, orgFilter, siteFilter }),
      getAllUnitsStat({ areaFilter, orgFilter, siteFilter }),
      getFaultedUnitsStat({ areaFilter, orgFilter, siteFilter }),
      getRunningUnitsStat({ areaFilter, orgFilter, siteFilter }),
      getNotRunningUnitsStat({ areaFilter, orgFilter, siteFilter }),
    ]);
    setStatsLoading(false);
  };

  useEffect(() => {
    session?.token && getFilterAreas();
  }, [session]);

  useEffect(() => {
    if (session?.token) {
      getUnits({ filters: [dashboardAreaFilter] });
    }
  }, [dashboardAreaFilter, session]);

  useEffect(() => {
    const newTotal = (offStat?.n_assets || 0)
      + (runningStat?.n_assets || 0)
      + (faultedStat?.n_assets || 0)
      + (notRunningStat?.n_assets || 0);

    const newStats = {
      rtpStat,
      offStat,
      faultedStat,
      runningStat,
      notRunningStat,
      totalCommunicatingUnitsStat: { n_assets: newTotal },
      totalUnitsStat: { n_assets: allUnitsStat?.n_assets },
    };

    setDashboardStats(newStats);
  }, [offStat, runningStat, notRunningStat, faultedStat, rtpStat, allUnitsStat]);

  return (
    <DashboardPage
      user={user}
      units={units}
      getUnits={getUnits}
      filterAreas={filterAreas}
      unitsLoading={unitsLoading}
      statsLoading={statsLoading}
      dashboardStats={dashboardStats}
      getDashboardStats={getDashboardStats}
      dashboardAreaFilter={dashboardAreaFilter}
      setDashboardAreaFilter={setDashboardAreaFilter}
    />
  );
};

export default DashboardPageContainer;

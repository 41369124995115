import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { oneHourAgo, todayFullTime } from 'shared/helpers/datePickerHelpers';
import { extendedDayJs } from 'shared/helpers/timeHelpers';
import { selectSession } from 'shared/reducers/sessionReducer';
import ChartCard from 'single-asset/components/charts/ChartCard';

const chartConfig = {
  seriesConfig: {
    config: {
      series: [
        {
          agg: {
            max: [
              'y',
            ],
            mean: [
              'y',
            ],
            min: [
              'y',
            ],
          },
          label: 'Discharge Pressure',
          map: {
            epoch_ms: 'x',
            uom: 'uom',
            val: 'y',
          },
          seriesProps: {
            lineColor: '#0A84FF',
            type: 'line',
          },
          tag_id: 565,
        },
        {
          agg: {
            max: [
              'y',
            ],
            mean: [
              'y',
            ],
            min: [
              'y',
            ],
          },
          label: 'Suction Pressure',
          map: {
            epoch_ms: 'x',
            uom: 'uom',
            val: 'y',
          },
          seriesProps: {
            lineColor: '#00C278',
            type: 'line',
          },
          tag_id: 564,
        },
      ],
    },
  },
};

const lastHourPollingPayload = {
  start: extendedDayJs(oneHourAgo).format(),
  stop: extendedDayJs(todayFullTime).format(),
  series: [
    {
      agg: {
        max: [
          'y',
        ],
        mean: [
          'y',
        ],
        min: [
          'y',
        ],
      },
      label: 'Discharge Pressure',
      map: {
        epoch_ms: 'x',
        uom: 'uom',
        val: 'y',
      },
      seriesProps: {
        lineColor: '#0A84FF',
        type: 'line',
      },
      tag_id: 565,
      org_id: 31,
      site_id: 18887,
      asset_id: 5625,
      device_id: 5077,
    },
    {
      agg: {
        max: [
          'y',
        ],
        mean: [
          'y',
        ],
        min: [
          'y',
        ],
      },
      label: 'Suction Pressure',
      map: {
        epoch_ms: 'x',
        uom: 'uom',
        val: 'y',
      },
      seriesProps: {
        lineColor: '#00C278',
        type: 'line',
      },
      tag_id: 564,
      org_id: 31,
      site_id: 18887,
      asset_id: 5625,
      device_id: 5077,
    },
  ],
};

const PushChartComparison = () => {
  const isProd = process.env.REACT_APP_BUILD_ENV === 'production';
  const devPrefix = !isProd ? 'axildev-' : '';
  const fullUrl = `https://${!isProd ? 'dev-' : ''}unit-sensors.api.axil.ai`;
  const isLocal = process.env.REACT_APP_IS_LOCAL;
  const { token } = useSelector(selectSession);
  const [pushData, setPushData] = useState([]);
  const [pushDataLoading, setPushDataLoading] = useState(false);

  const [pollingData, setPollingData] = useState([]);
  const [pollingDataLoading, setPollingDataLoading] = useState(false);

  const adjustForTimezone = (date) => {
    const timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
  };

  const getPushData = async () => {
    setPushDataLoading(true);
    try {
      const res = await fetch(`${!isLocal ? fullUrl : ''}/v1/units/12733/sensors/journal?limit=360`);
      const data = await res.json();
      const formattedData = data.map((d) => ({
        x: adjustForTimezone(new Date(d.time)),
        'Discharge Pressure': d.dischargePressure ?? null,
        'Suction Pressure': d.suctionPressure ?? null,
      })).reverse();
      setPushData(formattedData);
    } catch (error) {
      console.error('Error fetching push data', error);
    } finally {
      setPushDataLoading(false);
    }
  };

  const getPollingData = async () => {
    setPollingDataLoading(true);
    try {
      const res = await fetch(`https://api.${devPrefix}flaxil.com/v3/axil/series/hot/by_date?label=discharge-pressure,suction-pressure`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(lastHourPollingPayload),
      });
      const data = await res.json();
      setPollingData(data);
    } catch (error) {
      console.error('Error fetching polling data', error);
    } finally {
      setPollingDataLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      !pushData.length && !pushDataLoading && getPushData();
      !pollingData.length && !pollingDataLoading && getPollingData();
    }
  }, [token]);

  return (
    <div>
      <h2>Push vs Polling charting comparison for unit 12733</h2>
      <h4>Push data</h4>
      {
        pushData?.length ? (
          <ChartCard
            index={0}
            width="98%"
            getChartsByDate={() => null}
            series={{
              ...chartConfig,
              seriesResult: {
                agg: {
                  'Discharge Pressure': {},
                  'Suction Pressure': {},
                },
                data: pushData,
              },
            }}
          />
        ) : <p>Loading push data...</p>
      }

      <h4>Polling data</h4>
      {
        pollingData.data?.length || pollingData.agg ? (
          <ChartCard
            index={1}
            width="98%"
            getChartsByDate={() => null}
            series={{
              ...chartConfig,
              seriesResult: pollingData,
            }}
          />
        ) : <p>Loading polling data... If this persists, no data was returned from server</p>
      }
    </div>
  );
};

export default PushChartComparison;

import React, { CSSProperties } from 'react';

import FluxTooltip from 'shared/components/FluxTooltip';

import './EmissionStatCard.scss';

interface EmissionStatCardProps {
  label?: string,
  value?: string,
  style?: CSSProperties,
  tooltipTitle?: string,
  tooltipDescription?: string,
}

const EmissionStatCard: React.FC<EmissionStatCardProps> = ({
  style,
  label,
  value,
  tooltipTitle,
  tooltipDescription,
}) => (
  <div className="emission-stat-card" style={style}>
    <div className="emission-stat-card-header">
      <p className="header-label">{label}</p>
      <FluxTooltip
        tooltip={null}
        title={tooltipTitle}
        circleColor="#1B1A1A"
        description={tooltipDescription}
      />
    </div>
    <p className="emission-stat-card-value">{value}</p>
  </div>
);

EmissionStatCard.defaultProps = {
  style: {},
  label: '',
  value: '',
  tooltipTitle: '',
  tooltipDescription: '',
};

export default EmissionStatCard;

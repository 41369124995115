import { selectSession } from 'shared/reducers/sessionReducer';

export const getTokenAndSelectedState = (stateProvider, selector) => {
  const state = stateProvider();

  return [
    selectSession(state)?.token,
    selector && selector(state),
  ];
};

export const asyncBatchHookActionCreator = ({
  repositoryAPI,
  responseTransformer,
  requestTransformer,
  PENDING_ACTION_TYPE,
  SUCCESS_ACTION_TYPE,
  FAILED_ACTION_TYPE,
}) => (bodyParamObjectList, additionalBodyParamObject = {}, dispatch, session) => {
  (asyncBatchActionCreator({
    repositoryAPI,
    requestTransformer,
    responseTransformer,
    PENDING_ACTION_TYPE,
    SUCCESS_ACTION_TYPE,
    FAILED_ACTION_TYPE,
  })(bodyParamObjectList, additionalBodyParamObject)(dispatch, () => ({ session })));
};

export const asyncHookActionCreator = ({
  repositoryAPI,
  PENDING_ACTION_TYPE,
  SUCCESS_ACTION_TYPE,
  FAILED_ACTION_TYPE,
  responseTransformer = null,
  requestTransformer = null,
}) => (bodyParmObject, dispatch, session) => {
  asyncActionCreator({
    repositoryAPI,
    PENDING_ACTION_TYPE,
    SUCCESS_ACTION_TYPE,
    FAILED_ACTION_TYPE,
    requestTransformer,
    responseTransformer,
  })(bodyParmObject)(dispatch, () => ({ session }));
};

export const apiRequest = async ({
  repositoryAPI,
  bodyParamObject,
  requestTransformer,
  responseTransformer,
  selectedState,
  accessToken,
  restParamObject,
}) => {
  const body = requestTransformer && requestTransformer(selectedState, bodyParamObject)
    || bodyParamObject;

  try {
    const response = await repositoryAPI({
      accessToken,
      ...(restParamObject && restParamObject),
      ...(body && { body }),
    });

    if (response.ok) {
      const result = await response.json();

      return {
        ok: true,
        ...(responseTransformer && responseTransformer(result, bodyParamObject) || { result }),
      };
    }
    throw response;
  } catch (error) {
    const {
      url, status, statusText, type,
    } = error;

    return {
      ok: false,
      error: url && status && statusText && type && {
        statusText: `${status} ${type}: ${statusText} ${url}`,
      }
      || { statusText: error.toString() },
    };
  }
};

export const asyncActionCreator = ({
  repositoryAPI,
  requestTransformer,
  responseTransformer,
  PENDING_ACTION_TYPE,
  SUCCESS_ACTION_TYPE,
  FAILED_ACTION_TYPE,
  selector,
}) => (bodyParamObject, restParamObject) => async (dispatch, getState) => {
  dispatch({
    type: PENDING_ACTION_TYPE,
  });

  const [accessToken, selectedState] = getTokenAndSelectedState(getState, selector);

  const result = await apiRequest({
    repositoryAPI,
    bodyParamObject,
    requestTransformer,
    responseTransformer,
    selectedState,
    accessToken,
    restParamObject,
  });
  if (result.ok) {
    dispatch({
      type: SUCCESS_ACTION_TYPE,
      ...result,
    });
  } else {
    dispatch({
      type: FAILED_ACTION_TYPE,
      errorResponse: result,
    });
  }
};

export const asyncBatchActionCreator = ({
  repositoryAPI,
  requestTransformer,
  responseTransformer,
  PENDING_ACTION_TYPE,
  SUCCESS_ACTION_TYPE,
  FAILED_ACTION_TYPE,
  selector,
}) => (bodyParamObjectList, additionalBodyParamObject = {}, restParamObject) => async (dispatch, getState) => {
  const [accessToken, selectedState] = getTokenAndSelectedState(getState, selector);
  dispatch({
    type: PENDING_ACTION_TYPE,
    ...additionalBodyParamObject,
  });

  const fetchResults = await Promise.all(bodyParamObjectList.map(async (bodyParamObject) => apiRequest({
    repositoryAPI,
    bodyParamObject: {
      ...bodyParamObject,
      ...additionalBodyParamObject,
    },
    requestTransformer,
    responseTransformer,
    selectedState,
    accessToken,
    restParamObject,
  })));

  const successfulResultList = fetchResults.filter(({ ok }) => ok);
  if (successfulResultList.length) {
    dispatch({
      type: SUCCESS_ACTION_TYPE,
      successfulResultList,
    });
  } else {
    dispatch({
      type: FAILED_ACTION_TYPE,
      failedResultList: fetchResults,
    });
  }
};

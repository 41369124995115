import { extendedDayJs } from './timeHelpers';

export const getFormattedDate = (date) => (date ? extendedDayJs(date).format('ddd, MMM D') : 'Select Date');
export const getFullFormattedDate = (date) => (date ? extendedDayJs(date).format('MM/DD/YY') : 'Select Date');
export const getFormattedOldChartRequestDateTime = (date) => (date && extendedDayJs(date).format('MM/DD/YY HH:mm:ss'));

const today = extendedDayJs();
export const todayFullDate = extendedDayJs(extendedDayJs()).set({ hour: 23, minute: 59, second: 59 });
export const todayFullTime = getFormattedOldChartRequestDateTime(today);

export const getFormattedPastDate = (amount, type, formatter) => {
  const date = extendedDayJs().subtract(amount, type, formatter);
  return formatter(date);
};

export const sixtyDaysAgo = getFormattedPastDate(60, 'day', getFullFormattedDate);
export const fourteenDaysAgo = getFormattedPastDate(14, 'day', getFullFormattedDate);
export const twentyEightDaysAgo = getFormattedPastDate(28, 'day', getFullFormattedDate);
export const oneHourAgo = getFormattedPastDate(1, 'hour', getFormattedOldChartRequestDateTime);
export const oneDayAgo = getFormattedPastDate(24, 'hour', getFormattedOldChartRequestDateTime);

export const chartRangeMappings = [
  { label: 'Custom', value: null },
  { label: 'Last 1 Hour', value: oneHourAgo },
  { label: 'Last 24 Hours', value: oneDayAgo },
  { label: 'Last 60 Days', value: sixtyDaysAgo },
  { label: 'Last 14 Days', value: fourteenDaysAgo },
  { label: 'Last 28 Days', value: twentyEightDaysAgo },
];

export const getOffsetDateArr = (dateArr) => ([
  `${extendedDayJs(dateArr.at(0)).utcOffset((-1 * new Date().getTimezoneOffset()), true).format()}`.split('T').join(' '),
  `${extendedDayJs(dateArr.at(1)).utcOffset((-1 * new Date().getTimezoneOffset()), true).format()}`.split('T').join(' '),
]);

import SearchableDropdown from 'shared/components/SearchableDropdown';

import { extraReportDetailHeadings, reportsWithExtras } from 'reporting/constants/reportingConstants';

const ExtraReportDetails = ({
  extraOptions,
  setExtraOptions,
  errored: { field: errorField } = { field: '' },
}) => {
  const handleCheckOptionClick = ({ name, value }) => {
    const newExtraOptions = extraOptions.options.map((option) => (
      option.name === name ? { ...option, value: !value } : option
    ));
    setExtraOptions({ ...extraOptions, options: newExtraOptions });
  };

  const displayOptionButtons = (options) => options.map((option) => (
    <div
      className={`extra-option ${option.value ? 'active' : ''}`}
      key={option.name}
      onClick={() => handleCheckOptionClick(option)}
    >
      <p>{option.label}</p>
    </div>
  ));

  const displayDropdownSelection = (options) => {
    const dropdownItems = options.options.map((o) => ({ key: o, value: o, label: o }));
    return (
      <SearchableDropdown
        isSearchable={false}
        itemHeader="Report Type"
        dropdownItems={dropdownItems}
        dropdownValue={options.value}
        onItemSelect={(value) => setExtraOptions({
          ...extraOptions,
          options: { ...extraOptions.options, value },
        })}
      />
    );
  };

  const { type, options } = extraOptions;
  return (
    <div className="extra-details">
      <h3 className={`${errorField === 'eventDetail' ? 'errored-heading' : ''}`}>
        { `${extraReportDetailHeadings[type].title} `}
        <span className="required-asterisk">*</span>
      </h3>
      <p className="section-subtext">{extraReportDetailHeadings[type].subTitle}</p>
      <div className={`detail-options ${errorField === 'eventDetail' ? 'errored p-2rem' : ''}`}>
        {
          type === reportsWithExtras.FLUX_FLEET && displayOptionButtons(options)
        }
        {
          type === reportsWithExtras.UNIT_PERFORMANCE && displayDropdownSelection(options)
        }
      </div>
    </div>
  );
};

export default ExtraReportDetails;

/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Tab, Tabs } from '@flogistix/flo-ui';

import {
  getUserPreferences,
  updateUserPreferences,
} from 'shared/repositories/accountSettingsRepository';
import { selectSession } from 'shared/reducers/sessionReducer';
import { fetchUnits } from 'shared/repositories/assetsRepository';
import { getFilteredAssetList } from 'shared/helpers/assetHelpers';
import { selectSubscriptions } from 'shared/reducers/subscriptionsSlice';
import { editUserSubscriptions } from 'shared/actions/subscriptionsActions';

import AccountSettingsPageSkeleton from 'account-settings/pages/AccountSettingsPageSkeleton';
import NotificationSkeleton from 'account-settings/components/notifications/NotificationSkeleton';
import AccountInformation from 'account-settings/components/account-information/AccountInformation';
import NotificationsTabsContent from 'account-settings/components/notifications/NotificationsTabsContent';

import packageJson from '../../../package.json';

import './AccountSettingsPage.scss';

const { version } = packageJson;

const AccountSettingsPage = () => {
  const dispatch = useDispatch();

  const toggleDemoMode = (demoMode) => dispatch({ type: 'TOGGLE_DEMO_MODE', payload: demoMode });
  const updateUserSubscriptions = (newSubscriptions) => dispatch(editUserSubscriptions(newSubscriptions));

  const session = useSelector(selectSession);
  const subscriptionsState = useSelector(selectSubscriptions);
  const demoModeState = useSelector((state) => state.resources.demo);

  const [activeSection, setActiveSection] = useState('My Notifications');

  const [preferences, setPreferences] = useState([]);
  const [preferencesError, setPreferencesError] = useState(null);
  const [preferencesLoading, setPreferencesLoading] = useState(false);

  const [assetList, setAssetList] = useState([]);
  const [assetListError, setAssetListError] = useState(null);
  const [filteredAssetList, setFilteredAssetList] = useState([]);
  const [assetListLoading, setAssetListLoading] = useState(false);

  const defaultDndSettings = {
    dnd: preferences?.dnd,
    dnd_stop: preferences?.dnd_stop,
    dnd_start: preferences?.dnd_start,
  };
  const [dndSettings, setDndSettings] = useState(defaultDndSettings);
  const [modifiedSubscriptions, setModifiedSubscriptions] = useState(null);

  const [showButtons, setShowButtons] = useState(false);

  const fetchPreferences = async () => {
    setPreferencesLoading(true);

    try {
      const response = await getUserPreferences({ accessToken: session?.token });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      setPreferences(responseData);
    } catch (error) {
      setPreferencesError(error);
      console.error('Error fetching preferences:', error);
    } finally {
      setPreferencesLoading(false);
    }
  };

  const fetchAllAssets = async () => {
    setAssetListLoading(true);

    try {
      const response = await fetchUnits({ accessToken: session?.token, body: [] });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const filteredResponseData = responseData?.filter((asset) => asset.invalidated_at === null);

      setAssetList(filteredResponseData);
      setFilteredAssetList(getFilteredAssetList(filteredResponseData, null, '', ''));
    } catch (error) {
      setAssetListError(error);
      console.error('Error fetching assets:', error);
    } finally {
      setAssetListLoading(false);
    }
  };

  const postUserPreferences = async (updatedPreferences) => {
    setPreferencesLoading(true);

    try {
      const response = await updateUserPreferences({ accessToken: session?.token, body: updatedPreferences });

      if (!response.ok) {
        throw new Error(`Update failed with status: ${response.status}`);
      }

      const responseData = await response.json();

      responseData.dnd_stop = responseData.dnd_sp;
      delete responseData.dnd_sp;

      setDndSettings({
        dnd_stop: responseData.dnd_stop,
        dnd_start: responseData.dnd_start,
      });

      setPreferences(responseData);
    } catch (error) {
      setPreferencesError(error);
      console.error('Error updating:', error);
    } finally {
      setPreferencesLoading(false);
      hideConfirmationButtons();
    }
  };

  const editPhoneNumber = async (newPhonePreferences) => {
    setPreferencesLoading(true);

    try {
      const response = await updateUserPreferences({ accessToken: session?.token, body: newPhonePreferences });

      if (!response.ok) {
        throw new Error(`Update failed with status: ${response.status}`);
      }

      const responseData = await response.json();

      responseData.dnd_stop = responseData.dnd_sp;
      delete responseData.dnd_sp;

      setPreferences(responseData);

      setDndSettings({
        dnd_stop: responseData.dnd_stop,
        dnd_start: responseData.dnd_start,
      });
    } catch (error) {
      setPreferencesError(error);
      console.error('Error fetching preferences:', error);
    } finally {
      setPreferencesLoading(false);
    }
  };

  const updateReceivingTexts = () => {
    const updatedPreferences = {
      ...preferences,
      send_sms: !preferences.send_sms,
    };

    postUserPreferences(updatedPreferences);
  };

  const updateReceivingEmails = () => {
    const updatedPreferences = {
      ...preferences,
      send_email: !preferences.send_email,
    };

    postUserPreferences(updatedPreferences);
  };

  const updateDndPreferences = () => {
    const newPreferences = {
      ...preferences,
      dnd: !!(dndSettings?.dnd_start !== null && dndSettings?.dnd_stop !== null),
      dnd_stop: dndSettings?.dnd_stop,
      dnd_start: dndSettings?.dnd_start,
    };

    postUserPreferences(newPreferences);
  };

  const submitNewSubscriptions = () => {
    updateUserSubscriptions({ subscriptions: modifiedSubscriptions });
    hideConfirmationButtons(false);
  };

  const showConfirmationButtons = () => setShowButtons(true);

  const hideConfirmationButtons = () => setShowButtons(false);

  const revertSubscriptionChanges = () => {
    setShowButtons(false);
    fetchAllAssets();
  };

  const handleSave = () => {
    modifiedSubscriptions !== null && submitNewSubscriptions();
    updateDndPreferences();
  };

  useEffect(() => {
    if (session?.token) {
      fetchAllAssets();
      fetchPreferences();
    }
  }, [session]);

  useEffect(() => {
    if (preferences?.dnd_stop !== null && preferences?.dnd_start !== null) {
      setDndSettings({
        dnd: preferences?.dnd,
        dnd_stop: preferences?.dnd_stop,
        dnd_start: preferences?.dnd_start,
      });
    }
  }, [preferences?.dnd_stop, preferences?.dnd_start]);

  return (
    session?.user
      ? (
        <div className="account-settings-page">
          <div className="page-heading">
            <div className="left-heading">
              <h1>My Account</h1>
              {showButtons
                ? (
                  <div className="confirmation-buttons">
                    <Button
                      variation="red"
                      style={{ marginRight: '8px' }}
                      onClick={() => {
                        revertSubscriptionChanges();
                        setDndSettings(preferences);
                      }}
                    >
                      Revert changes
                    </Button>
                    <Button
                      variation="blue"
                      onClick={handleSave}
                    >
                      Save Changes
                    </Button>
                  </div>
                )
                : <div />}
            </div>
            <p className="software-version">
              Software Version: {version}
            </p>
          </div>
          {preferences
            && (
              <AccountInformation
                user={session?.user}
                demoMode={demoModeState}
                preferences={preferences}
                editUserPhone={editPhoneNumber}
                toggleDemoMode={toggleDemoMode}
                errorResponse={preferencesError}
                updatePending={preferencesLoading}
              />
            )}
          <div className="notifications-content">
            {!assetListLoading
              ? (
                <div>
                  <Tabs
                    color="black"
                    headerSize="h3"
                    className="notification-tabs"
                    defaultActiveKey="my-notifications"
                  >
                    <Tab
                      title="My Notifications"
                      eventKey="my-notifications"
                      onClick={() => setActiveSection('My Notifications')}
                    />
                    <Tab
                      title="Add New"
                      eventKey="add-new"
                      onClick={() => setActiveSection('Add New')}
                    />
                  </Tabs>
                  <NotificationsTabsContent
                    assets={assetList}
                    dndSettings={dndSettings}
                    preferences={preferences}
                    activeSection={activeSection}
                    setDndSettings={setDndSettings}
                    filteredAssets={filteredAssetList}
                    setFilteredAssets={setFilteredAssetList}
                    updateReceivingTexts={updateReceivingTexts}
                    modifiedSubscriptions={modifiedSubscriptions}
                    updateReceivingEmails={updateReceivingEmails}
                    showConfirmationButtons={showConfirmationButtons}
                    updateUserSubscriptions={updateUserSubscriptions}
                    setModifiedSubscriptions={setModifiedSubscriptions}
                    setShowConfirmationButtons={showConfirmationButtons}
                    userSubscriptions={subscriptionsState?.subscriptions}
                  />
                </div>
              )
              : (
                <NotificationSkeleton />
              )}
          </div>
        </div>
      )
      : (
        <AccountSettingsPageSkeleton />
      )
  );
};

export default AccountSettingsPage;
